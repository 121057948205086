export default class ProgramModel {
  constructor() {
    this.id = 0;
    this.isUmrah = true;
    this.images = [];
    this.programsLists = [new ListProgramModel()];
    this.fileDay = [];
    this.name = "";
  }
}

export class ListProgramModel {
  constructor(day) {
    this.name = "";
    this.description = "";
    this.imageIds = [];
    this.day = day || 1;
  }
}
