import { Divider, IconButton, Tag } from "rsuite";
import format_number from "../../../Helpers/number_formatter";
import { Edit } from "@rsuite/icons";
import TrashIcon from "@rsuite/icons/Trash";
import { useMemo } from "react";

const TableCosts = ({ model, setModel, setCostModel }) => {
  const filteredCosts = useMemo(
    () =>
      model.packageUmrahCosts.filter(
        (el) => el.designation.toUpperCase() !== "VOL"
      ),
    [model.packageUmrahCosts]
  );

  const totalCostRYEL = useMemo(
    () => filteredCosts.reduce((sum, el) => sum + el.cost, 0),
    [filteredCosts]
  );

  const totalCostTND = useMemo(
    () => filteredCosts.reduce((sum, el) => sum + el.cost * el.exchange, 0),
    [filteredCosts]
  );

  const handleEdit = (el) => {
    setCostModel(el);
  };

  const handleDelete = (el) => {
    setModel((prev) => ({
      ...prev,
      packageUmrahCosts: prev.packageUmrahCosts.filter(
        (el1) =>
          (el.id && el1.id !== el.id) ||
          (!el.id && el.uuid && el1.uuid !== el.uuid)
      ),
    }));
  };

  return (
    <div>
      <table>
        <thead>
          <tr style={{ background: "#454599", color: "#fff" }}>
            <td>Désignation</td>
            <td>TAG</td>
            <td>Coût RYEL</td>
            <td>Coût TND</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>
          {filteredCosts.map((el) => (
            <tr key={el.id || el.uuid} style={{ color: "#454599" }}>
              <td>{el.designation}</td>
              <td>
                <Tag
                  color={
                    ["red", "green", "yellow"][
                      [
                        ...new Set(
                          model.packageUmrahCosts.map((_el) => _el.tag)
                        ),
                      ].findIndex((__el) => __el === el.tag)
                    ]
                  }
                >
                  #{el.tag}
                </Tag>
              </td>
              <td>{format_number(el.cost)}</td>
              <td>{format_number(el.cost * el.exchange)}</td>
              <td>
                <IconButton
                  appearance="subtle"
                  onClick={() => handleEdit(el)}
                  icon={<Edit />}
                  circle
                />
                <Divider vertical />
                <IconButton
                  appearance="subtle"
                  onClick={() => handleDelete(el)}
                  icon={<TrashIcon />}
                  circle
                />
              </td>
            </tr>
          ))}
          <tr>
            <td>Total</td>
            <td></td>
            <td>{format_number(totalCostRYEL)}</td>
            <td>{format_number(totalCostTND)}</td>
            <td>-</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableCosts;
