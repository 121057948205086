import countries from "../Data/countries";

export default class TicketModel {
  tags = [];
  constructor(
    paxName,
    status,
    ticketNumber,
    emmissionDate,
    clientId,
    pnr,
    paxType,
    priceTicket,
    emd,
    agencyCommission,
    totalTTC,
    totalRefund
  ) {
    this.paxName = paxName;
    this.status = status;
    this.ticketNumber = ticketNumber;
    this.emmissionDate = emmissionDate;
    this.clientId = clientId;
    this.pnr = pnr;
    this.paxType = paxType;
    this.priceTicket = priceTicket;
    this.emd = emd;
    this.agencyCommission = agencyCommission;
    this.totalTTC = totalTTC;
    this.totalRefund = totalRefund;
  }
}

export class TicketRSModel extends TicketModel {
  client = {
    id: 0,
    name: "",
    taxCode: "",
    emails: [],
    phones: [],
    address: "",
  };
}
export class TicketDetailsModel extends TicketRSModel {
  segments = [new Segment()];
  docName = "";
}
export class Segment {
  departCity
  departCountry
  departAirport
  departDate
  arrivalCity
  arrivalCountry
  arrivalDate
  arrivalAirport

  flightNumber;
  
  class;
  bagages;

  duration;
}
export class Airline {
  logo;
  code;
  name;
  constructor(code, name) {
    this.code = code;
    this.logo = code + ".jpg";
    this.name = name;
  }
}
export class Airport {
  name;
  city;
  country;

  constructor(coCode, name, city) {
    this.country = new Country(coCode);
    this.name = name;
    this.city = city;
  }
}
export class Country {
  name = "";
  code = "";
  logo = "";
  constructor(code) {
    this.code = code;
    if (code) {
      let co = countries.find((c) => c.id === code);
      this.name = co.name;
      this.logo = co.name + ".png";
    }
  }
}
// *******************************************************
let fake_ticket = new TicketRSModel();
fake_ticket.agencyCommission = 20;
fake_ticket.id = 11245;
fake_ticket.client = {
  name: "Oh Voyage",
  id: 1,
  taxCode: "testcode",
  address: "Kasserine , Immeuble, El Baraka 1200",
};
fake_ticket.emmissionDate = new Date(2022, 6, 12);
fake_ticket.paxName = "Alwani Anis";
fake_ticket.paxType = "ADT";
fake_ticket.pnr = "AZE125";
fake_ticket.priceTicket = 1000;
fake_ticket.status = "TKTT";
fake_ticket.ticketNumber = "112525821";
fake_ticket.totalTTC = 1020;
fake_ticket.currency = "TND";
fake_ticket.tags = [{ name: "Vol Istanbul", id: 1 }];

export const tickets_fake = [fake_ticket];
let fake_details_ticket = new TicketDetailsModel();
fake_details_ticket = { ...fake_ticket };
let seg = new Segment();
seg.arrivalDate = new Date(2022, 6, 12);
seg.departDate = new Date(2022, 6, 12);
seg.arrivalTime = new Date(2022, 6, 12, 12, 50, 0).getTime();
seg.bagages = true;
seg.class = "Economic";
seg.departAirport = new Airport("TN", "Carthage", "Tunis");
seg.destinationAirport = new Airport("FR", "Charles de Guaule", "Paris");
seg.airline = new Airline("TU", "Tunisair");
fake_details_ticket.segments = [seg];
fake_details_ticket.docName = "AIR114156.txt";
export const fake_details = fake_details_ticket;

