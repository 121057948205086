import { Input } from "rsuite";
import Responsive from "../../Components/Responsive";
import styles from "./styles/programStyles";
import Attachments from "../Packages/Attachments/Attachments.component";

const ProgramComponent = ({ program, setModel, model, index }) => {
  const handleChange = (field, value) => {
    setModel((prevModel) => {
      const updatedPrograms = [...prevModel.programsLists];
      updatedPrograms[index] = {
        ...updatedPrograms[index],
        [field]: value,
      };
      return {
        ...prevModel,
        programsLists: updatedPrograms,
      };
    });
  };

  return (
    <>
      <div style={styles.container}>
        <div style={styles.dayLabel}>Jour {program.day}</div>
        <div style={styles.formSection}>
          <Responsive className="p-5" m={6} l={3} xl={3}>
            <label>Nom: </label>
            <Input
              block
              value={program.name || ""}
              onChange={(value) => handleChange("name", value)}
            />
          </Responsive>

          <Responsive className="p-5" m={6} l={5} xl={9}>
            <label>Description: </label>
            <Input
              as="textarea"
              rows={3}
              block
              value={program.description || ""}
              onChange={(value) => handleChange("description", value)}
            />
          </Responsive>
        </div>

        <Attachments
          model={model}
          setModel={setModel}
          isProgram
          index={index}
        />
      </div>
    </>
  );
};

export default ProgramComponent;
