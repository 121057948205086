import { useState } from "react";
import { services } from "../../../Constants/types";
import { useRecoilValue } from "recoil";
import { IconButton, Input, SelectPicker } from "rsuite";
import Responsive from "../../../Components/Responsive";
import format_number from "../../../Helpers/number_formatter";
import { CurrencyAtom } from "../../../Atoms/currency.atom";
import { ExchangeAtom } from "../../../Atoms/exchange.atom";
import TrashIcon from "@rsuite/icons/Trash";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import { ExtraModel } from "../../../Models/Package.model";

const Extrats = ({ providers, fetchProviders, model, setModel }) => {
  const currencies = useRecoilValue(CurrencyAtom);
  const exchanges = useRecoilValue(ExchangeAtom);
  const [service, setService] = useState(model?.extrats[0]?.service || 0);
  const [providerId, setProviderId] = useState(
    model?.extrats[0]?.providerId || 0
  );
  const [currencyId, setCurrencyId] = useState(
    model?.extrats[0]?.currencyId || 0
  );
  const [exchange, setExchange] = useState(model?.extrats[0]?.exchange || 1);

  const updateExtratsFields = (fields) => {
    const updatedExtrats = model.extrats.map((extrat) => ({
      ...extrat,
      ...fields,
    }));
    setModel((prevModel) => ({ ...prevModel, extrats: updatedExtrats }));
  };

  const handleProviderSelect = (selectedProviderId) => {
    setProviderId(selectedProviderId);
    updateExtratsFields({ providerId: selectedProviderId });
  };

  const handleServiceSelect = (selectedServiceId) => {
    setService(selectedServiceId);
    updateExtratsFields({ service: selectedServiceId });
  };

  const getCurrencyOptions = () => {
    const provider = providers.find((p) => p.id === providerId);
    return (
      provider?.currencies?.map(({ currency }) => ({
        label: currency.name,
        value: currency.id,
      })) ||
      currencies.map((c) => ({
        label: c.name,
        value: c.id,
      }))
    );
  };

  const handleCurrencySelect = (selectedCurrencyId) => {
    setCurrencyId(selectedCurrencyId);
    const exchangeRate =
      exchanges.find((ex) => ex.currencySourceId === selectedCurrencyId)
        ?.rate || 1;
    setExchange(exchangeRate);
    updateExtratsFields({
      currencyId: selectedCurrencyId,
      exchange: exchangeRate,
    });
  };

  const handleUpdateExtrat = (index, key, value) => {
    const updatedExtrats = model.extrats.map((ext, i) =>
      i === index
        ? { ...ext, [key]: key === "cost" ? parseFloat(value) : value }
        : ext
    );
    setModel((prevModel) => ({ ...prevModel, extrats: updatedExtrats }));
  };

  const addExtrat = () => {
    setModel((prevModel) => ({
      ...prevModel,
      extrats: [...prevModel.extrats, new ExtraModel()],
    }));
  };

  const removeExtrat = (index) => {
    const updatedExtrats = model.extrats.filter((_, i) => i !== index);
    setModel((prevModel) => ({
      ...prevModel,
      extrats: updatedExtrats,
    }));
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h6>Extrats</h6>
        <div style={{ display: "flex" }}>
          <Responsive className="p-10" m={4} l={2.5} xl={1.8}>
            <label>Fournisseur:</label>
            <SelectPicker
              onSearch={fetchProviders}
              data={providers.map((p) => ({ label: p.name, value: p.id }))}
              block
              noSearch
              value={providerId}
              onSelect={handleProviderSelect}
            />
          </Responsive>

          <Responsive className="p-10" m={4} l={2.5} xl={1.8}>
            <label>Service:</label>
            <SelectPicker
              data={services}
              block
              noSearch
              value={service}
              onSelect={handleServiceSelect}
            />
          </Responsive>

          <Responsive s={3} m={3} l={1.5} xl={1.5} className="p-10">
            <label>Devise:</label>
            <SelectPicker
              data={getCurrencyOptions()}
              block
              noSearch
              value={currencyId}
              onSelect={handleCurrencySelect}
            />
          </Responsive>

          <Responsive s={3} m={3} l={1.5} xl={0.8} className="p-10">
            <label>Cour:</label>
            <Input type="number" value={exchange} disabled />
          </Responsive>
        </div>

        {model.extrats.map((extrat, i) => (
          <div key={i} className="fee-entry" style={{ display: "flex" }}>
            <Responsive m={4} l={3} xl={3} className="p-10">
              <label>Désignation:</label>
              <Input
                value={extrat.note}
                onChange={(value) => handleUpdateExtrat(i, "note", value)}
              />
            </Responsive>

            <Responsive s={4} m={4} l={1.5} xl={1.5} className="p-10">
              <label>Coût unitaire:</label>
              <Input
                type="number"
                step="1"
                value={extrat.unitCost}
                onChange={(value) =>
                  handleUpdateExtrat(i, "unitCost", parseFloat(value))
                }
              />
            </Responsive>

            <Responsive s={4} m={4} l={1} xl={1} className="p-10">
              <label>Coût TND:</label>
              <Input
                type="number"
                value={format_number(extrat.unitCost * exchange)}
                disabled
              />
            </Responsive>

            <Responsive s={4} m={4} l={1.5} xl={1.5} className="p-10">
              <label>Frais unitaires :</label>
              <Input
                type="number"
                step="1"
                value={extrat.unitFee}
                onChange={(value) =>
                  handleUpdateExtrat(i, "unitFee", parseFloat(value))
                }
              />
            </Responsive>

            <Responsive s={4} m={4} l={1} xl={1} className="p-10">
              <label>Frais TND:</label>
              <Input
                type="number"
                value={format_number(extrat.unitFee * exchange)}
                disabled
              />
            </Responsive>
            <Responsive s={2} m={1.6} l={1} xl={1}>
              <div style={{ height: "42px" }}> </div>
              <IconButton
                onClick={() => removeExtrat(i)}
                color="violet"
                icon={<TrashIcon />}
              />
              {i + 1 === model.extrats.length && (
                <IconButton
                  onClick={addExtrat}
                  color="violet"
                  icon={<PlusRoundIcon />}
                />
              )}
            </Responsive>
          </div>
        ))}
      </div>
    </>
  );
};

export default Extrats;
