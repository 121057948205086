import { Divider, IconButton } from "rsuite";
import { Edit } from "@rsuite/icons";
import TrashIcon from "@rsuite/icons/Trash";

const DestinationsTable = ({
  destinations,
  setDestinationModel,
  updateModel,
}) => {
  const handleEdit = (destination) => {
    setDestinationModel(destination);
  };

  const handleDelete = (destinationId, destinationUuid) => {
    updateModel((prev) => ({
      ...prev,
      destinations: prev.destinations.filter(
        (el) =>
          (destinationId && el.id !== destinationId) ||
          (destinationUuid && el.uuid !== destinationUuid)
      ),
    }));
  };

  return (
    <table className="table-container">
      <thead>
        <tr className="table-header">
          <td>Nom</td>
          <td>Actions</td>
        </tr>
      </thead>
      <tbody>
        {destinations &&
          destinations.map((destination) => (
            <tr key={destination.id || destination.uuid} className="table-row">
              <td>{destination.name}</td>
              <td className="table-actions">
                <IconButton
                  appearance="subtle"
                  onClick={() => handleEdit(destination)}
                  icon={<Edit />}
                  circle
                />
                <Divider vertical />
                <IconButton
                  appearance="subtle"
                  onClick={() => handleDelete(destination.id, destination.uuid)}
                  icon={<TrashIcon />}
                  circle
                />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default DestinationsTable;
