const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  transfertListContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: "16px",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    width: "5%",
  },
  iconButton: {
    width: "30px",
    height: "30px",
  },
};

export default styles;
