import { CaracteristiquePackageTranslations } from "../../../Constants/types";
import DifferentFeesForm from "./differentFees";
import MainInformations from "./mainInformation";
import PackageUmrahCosts from "./packageUmrahCosts";
import TableCosts from "./tableCosts";
import ExtratsForm from "./extrats";
import Caracteristique from "../../../Components/Caracteristique/caracteristique";
import { useEffect, useState } from "react";
import extrats from "../../../Data/extrats";

const Informations = ({
  model,
  setModel,
  setCostModel,
  costModel,
  fetchProviders,
  exchanges,
  currencies,
  providers,
}) => {
  const [providerId, setProviderId] = useState(
    model?.extrats[0]?.providerId || 0
  );
  const [currencyId, setCurrencyId] = useState(
    model?.extrats[0]?.currencyId || 0
  );
  const [exchange, setExchange] = useState(model?.extrats[0]?.exchange || 1);

  useEffect(() => {
    if (model.extrats.length === 0) {
      setModel((prevModel) => ({ ...prevModel, extrats: extrats }));
    }
  }, []);

  const setCaracteristiques = (caracteristique) => {
    setModel((prev) => ({
      ...prev,
      caracteristique,
    }));
  };

  const handleUpdateExtrat = (index, key, value) => {
    const updatedExtrats = model.extrats.map((ext, i) =>
      i === index
        ? { ...ext, [key]: key === "cost" ? parseFloat(value) : value }
        : ext
    );
    setModel((prevModel) => ({ ...prevModel, extrats: updatedExtrats }));
  };

  const getCurrencyOptions = () => {
    const provider = providers.find((p) => p.id === providerId);
    return (
      provider?.currencies?.map(({ currency }) => ({
        label: currency.name,
        value: currency.id,
      })) ||
      currencies.map((c) => ({
        label: c.name,
        value: c.id,
      }))
    );
  };

  const updateExtratsFields = (fields) => {
    const updatedExtrats = model.extrats.map((extrat) => ({
      ...extrat,
      ...fields,
    }));
    setModel((prevModel) => ({ ...prevModel, extrats: updatedExtrats }));
  };

  const handleCurrencySelect = (selectedCurrencyId) => {
    setCurrencyId(selectedCurrencyId);
    const exchangeRate =
      exchanges.find((ex) => ex.currencySourceId === selectedCurrencyId)
        ?.rate || 1;
    setExchange(exchangeRate);

    updateExtratsFields({
      currencyId: selectedCurrencyId,
      exchange: exchangeRate,
    });
  };

  const handleProviderSelect = (selectedProviderId) => {
    setProviderId(selectedProviderId);
    updateExtratsFields({ providerId: selectedProviderId });
  };

  return (
    <div>
      <MainInformations model={model} setModel={setModel} />
      <Caracteristique
        setCaracteristiques={setCaracteristiques}
        caracteristique={model.caracteristique || {}}
        translations={CaracteristiquePackageTranslations}
      />
      <div style={{ background: "rgb(70,90,238,0.2)" }} className="p-10">
        <PackageUmrahCosts
          setCostModel={setCostModel}
          costModel={costModel}
          fetchProviders={fetchProviders}
          exchanges={exchanges}
          currencies={currencies}
          model={model}
          setModel={setModel}
          providers={providers}
        />
        <TableCosts
          model={model}
          setModel={setModel}
          setCostModel={setCostModel}
        />
      </div>
      <DifferentFeesForm model={model} setModel={setModel} />
      <ExtratsForm
        model={model}
        providers={providers}
        fetchProviders={fetchProviders}
        handleUpdateExtrat={handleUpdateExtrat}
        getCurrencyOptions={getCurrencyOptions}
        handleCurrencySelect={handleCurrencySelect}
        handleProviderSelect={handleProviderSelect}
        exchange={exchange}
        providerId={providerId}
        currencyId={currencyId}
      />
    </div>
  );
};

export default Informations;
