import React from "react";
import { Input, SelectPicker } from "rsuite";
import { roomTypes, typeTravler } from "../../../../Constants/types";
import styles from "./styles/tableStyles";

const PricesPackageTable = ({ prices, onPriceChange }) => {
  return (
    <div>
      <table>
        <thead style={styles.thead}>
          <tr>
            <th style={styles.th}>Hotel</th>
            <th style={styles.th}>Nom de l'arrangement</th>
            <th style={styles.th}>Type voyageur</th>
            <th style={styles.th}>Price</th>
          </tr>
        </thead>
        <tbody>
          {prices.map((price, index) => (
            <tr key={index}>
              <td style={styles.td}>
                <h6>{price.name}</h6>
              </td>
              <td style={styles.td}>
                <SelectPicker
                  data={roomTypes}
                  block
                  noSearch
                  value={price.arrangementName}
                  onChange={(arrangementName) => {
                    onPriceChange(index, "arrangementName", arrangementName);
                  }}
                />
              </td>

              <td style={styles.td}>
                <SelectPicker
                  data={typeTravler}
                  block
                  noSearch
                  value={price.typeTraveler}
                  onChange={(value) =>
                    onPriceChange(index, "typeTraveler", value)
                  }
                />
              </td>
              <td style={styles.td}>
                <Input
                  value={price.price || 0}
                  onChange={(price) =>
                    onPriceChange(index, "price", parseFloat(price))
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PricesPackageTable;
