const styles = {
  container: {
    padding: "40px 10px",
  },

  wrapper: {
    display: "flex",
    flexDirection: "column",
  },

  header: {
    display: "flex",
    gap: "16px",
    marginBottom: "16px",
    background: "rgb(70,90,238,0.2)",
  },

  formSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "16px",
    padding: "16px",
    width: "100%",
  },

  formRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },

  formInputWrapper: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
  },

  mapWrapper: {
    flex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  mapContainer: {
    width: "100%",
    height: "47vh",
    borderRadius: "8px",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
  },
};

export default styles;
