const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  label: {
    display: "flex",
    gap: "8px",
    fontSize: "18px",
  },
};

export default styles;
