import { IconButton, Input } from "rsuite";
import Responsive from "../../../../Components/Responsive";
import { CaracteristiqueTransfert } from "../../../../Constants/types";
import TransfertList from "./transfertList/transfertList";
import { TransfertListModel } from "../../../../Models/packageomra.model";
import TrashIcon from "@rsuite/icons/Trash";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import styles from "./styles/transfertDisponibiltiesStyles";
import Caracteristique from "../../../../Components/Caracteristique/caracteristique";

const TransfertDisponibilties = ({ setModel, disponibilitie, index }) => {
  const handleChange = (key, value) => {
    setModel((prev) => ({
      ...prev,
      disponibilities: prev.disponibilities.map((disponibility, idx) => {
        if (idx === index) {
          return {
            ...disponibility,
            transfert: {
              ...disponibility.transfert,
              [key]: value,
            },
          };
        }
        return disponibility;
      }),
    }));
  };

  const setCaracteristique = (updatedCaracteristique) => {
    setModel((prev) => ({
      ...prev,
      disponibilities: prev.disponibilities.map((disponibility, idx) => {
        if (idx === index) {
          return {
            ...disponibility,
            transfert: {
              ...disponibility.transfert,
              caracteristiques: updatedCaracteristique,
            },
          };
        }
        return disponibility;
      }),
    }));
  };

  const addNewTransfert = () => {
    const newTransfertList = new TransfertListModel();
    setModel((prev) => ({
      ...prev,
      disponibilities: prev.disponibilities.map((disponibility, idx) => {
        if (idx === index) {
          return {
            ...disponibility,
            transfert: {
              ...disponibility.transfert,
              transfertLists: [
                ...disponibility.transfert.transfertLists,
                newTransfertList,
              ],
            },
          };
        }
        return disponibility;
      }),
    }));
  };

  const deleteTransfert = (transfertIndex) => {
    setModel((prev) => ({
      ...prev,
      disponibilities: prev.disponibilities.map((disponibility, idx) => {
        if (idx === index) {
          return {
            ...disponibility,
            transfert: {
              ...disponibility.transfert,
              transfertLists: disponibility.transfert.transfertLists.filter(
                (_, i) => i !== transfertIndex
              ),
            },
          };
        }
        return disponibility;
      }),
    }));
  };

  return (
    <div>
      <h6>Transfert</h6>
      <div style={styles.mainContainer}>
        <div style={styles.rowContainer}>
          <Responsive l={5} xl={5} className="p-10">
            <label>Nom : </label>
            <Input
              block
              name="name"
              value={disponibilitie?.transfert?.name || ""}
              onChange={(name) => handleChange("name", name)}
            />
          </Responsive>

          <Responsive l={5} xl={6.5} className="p-10">
            <label>Description : </label>
            <Input
              as="textarea"
              rows={3}
              block
              name="description"
              value={disponibilitie?.transfert?.description || ""}
              onChange={(description) =>
                handleChange("description", description)
              }
            />
          </Responsive>
        </div>
        <Caracteristique
          setCaracteristiques={setCaracteristique}
          caracteristique={disponibilitie?.transfert?.caracteristiques || {}}
          translations={CaracteristiqueTransfert}
        />

        {disponibilitie?.transfert?.transfertLists.map((transfertList, i) => (
          <div key={i} style={styles.transfertListContainer}>
            <div style={{ flexGrow: 1 }}>
              <TransfertList
                setModel={setModel}
                disponibilitie={disponibilitie}
                index={i}
                transfertList={transfertList}
              />
            </div>

            <Responsive s={2} m={2} l={2} xl={2} style={styles.iconContainer}>
              <IconButton
                onClick={() => deleteTransfert(i)}
                color="violet"
                icon={<TrashIcon />}
                style={styles.iconButton}
              />
              {i + 1 === disponibilitie?.transfert?.transfertLists.length && (
                <IconButton
                  onClick={addNewTransfert}
                  color="violet"
                  icon={<PlusRoundIcon />}
                  style={styles.iconButton}
                />
              )}
            </Responsive>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransfertDisponibilties;
