export default class AddTicketModel {
  paxName;
  paxType;
  passportNbr;
  passportExpiration;
  gender;
  dateOfBirth;
  nationality;

  adress;
  email;
  phone;

  //_____________________________________
  clientId; ///
  airlineId;
  note;
  agentAmadusId;

  //_____________________________________

  docNumber;
  pnr;
  doEmmission;
  tranc; //status

  //_______________________________________

  baseFare = 0;
  serviceFees = 0;
  serviceFeesTVA = 0;
  tax = 0;

  ///èèèèèèèèèèèèèèè

  //   refundPayed
  //   isNational
  //isInvoiced;
  //isRfndInvoiced;
  //isPayed;
  //isExchanged;

  // discount;
  totalRefund = 0;
  commissionRefund = 0;
  refundPenalty;
  refundTax = 0;

  ///

  //   fare;
  // totalDoc; // far + tax
  //fp; // payment
  //commission;
  // addition to service fee
  emd = 0;
  emdNumber;
  totalTTC = 0;
  segments = [];
}
