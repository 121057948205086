import { IconButton, Input } from "rsuite";
import Responsive from "../../../../../Components/Responsive";
import TrashIcon from "@rsuite/icons/Trash";
import PlusRoundIcon from "@rsuite/icons/PlusRound";

const TransportForm = ({
  mode,
  i,
  addNewTransport,
  modeTransports,
  deleteTransport,
  handleInputChangeTransport,
}) => {
  return (
    <div>
      <Responsive className="p-5" s={6} m={6} l={3} xl={1.8}>
        <label>Nom: </label>
        <Input
          block
          value={mode.name || ""}
          onChange={(name) => handleInputChangeTransport(i, "name", name)}
        />
      </Responsive>

      <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-5">
        <label>Prix:</label>
        <Input
          type="number"
          step="1"
          value={mode.price || 0}
          onChange={(price) =>
            handleInputChangeTransport(i, "price", parseFloat(price))
          }
        />
      </Responsive>

      <Responsive
        s={2}
        m={1.6}
        l={1}
        xl={1}
        style={{
          gap: "8px",
        }}
      >
        <div style={{ height: "38px" }}></div>
        <IconButton
          onClick={() => deleteTransport(i)}
          color="violet"
          icon={<TrashIcon />}
        />

        {i + 1 === modeTransports.length && (
          <IconButton
            onClick={addNewTransport}
            color="violet"
            icon={<PlusRoundIcon />}
          />
        )}
      </Responsive>
    </div>
  );
};

export default TransportForm;
