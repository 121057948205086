export const appendToFormData = (formData, baseKey, value) => {
  if (typeof value === "object" && value !== null) {
    if (Array.isArray(value)) {
      if (value.length === 0) {
        formData.append(`${baseKey}`, "[]");
      } else {
        value.forEach((item, index) => {
          appendToFormData(formData, `${baseKey}[${index}]`, item);
        });
      }
    } else {
      Object.keys(value).forEach((nestedKey) => {
        appendToFormData(
          formData,
          `${baseKey}[${nestedKey}]`,
          value[nestedKey]
        );
      });
    }
  } else {
    formData.append(baseKey, value);
  }
};

const appendModelToFormData = (formData, model) => {
  Object.keys(model).forEach((key) => {
    if (Array.isArray(model[key])) {
      if (model[key].length === 0) {
        formData.append(`${key}`, "[]");
      } else {
        model[key].forEach((item, index) => {
          if (typeof item === "object" && item !== null) {
            Object.keys(item).forEach((subKey) => {
              appendToFormData(
                formData,
                `${key}[${index}][${subKey}]`,
                item[subKey]
              );
            });
          } else {
            formData.append(`${key}[${index}]`, item);
          }
        });
      }
    } else if (typeof model[key] === "object" && model[key] !== null) {
      Object.keys(model[key]).forEach((subKey) => {
        appendToFormData(formData, `${key}[${subKey}]`, model[key][subKey]);
      });
    } else {
      formData.append(key, model[key]);
    }
  });

  if (model.images && Array.isArray(model.images)) {
    model.images.forEach((image) => {
      if (image instanceof File) {
        formData.append("images", image);
      }
    });
  }

  if (model.videos && Array.isArray(model.videos)) {
    model.videos.forEach((video) => {
      if (video instanceof File) {
        formData.append("videos", video);
      }
    });
  }
};

export default appendModelToFormData;
