const styles = {
  container: {
    padding: "5px 5px",
    marginBottom: "16px",
    background: "rgb(70,90,238,0.2)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "95%",
  },

  formSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    padding: "10px",
    flexGrow: 1,
  },
  dayLabel: {
    fontWeight: "bold",
  },
};

export default styles;
