import PlusRoundIcon from "@rsuite/icons/PlusRound";
import { IconButton, Input } from "rsuite";
import Responsive from "../../../Components/Responsive";
import TrashIcon from "@rsuite/icons/Trash";
import { DifferentFeesModal } from "../../../Models/packageomra.model";

const DifferentFeesForm = ({ model, setModel }) => {
  const handleUpdateFee = (index, key, value) => {
    const updatedFees = model.differentFees.map((fee, i) => {
      if (i === index) {
        return { ...fee, [key]: key === "cost" ? parseFloat(value) : value };
      }
      return fee;
    });
    setModel({ ...model, differentFees: updatedFees });
  };

  const handleAddFee = () => {
    const newFees = [...model.differentFees, new DifferentFeesModal()];
    setModel({ ...model, differentFees: newFees });
  };

  const handleRemoveFee = (index) => {
    const updatedFees = model.differentFees.filter((_, i) => i !== index);
    setModel({ ...model, differentFees: updatedFees });
  };

  return (
    <div>
      <h6>
        Autres Frais{" "}
        {model.differentFees.length === 0 && (
          <IconButton
            onClick={handleAddFee}
            color="violet"
            icon={<PlusRoundIcon />}
          />
        )}
      </h6>
      {model.differentFees.map((fee, i) => (
        <div key={fee.id || i} className="fee-entry">
          <Responsive m={6} l={6} xl={6} className="p-10">
            <label>Designation :</label>
            <Input
              value={fee.designation}
              onChange={(designation) =>
                handleUpdateFee(i, "designation", designation)
              }
            />
          </Responsive>

          <Responsive s={6} m={4} l={4} xl={4} className="p-10">
            <label>Coût :</label>
            <Input
              type="number"
              step="0.1"
              value={fee.cost}
              onChange={(cost) => handleUpdateFee(i, "cost", cost)}
            />
          </Responsive>

          <Responsive s={2} m={1.6} l={1} xl={1}>
            <div style={{ height: "42px" }} />
            <IconButton
              onClick={() => handleRemoveFee(i)}
              color="violet"
              icon={<TrashIcon />}
            />
            {i + 1 === model.differentFees.length && (
              <IconButton
                onClick={handleAddFee}
                color="violet"
                icon={<PlusRoundIcon />}
              />
            )}
          </Responsive>
        </div>
      ))}
    </div>
  );
};

export default DifferentFeesForm;
