import ImageIcon from "@rsuite/icons/Image";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Button, Checkbox, Input, SelectPicker, Tag } from "rsuite";
import Pagination from "rsuite/Pagination";
import { APi } from "../../Api";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";

import { createAPIEndpoint } from "../../Api/authenticated.requests";
import { ENDPOINTS } from "../../Api/enpoints";
import { agencyAtom } from "../../Atoms/agency.atom";
import { groupsAtoms } from "../../Atoms/groups.atoms";
import { BASE_URL } from "../../Config/api.config";
import { ConfirmationStatus } from "../../Constants/types";
import { multiDataSet } from "./excel_data";
export default function SaleUmrahConsult(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [clients, setclients] = useState([]);
  const [clients2, setclients2] = useState([]);
  const [packages, setpackages] = useState([]);
  const [vols, setvols] = useState([]);
  const [vol, setvol] = useState(null);
  const [providers, setproviders] = useState([]);
  const agency = useRecoilValue(agencyAtom);
  const [checkeds, setcheckeds] = useState([]);
  const [checkTime, setcheckTime] = useState(Date.now());
  const [imagesLoaded, setImagesLoaded] = useState(0);
  // Set the total number of images
  const [loadPrint, setloadPrint] = useState(false);

  const handleImageLoad = () => {
    setImagesLoaded((prevImagesLoaded) => prevImagesLoaded + 1);
  };

  const [totalCount, settotalCount] = useState(0);
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 20,
    clientId: 0,
    status: 0,
    date: null,
    tagId: 0,
  });

  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS

  // API CALLS
  const fetchClients = (q, forFilter = true) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Client, { q }, "/autocomplete")
        .customGet()
        .then((res) =>
          forFilter ? setclients(res.data) : setclients2(res.data)
        );
    }
  };

  const fetchProviders = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Provider, { q }, "/autocomplete2")
        .customGet()
        .then((res) => setproviders(res.data));
    }
  };

  const fetchPackages = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah + "/getForPrices", { q })
        .customGet()
        .then((res) => {
          setpackages(res.data.data);

          let _vols = res.data.data.reduce(
            (a, b) =>
              a.concat(
                b.disponibilities.map((el) => ({ packageUmrahId: b.id, ...el }))
              ),
            []
          );
          console.log(_vols);
          setvols(_vols.filter((d) => d.onStock));
        });
    }
  };
  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Umrah", {
      ...filterModel,
      date: filterModel.date
        ? moment(filterModel.date).format("DD MMM YYYY")
        : null,
    })
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
      })
      .catch((e) => {
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      });
  };

  //

  // LIFE CYCLES
  useEffect(() => {
    fetchClients();
    fetchProviders();
    fetchPackages();
  }, []);
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  const columns = [
    {
      value: "id",
      value2: "hasVisa",
      value3: "withoutVisa",
      name: " ",
      render: (id, hasVisa, withoutVisa) => (
        <b style={{ color: "green", display: "flex", alignItems: "center" }}>
          <Checkbox
            disabled={!hasVisa || withoutVisa}
            onChange={(v) => {
              setcheckTime(Date.now());
              if (checkeds.find((el) => el == id))
                setcheckeds((prev) => prev.filter((l) => l != id));
              else setcheckeds((prev) => (hasVisa ? [...prev, id] : prev));
            }}
            checked={checkeds.find((el) => el == id) != null}
          ></Checkbox>
        </b>
      ),
    },
    {
      value: "packageUmrah",
      value2: "onlyTicket",
      value3: "withoutVisa",
      value4: "withoutTicket",
      name: "Voyage",
      render: (v, onlyTicket, withoutVisa, withoutTicket) => (
        <b style={{ color: "#549f0a" }}>
          {v ? v.name.toUpperCase() : ""}{" "}
          {onlyTicket ? <Tag color="blue">Billet</Tag> : ""}{" "}
          {withoutVisa ? <Tag color="orange">Sans Visa</Tag> : ""}
          {withoutTicket ? <Tag color="violet">Sans Billet</Tag> : ""}
        </b>
      ),
    },
    {
      value: "coupon",
      name: "Coupon Utilisé",
      render: (v) => <b>{v ? v : "---"}</b>,
    },
    {
      value: "disponibility",
      name: "Vol",
      render: (c) =>
        c ? (
          <b
            style={{
              display: "flex",
              alignItems: "center",
              padding: "3px",

              borderRadius: "5px",
            }}
          >
            <img
              alt=""
              style={{ width: "30px", height: "30px", margin: "0 6px" }}
              src={c.airline ? c.airline.logo : ""}
            ></img>
            <b>
              {" "}
              <div style={{ color: "#232323" }}>
                {moment(c.date).format("DD MMM YYYY")}
              </div>
              <b style={{ color: "#888", fontWeight: "lighter" }}>
                {" "}
                {c.airline ? c.airline.name : ""}
              </b>
            </b>
          </b>
        ) : (
          <b>-</b>
        ),
    },
    {
      value: "confirmationStatus",
      name: "Etat",
      render: (v) => (
        <Tag
          color={
            v === 2 ? "violet" : v === 3 ? "green" : v === 4 ? "red" : "yellow"
          }
        >
          {ConfirmationStatus.find((el) => v === null || el.value === v)
            ? ConfirmationStatus.find((el) => v === null || el.value === v)
                .label
            : ConfirmationStatus.find((el) => el.value === 1).label}
        </Tag>
      ),
    },

    {
      value: "client",
      name: "Client B2B",
      render: (v) => <a>{v ? v.name.toUpperCase() : ""}</a>,
    },
    {
      value: "customer",
      name: "Client B2C",
      render: (v) => (
        <a style={{ display: "flex", alignItems: "center" }}>
          {v.picture ? (
            <img
              alt=""
              src={
                BASE_URL +
                "Uploads/CustomerPictures/" +
                v.picture +
                "?" +
                Date.now()
              }
              width="43"
              height="43"
              style={{
                border: "1px solid #eee",
                borderRadius: "50%",
                boxShadow: "-11px -5px 20px -13px rgba(0,0,0,0.75)",
              }}
            />
          ) : (
            <div
              style={{
                fontSize: "15px",
                border: "1px dashed #888",
                borderRadius: "50%",
                width: "43px",
                height: "43px",
                textAlign: "center",
                lineHeight: "42px",
              }}
            >
              <ImageIcon color="#3598ff"></ImageIcon>
            </div>
          )}
          <div style={{ padding: "0 5px" }}>
            {" "}
            {v.firstName + "  " + v.lastName}
            {"  "}
            <b
              style={{
                color: "#111",
                fontSize: "14px",
              }}
            >
              {v ? v.passportNumber : ""}
            </b>
            <br></br>{" "}
            <b style={{ color: "#330033" }}>
              {v.firstNameAr + "  " + v.lastNameAr}
            </b>
          </div>
        </a>
      ),
    },
    {
      value: "wakil",
      name: "Wakil",
      render: (v) => <strong>{v ? v.toUpperCase() : ""}</strong>,
    },
    {
      value: "totalPrice",
      name: "Prix Total",
      render: (v) => (
        <b style={{ color: "#53af50", fontSize: "18px" }}>
          {v && v.toFixed(3) + " "}
          <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
            TND
          </span>
        </b>
      ),
    },
    {
      value: "disponibility",
      value2: "onlyTicket",
      name: "Commission B2B",
      render: (v, onlyTicket) => (
        <b style={{ color: "#53af50", fontSize: "18px" }}>
          {!onlyTicket && v && v.b2Bcommission.toFixed(3) + " "}
          <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
            {!onlyTicket && "TND "}
          </span>
        </b>
      ),
    },
    {
      value: "date",
      name: "Date De Vente",
      render: (v) => <strong>{moment(v).format("DD MMM YYYY")}</strong>,
    },
  ];

  const handlePrint = () => {
    setloadPrint(true);
    const iframe = document.getElementById("iframe");
    if (checkeds.length && imagesLoaded === checkeds.length) {
      iframe.contentDocument.body.innerHTML = "";

      const content = generateHTMLContent();
      iframe.contentDocument.write(content);
      iframe.contentWindow.print();
      setloadPrint(false);
      setImagesLoaded(0);
    } else
      setTimeout(() => {
        if (checkeds.length && imagesLoaded === checkeds.length) {
          iframe.contentDocument.body.innerHTML = "";

          const content = generateHTMLContent();
          iframe.contentDocument.write(content);
          iframe.contentWindow.print();
          setloadPrint(false);
          setImagesLoaded(0);
        } else
          setTimeout(() => {
            iframe.contentDocument.body.innerHTML = "";

            const content = generateHTMLContent();
            iframe.contentDocument.write(content);
            iframe.contentWindow.print();
            setloadPrint(false);
            setImagesLoaded(0);
          }, 5000);
      }, 10000);
  };

  function generateHTMLContent() {
    // Replace this with your dynamic content generation logic

    let els = data.filter((p1) => checkeds.find((_p) => _p == p1.id));

    // Generate a list of image tags dynamically
    const imageTags = els
      .map((el) => el.customer)
      .map(
        (v, index) =>
          `  <img style="display:inline-block; width:7.9in;
          height:11.5in; 
border-radius:10px; vertical-align:top ; margin:auto
          ;" src=${
            BASE_URL +
            "Uploads/Visas/" +
            v.passportNumber +
            ".png?t=" +
            checkTime
          } />
          
       `
      )
      .join("");
    //   <div rtl="true"><span style="color:#4667d1; font-size:22px">: مندوب  مدينة  <span></div>
    //   <div rtl="true"><span style="color:#4667d1; font-size:22px">: مندوب  مدينة  <span></div>
    // The complete HTML document structure
    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${Date.now()}</title>
      </head>
      <body>
 ${imageTags}
      </body>
      </html>
    `;
  }
  return (
    <div>
      <div style={{ overflow: "hidden", height: 0 }}>
        <iframe id="iframe" title="printFrame" width="100%"></iframe>

        {data
          .filter((p1) => checkeds.find((_p) => _p == p1.id))
          .map((el) => el.customer)
          .map((c) => (
            <img
              onLoad={handleImageLoad}
              onError={handleImageLoad}
              src={
                BASE_URL +
                "Uploads/Visas/" +
                c.passportNumber +
                ".png?t=" +
                checkTime
              }
              key={c.id}
              alt=""
            ></img>
          ))}
      </div>
      <Filter search={() => fetch()}>
        <Responsive m={3} l={2.4} xl={2.4} className="p-10">
          <label>Recherche: </label>
          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Client: </label>
          <SelectPicker
            onSearch={(q) => fetchClients(q)}
            data={[{ label: "Tout", value: 0 }].concat(
              clients.map((c) => {
                return { label: c.name, value: c.id };
              })
            )}
            block
            noSearch
            value={filterModel.clientId}
            onSelect={(clientId) => {
              setfilterModel((prev) => {
                return { ...prev, clientId };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Status : </label>
          <SelectPicker
            searchable={false}
            data={[{ label: "Tout", value: 0 }].concat(ConfirmationStatus)}
            block
            noSearch
            value={filterModel.status}
            onSelect={(status) => {
              setfilterModel((prev) => {
                return { ...prev, status };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Nom Omrah: </label>
          <SelectPicker
            onSearch={fetchPackages}
            data={
              packages
                ? [{ label: "Tout", value: 0 }].concat(
                    packages.map((el) => {
                      return { label: el.name, value: el.id };
                    })
                  )
                : [{ label: "Tout", value: 0 }]
            }
            block
            noSearch
            value={filterModel.packageUmrahId}
            onSelect={(packageUmrahId) => {
              setfilterModel((prev) => {
                return { ...prev, packageUmrahId };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Vol: </label>
          <SelectPicker
            data={[{ label: "Tout", value: 0 }].concat(
              vols
                ? vols.map((el) => {
                    return {
                      label: (
                        <div style={{ color: "#232323" }}>
                          {moment(el.date).format("DD MMM YYYY")} ({" "}
                          <i style={{ color: "#999" }}>
                            {packages &&
                              packages.find((l) => l.id == el.packageUmrahId) &&
                              packages.find((l) => l.id == el.packageUmrahId)
                                .name}
                          </i>
                          )
                        </div>
                      ),
                      value: el.id,
                    };
                  })
                : []
            )}
            block
            noSearch
            value={filterModel.disponibilityId}
            onSelect={(disponibilityId) => {
              createAPIEndpoint(ENDPOINTS.Disponibility)
                .fetchById(disponibilityId)
                .then((res) => {
                  console.log(res.data);
                  setvol(res.data);
                });
              setfilterModel((prev) => {
                return { ...prev, disponibilityId };
              });
            }}
          />
        </Responsive>
      </Filter>
      <ExportAdd
        excelData={multiDataSet(data)}
        nameExcel="saleUmrah"
        size="lg"
        noAdd
      />{" "}
      <div
        onClick={(e) => {
          setcheckTime(Date.now());
          setcheckeds((prev) =>
            prev.length
              ? []
              : data
                  .filter((el) => el.hasVisa && !el.withoutVisa)
                  .map((el) => el.id)
          );
        }}
        style={{
          display: "inline-block",
          padding: "8px",
          borderRadius: "4px",
        }}
      >
        <Checkbox checked={checkeds.length > 0}></Checkbox> Sélectionner Tout
      </div>
      <div style={{ textAlign: "right", padding: "10px" }}>
        <Button
          loading={loadPrint}
          appearance="primary"
          color="blue"
          onClick={handlePrint}
        >
          imprimer visas
        </Button>{" "}
      </div>
      <Grid columns={columns} rows={data} />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100, 200, 500]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
      </div>
    </div>
  );
}
