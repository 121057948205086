export default [
  {
    id: "AF",
    name: "Afghanistan",
    continent: "Asia",
    currencyId: "AFN",
    phoneCode: "93",
    flag: "🇦🇫",
  },
  {
    id: "AX",
    name: "Åland Islands",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "35818",
    flag: "🇦🇽",
  },
  {
    id: "AL",
    name: "Albania",
    continent: "Europe",
    currencyId: "ALL",
    phoneCode: "355",
    flag: "🇦🇱",
  },
  {
    id: "DZ",
    name: "Algeria",
    continent: "Africa",
    currencyId: "DZD",
    phoneCode: "213",
    flag: "🇩🇿",
  },
  {
    id: "AS",
    name: "American Samoa",
    continent: "Australia",
    currencyId: "USD",
    phoneCode: "1684",
    flag: "🇦🇸",
  },
  {
    id: "AD",
    name: "Andorra",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "376",
    flag: "🇦🇩",
  },
  {
    id: "AO",
    name: "Angola",
    continent: "Africa",
    currencyId: "AOA",
    phoneCode: "244",
    flag: "🇦🇴",
  },
  {
    id: "AI",
    name: "Anguilla",
    continent: "North America",
    currencyId: "XCD",
    phoneCode: "1264",
    flag: "🇦🇮",
  },
  {
    id: "AQ",
    name: "Antarctica",
    continent: "Antarctica",
    currencyId: "-/-",
    phoneCode: "672",
    flag: "🇦🇶",
  },
  {
    id: "AG",
    name: "Antigua And Barbuda",
    continent: "North America",
    currencyId: "XCD",
    phoneCode: "1268",
    flag: "🇦🇬",
  },
  {
    id: "AR",
    name: "Argentina",
    continent: "South America",
    currencyId: "ARS",
    phoneCode: "54",
    flag: "🇦🇷",
  },
  {
    id: "AM",
    name: "Armenia",
    continent: "Asia",
    currencyId: "AMD",
    phoneCode: "374",
    flag: "🇦🇲",
  },
  {
    id: "AW",
    name: "Aruba",
    continent: "North America",
    currencyId: "ANG",
    phoneCode: "297",
    flag: "🇦🇼",
  },
  {
    id: "AC",
    name: "Ascension Island",
    continent: "Africa",
    currencyId: "SHP",
    phoneCode: "247",
    flag: "🇦🇨",
  },
  {
    id: "AU",
    name: "Australia",
    continent: "Australia",
    currencyId: "AUD",
    phoneCode: "61",
    flag: "🇦🇺",
  },
  {
    id: "AT",
    name: "Austria",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "43",
    flag: "🇦🇹",
  },
  {
    id: "AZ",
    name: "Azerbaijan",
    continent: "Asia",
    currencyId: "AZN",
    phoneCode: "994",
    flag: "🇦🇿",
  },
  {
    id: "BS",
    name: "Bahamas",
    continent: "North America",
    currencyId: "BSD",
    phoneCode: "1242",
    flag: "🇧🇸",
  },
  {
    id: "BH",
    name: "Bahrain",
    continent: "Asia",
    currencyId: "BHD",
    phoneCode: "973",
    flag: "🇧🇭",
  },
  {
    id: "BD",
    name: "Bangladesh",
    continent: "Asia",
    currencyId: "BDT",
    phoneCode: "880",
    flag: "🇧🇩",
  },
  {
    id: "BB",
    name: "Barbados",
    continent: "North America",
    currencyId: "BBD",
    phoneCode: "1246",
    flag: "🇧🇧",
  },
  {
    id: "BY",
    name: "Belarus",
    continent: "Europe",
    currencyId: "BYR",
    phoneCode: "375",
    flag: "🇧🇾",
  },
  {
    id: "BE",
    name: "Belgium",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "32",
    flag: "🇧🇪",
  },
  {
    id: "BZ",
    name: "Belize",
    continent: "North America",
    currencyId: "BZD",
    phoneCode: "51",
    flag: "🇧🇿",
  },
  {
    id: "BJ",
    name: "Benin",
    continent: "Africa",
    currencyId: "XOF",
    phoneCode: "229",
    flag: "🇧🇯",
  },
  {
    id: "BM",
    name: "Bermuda",
    continent: "North America",
    currencyId: "BMD",
    phoneCode: "1441",
    flag: "🇧🇲",
  },
  {
    id: "BT",
    name: "Bhutan",
    continent: "Asia",
    currencyId: "BTN",
    phoneCode: "975",
    flag: "🇧🇹",
  },
  {
    id: "BO",
    name: "Bolivia",
    continent: "South America",
    currencyId: "BOB",
    phoneCode: "591",
    flag: "🇧🇴",
  },
  {
    id: "BA",
    name: "Bosnia And Herzegovina",
    continent: "Europe",
    currencyId: "BAM",
    phoneCode: "387",
    flag: "🇧🇦",
  },
  {
    id: "BW",
    name: "Botswana",
    continent: "Africa",
    currencyId: "BWP",
    phoneCode: "267",
    flag: "🇧🇼",
  },
  {
    id: "BV",
    name: "Bouvet Island",
    continent: "Antarctica",
    currencyId: "NOK",
    phoneCode: "47",
    flag: "🇧🇻",
  },
  {
    id: "BR",
    name: "Brazil",
    continent: "South America",
    currencyId: "BRL",
    phoneCode: "55",
    flag: "🇧🇷",
  },
  {
    id: "IO",
    name: "British Indian Ocean Territory",
    continent: "Asia",
    currencyId: "USD",
    phoneCode: "246",
    flag: "🇮🇴",
  },
  {
    id: "VG",
    name: "British Virgin Islands",
    continent: "North America",
    currencyId: "USD",
    phoneCode: "1284",
    flag: "🇻🇬",
  },
  {
    id: "BN",
    name: "Brunei Darussalam",
    continent: "Asia",
    currencyId: "BND",
    phoneCode: "673",
    flag: "🇧🇳",
  },
  {
    id: "BG",
    name: "Bulgaria",
    continent: "Europe",
    currencyId: "BGN",
    phoneCode: "359",
    flag: "🇧🇬",
  },
  {
    id: "BF",
    name: "Burkina Faso",
    continent: "Africa",
    currencyId: "XOF",
    phoneCode: "226",
    flag: "🇧🇫",
  },
  {
    id: "MM",
    name: "Burma",
    continent: "Asia",
    currencyId: "MMK",
    phoneCode: "95",
    flag: "🇲🇲",
  },
  {
    id: "BI",
    name: "Burundi",
    continent: "Africa",
    currencyId: "BIF",
    phoneCode: "257",
    flag: "🇧🇮",
  },
  {
    id: "CI",
    name: "CÔte D'ivoire",
    continent: "Africa",
    currencyId: "XOF",
    phoneCode: "225",
    flag: "🇨🇮",
  },
  {
    id: "KH",
    name: "Cambodia",
    continent: "Asia",
    currencyId: "KHR",
    phoneCode: "855",
    flag: "🇰🇭",
  },
  {
    id: "CM",
    name: "Cameroon",
    continent: "Africa",
    currencyId: "XAF",
    phoneCode: "237",
    flag: "🇨🇲",
  },
  {
    id: "CA",
    name: "Canada",
    continent: "North America",
    currencyId: "CAD",
    phoneCode: "1",
    flag: "🇨🇦",
  },
  {
    id: "IC",
    name: "Canary Islands",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "34",
    flag: "🇮🇨",
  },
  {
    id: "CV",
    name: "Cape Verde",
    continent: "Africa",
    currencyId: "CVE",
    phoneCode: "238",
    flag: "🇨🇻",
  },
  {
    id: "KY",
    name: "Cayman Islands",
    continent: "North America",
    currencyId: "KYD",
    phoneCode: "1345",
    flag: "🇰🇾",
  },
  {
    id: "CF",
    name: "Central African Republic",
    continent: "Africa",
    currencyId: "XAF",
    phoneCode: "236",
    flag: "🇨🇫",
  },
  {
    id: "TD",
    name: "Chad",
    continent: "Africa",
    currencyId: "XAF",
    phoneCode: "235",
    flag: "🇹🇩",
  },
  {
    id: "CL",
    name: "Chile",
    continent: "South America",
    currencyId: "CLP",
    phoneCode: "56",
    flag: "🇨🇱",
  },
  {
    id: "CN",
    name: "China",
    continent: "Asia",
    currencyId: "CNY",
    phoneCode: "86",
    flag: "🇨🇳",
  },
  {
    id: "CX",
    name: "Christmas Island",
    continent: "Asia",
    currencyId: "AUD",
    phoneCode: "61",
    flag: "🇨🇽",
  },
  {
    id: "CC",
    name: "Cocos (keeling) Islands",
    continent: "Asia",
    currencyId: "AUD",
    phoneCode: "891",
    flag: "🇨🇨",
  },
  {
    id: "CO",
    name: "Colombia",
    continent: "South America",
    currencyId: "COP",
    phoneCode: "57",
    flag: "🇨🇴",
  },
  {
    id: "KM",
    name: "Comoros",
    continent: "Africa",
    currencyId: "KMF",
    phoneCode: "269",
    flag: "🇰🇲",
  },
  {
    id: "CG",
    name: "Congo",
    continent: "Africa",
    currencyId: "XAF",
    phoneCode: "242",
    flag: "🇨🇬",
  },
  {
    id: "CD",
    name: "Congo, The Democratic Republic Of The",
    continent: "Africa",
    currencyId: "CDF",
    phoneCode: "243",
    flag: "🇨🇩",
  },
  {
    id: "CK",
    name: "Cook Islands",
    continent: "Australia",
    currencyId: "NZD",
    phoneCode: "682",
    flag: "🇨🇰",
  },
  {
    id: "CR",
    name: "Costa Rica",
    continent: "North America",
    currencyId: "CRC",
    phoneCode: "56",
    flag: "🇨🇷",
  },
  {
    id: "HR",
    name: "Croatia",
    continent: "Europe",
    currencyId: "HRK",
    phoneCode: "385",
    flag: "🇭🇷",
  },
  {
    id: "CU",
    name: "Cuba",
    continent: "North America",
    currencyId: "CUP",
    phoneCode: "53",
    flag: "🇨🇺",
  },
  {
    id: "CY",
    name: "Cyprus",
    continent: "Asia",
    currencyId: "EUR",
    phoneCode: "357",
    flag: "🇨🇾",
  },
  {
    id: "CZ",
    name: "Czech Republic",
    continent: "Europe",
    currencyId: "CZK",
    phoneCode: "420",
    flag: "🇨🇿",
  },
  {
    id: "DK",
    name: "Denmark",
    continent: "Europe",
    currencyId: "DKK",
    phoneCode: "45",
    flag: "🇩🇰",
  },
  {
    id: "DG",
    name: "Diego Garcia",
    continent: "Africa",
    currencyId: "GBP",
    phoneCode: "246",
    flag: "🇩🇬",
  },
  {
    id: "DJ",
    name: "Djibouti",
    continent: "Africa",
    currencyId: "DJF",
    phoneCode: "253",
    flag: "🇩🇯",
  },
  {
    id: "DM",
    name: "Dominica",
    continent: "North America",
    currencyId: "XCD",
    phoneCode: "1767",
    flag: "🇩🇲",
  },
  {
    id: "DO",
    name: "Dominican Republic",
    continent: "South America",
    currencyId: "DOP",
    phoneCode: "1809",
    flag: "🇩🇴",
  },
  {
    id: "TL",
    name: "East Timor",
    continent: "Australia",
    currencyId: "IDR",
    phoneCode: "670",
    flag: "🇹🇱",
  },
  {
    id: "EC",
    name: "Ecuador",
    continent: "South America",
    currencyId: "USD",
    phoneCode: "593",
    flag: "🇪🇨",
  },
  {
    id: "EG",
    name: "Egypt",
    continent: "Africa",
    currencyId: "EGP",
    phoneCode: "20",
    flag: "🇪🇬",
  },
  {
    id: "SV",
    name: "El Salvador",
    continent: "North America",
    currencyId: "SVC",
    phoneCode: "53",
    flag: "🇸🇻",
  },
  {
    id: "GQ",
    name: "Equatorial Guinea",
    continent: "Africa",
    currencyId: "XAF",
    phoneCode: "240",
    flag: "🇬🇶",
  },
  {
    id: "ER",
    name: "Eritrea",
    continent: "Africa",
    currencyId: "ERN",
    phoneCode: "291",
    flag: "🇪🇷",
  },
  {
    id: "EE",
    name: "Estonia",
    continent: "Europe",
    currencyId: "EEK",
    phoneCode: "372",
    flag: "🇪🇪",
  },
  {
    id: "ET",
    name: "Ethiopia",
    continent: "Africa",
    currencyId: "ETB",
    phoneCode: "251",
    flag: "🇪🇹",
  },
  {
    id: "FK",
    name: "Falkland Islands (malvinas)",
    continent: "Africa",
    currencyId: "FLP",
    phoneCode: "500",
    flag: "🇫🇰",
  },
  {
    id: "FO",
    name: "Faroe Islands",
    continent: "Europe",
    currencyId: "DKK",
    phoneCode: "298",
    flag: "🇫🇴",
  },
  {
    id: "FJ",
    name: "Fiji",
    continent: "Australia",
    currencyId: "FJD",
    phoneCode: "679",
    flag: "🇫🇯",
  },
  {
    id: "FI",
    name: "Finland",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "358",
    flag: "🇫🇮",
  },
  {
    id: "FR",
    name: "France",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "33",
    flag: "🇫🇷",
  },
  {
    id: "GF",
    name: "French Guiana",
    continent: "South America",
    currencyId: "EUR",
    phoneCode: "594",
    flag: "🇫🇷",
  },
  {
    id: "PF",
    name: "French Polynesia",
    continent: "Australia",
    currencyId: "XPF",
    phoneCode: "689",
    flag: "🇵🇫",
  },
  {
    id: "TF",
    name: "French Southern Territories",
    continent: "Antarctica",
    currencyId: "EUR",
    phoneCode: "262",
    flag: "🇹🇫",
  },
  {
    id: "GA",
    name: "Gabon",
    continent: "Africa",
    currencyId: "XAF",
    phoneCode: "241",
    flag: "🇬🇦",
  },
  {
    id: "GE",
    name: "Georgia",
    continent: "Europe",
    currencyId: "GEL",
    phoneCode: "995",
    flag: "🇬🇪",
  },
  {
    id: "DE",
    name: "Germany",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "49",
    flag: "🇩🇪",
  },
  {
    id: "GH",
    name: "Ghana",
    continent: "Africa",
    currencyId: "GHC",
    phoneCode: "233",
    flag: "🇬🇭",
  },
  {
    id: "GI",
    name: "Gibraltar",
    continent: "Africa",
    currencyId: "GIP",
    phoneCode: "350",
    flag: "🇬🇮",
  },
  {
    id: "GR",
    name: "Greece",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "30",
    flag: "🇬🇷",
  },
  {
    id: "GL",
    name: "Greenland",
    continent: "North America",
    currencyId: "DKK",
    phoneCode: "299",
    flag: "🇬🇱",
  },
  {
    id: "GD",
    name: "Grenada",
    continent: "North America",
    currencyId: "XCD",
    phoneCode: "1473",
    flag: "🇬🇩",
  },
  {
    id: "GP",
    name: "Guadeloupe",
    continent: "North America",
    currencyId: "EUR",
    phoneCode: "590",
    flag: "🇬🇵",
  },
  {
    id: "GU",
    name: "Guam",
    continent: "Asia",
    currencyId: "USD",
    phoneCode: "1671",
    flag: "🇬🇺",
  },
  {
    id: "GT",
    name: "Guatemala",
    continent: "North America",
    currencyId: "GTQ",
    phoneCode: "52",
    flag: "🇬🇹",
  },
  {
    id: "GG",
    name: "Guernsey",
    continent: "Europe",
    currencyId: "GGP",
    phoneCode: "44148",
    flag: "🇬🇬",
  },
  {
    id: "GN",
    name: "Guinea",
    continent: "Africa",
    currencyId: "GNF",
    phoneCode: "224",
    flag: "🇬🇳",
  },
  {
    id: "GW",
    name: "Guinea-Bissau",
    continent: "Africa",
    currencyId: "XOF",
    phoneCode: "245",
    flag: "🇬🇼",
  },
  {
    id: "GY",
    name: "Guyana",
    continent: "South America",
    currencyId: "GYD",
    phoneCode: "592",
    flag: "🇬🇾",
  },
  {
    id: "HT",
    name: "Haiti",
    continent: "North America",
    currencyId: "USD",
    phoneCode: "59",
    flag: "🇭🇹",
  },
  {
    id: "HM",
    name: "Heard Island And Mcdonald Islands",
    continent: "Australia",
    currencyId: "AUD",
    phoneCode: "61",
    flag: "🇭🇲",
  },
  {
    id: "HN",
    name: "Honduras",
    continent: "North America",
    currencyId: "HNL",
    phoneCode: "54",
    flag: "🇭🇳",
  },
  {
    id: "HK",
    name: "Hong Kong",
    continent: "Asia",
    currencyId: "HKD",
    phoneCode: "852",
    flag: "🇭🇰",
  },
  {
    id: "HU",
    name: "Hungary",
    continent: "Europe",
    currencyId: "HUF",
    phoneCode: "36",
    flag: "🇭🇺",
  },
  {
    id: "IS",
    name: "Iceland",
    continent: "Europe",
    currencyId: "ISK",
    phoneCode: "354",
    flag: "🇦🇽",
  },
  {
    id: "IN",
    name: "India",
    continent: "Asia",
    currencyId: "INR",
    phoneCode: "91",
    flag: "🇮🇳",
  },
  {
    id: "ID",
    name: "Indonesia",
    continent: "Asia",
    currencyId: "IDR",
    phoneCode: "62",
    flag: "🇮🇩",
  },
  {
    id: "IR",
    name: "Iran",
    continent: "Asia",
    currencyId: "IRN",
    phoneCode: "98",
    flag: "🇮🇷",
  },
  {
    id: "IQ",
    name: "Iraq",
    continent: "Asia",
    currencyId: "IDR",
    phoneCode: "964",
    flag: "🇮🇶",
  },
  {
    id: "IE",
    name: "Ireland",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "353",
    flag: "🇮🇪",
  },
  {
    id: "IM",
    name: "Isle Of Man",
    continent: "Europe",
    currencyId: "IMP",
    phoneCode: "44",
    flag: "🇮🇲",
  },
  {
    id: "IL",
    name: "Israel",
    continent: "Asia",
    currencyId: "ILS",
    phoneCode: "972",
    flag: "🇮🇱",
  },
  {
    id: "IT",
    name: "Italy",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "39",
    flag: "🇮🇹",
  },
  {
    id: "JM",
    name: "Jamaica",
    continent: "North America",
    currencyId: "JMD",
    phoneCode: "1876",
    flag: "🇯🇲",
  },
  {
    id: "JP",
    name: "Japan",
    continent: "Asia",
    currencyId: "JPY",
    phoneCode: "81",
    flag: "🇯🇵",
  },
  {
    id: "JE",
    name: "Jersey",
    continent: "Europe",
    currencyId: "JEP",
    phoneCode: "44",
    flag: "🇯🇪",
  },
  {
    id: "JO",
    name: "Jordan",
    continent: "Asia",
    currencyId: "JOD",
    phoneCode: "962",
    flag: "🇯🇴",
  },
  {
    id: "KZ",
    name: "Kazakhstan",
    continent: "Asia",
    currencyId: "KZT",
    phoneCode: "7",
    flag: "🇰🇿",
  },
  {
    id: "KE",
    name: "Kenya",
    continent: "Africa",
    currencyId: "KES",
    phoneCode: "254",
    flag: "🇰🇪",
  },
  {
    id: "KI",
    name: "Kiribati",
    continent: "Australia",
    currencyId: "AUD",
    phoneCode: "686",
    flag: "🇰🇮",
  },
  {
    id: "KW",
    name: "Kuwait",
    continent: "Asia",
    currencyId: "KWD",
    phoneCode: "965",
    flag: "🇰🇼",
  },
  {
    id: "KG",
    name: "Kyrgyzstan",
    continent: "Asia",
    currencyId: "KGS",
    phoneCode: "996",
    flag: "🇰🇬",
  },
  {
    id: "LA",
    name: "Lao People's Democratic Republic",
    continent: "Asia",
    currencyId: "LAK",
    phoneCode: "856",
    flag: "🇱🇦",
  },
  {
    id: "LV",
    name: "Latvia",
    continent: "Europe",
    currencyId: "LVL",
    phoneCode: "371",
    flag: "🇱🇻",
  },
  {
    id: "LB",
    name: "Lebanon",
    continent: "Asia",
    currencyId: "LBP",
    phoneCode: "961",
    flag: "🇱🇧",
  },
  {
    id: "LS",
    name: "Lesotho",
    continent: "Africa",
    currencyId: "LSL",
    phoneCode: "266",
    flag: "🇱🇸",
  },
  {
    id: "LR",
    name: "Liberia",
    continent: "Africa",
    currencyId: "LRD",
    phoneCode: "231",
    flag: "🇱🇷",
  },
  {
    id: "LY",
    name: "Libya",
    continent: "Africa",
    currencyId: "LYD",
    phoneCode: "218",
    flag: "🇱🇾",
  },
  {
    id: "LI",
    name: "Liechtenstein",
    continent: "Europe",
    currencyId: "CHF",
    phoneCode: "423",
    flag: "🇱🇮",
  },
  {
    id: "LT",
    name: "Lithuania",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "370",
    flag: "🇱🇹",
  },
  {
    id: "LU",
    name: "Luxembourg",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "352",
    flag: "🇱🇺",
  },
  {
    id: "MO",
    name: "Macao",
    continent: "Asia",
    currencyId: "MOP",
    phoneCode: "853",
    flag: "🇲🇴",
  },
  {
    id: "MG",
    name: "Madagascar",
    continent: "Africa",
    currencyId: "MGA",
    phoneCode: "261",
    flag: "🇲🇬",
  },
  {
    id: "MW",
    name: "Malawi",
    continent: "Africa",
    currencyId: "MWK",
    phoneCode: "265",
    flag: "🇲🇼",
  },
  {
    id: "MY",
    name: "Malaysia",
    continent: "Asia",
    currencyId: "MYR",
    phoneCode: "60",
    flag: "🇲🇾",
  },
  {
    id: "MV",
    name: "Maldives",
    continent: "Asia",
    currencyId: "MVR",
    phoneCode: "960",
    flag: "🇲🇻",
  },
  {
    id: "ML",
    name: "Mali",
    continent: "Africa",
    currencyId: "XOF",
    phoneCode: "223",
    flag: "🇲🇱",
  },
  {
    id: "MT",
    name: "Malta",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "356",
    flag: "🇲🇹",
  },
  {
    id: "MH",
    name: "Marshall Islands",
    continent: "Australia",
    currencyId: "USD",
    phoneCode: "692",
    flag: "🇲🇭",
  },
  {
    id: "MQ",
    name: "Martinique",
    continent: "North America",
    currencyId: "EUR",
    phoneCode: "596",
    flag: "🇲🇶",
  },
  {
    id: "MR",
    name: "Mauritania",
    continent: "Africa",
    currencyId: "MRO",
    phoneCode: "222",
    flag: "🇲🇷",
  },
  {
    id: "MU",
    name: "Mauritius",
    continent: "Africa",
    currencyId: "MUR",
    phoneCode: "230",
    flag: "🇲🇺",
  },
  {
    id: "YT",
    name: "Mayotte",
    continent: "Africa",
    currencyId: "EUR",
    phoneCode: "269",
    flag: "🇾🇹",
  },
  {
    id: "MX",
    name: "Mexico",
    continent: "North America",
    currencyId: "MXN",
    phoneCode: "52",
    flag: "🇲🇽",
  },
  {
    id: "FM",
    name: "Micronesia, Federated States Of",
    continent: "Australia",
    currencyId: "USD",
    phoneCode: "691",
    flag: "🇫🇲",
  },
  {
    id: "MD",
    name: "Moldova",
    continent: "Europe",
    currencyId: "MDL",
    phoneCode: "373",
    flag: "🇲🇩",
  },
  {
    id: "MC",
    name: "Monaco",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "377",
    flag: "🇲🇨",
  },
  {
    id: "MN",
    name: "Mongolia",
    continent: "Asia",
    currencyId: "MNT",
    phoneCode: "976",
    flag: "🇲🇳",
  },
  {
    id: "ME",
    name: "Montenegro",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "382",
    flag: "🇲🇪",
  },
  {
    id: "MS",
    name: "Montserrat",
    continent: "North America",
    currencyId: "XCD",
    phoneCode: "1664",
    flag: "🇲🇸",
  },
  {
    id: "MA",
    name: "Morocco",
    continent: "Africa",
    currencyId: "MAD",
    phoneCode: "211",
    flag: "🇲🇦",
  },
  {
    id: "MZ",
    name: "Mozambique",
    continent: "Africa",
    currencyId: "MZM",
    phoneCode: "258",
    flag: "🇲🇿",
  },
  {
    id: "NA",
    name: "Namibia",
    continent: "Africa",
    currencyId: "ZAR",
    phoneCode: "264",
    flag: "🇳🇦",
  },
  {
    id: "NR",
    name: "Nauru",
    continent: "Australia",
    currencyId: "AUD",
    phoneCode: "674",
    flag: "🇳🇷",
  },
  {
    id: "NP",
    name: "Nepal",
    continent: "Asia",
    currencyId: "NPR",
    phoneCode: "977",
    flag: "🇳🇵",
  },
  {
    id: "NL",
    name: "Netherlands",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "31",
    flag: "🇳🇱",
  },
  {
    id: "AN",
    name: "Netherlands Antilles",
    continent: "North America",
    currencyId: "ANG",
    phoneCode: "599",
    flag: "🇳🇱",
  },
  {
    id: "NC",
    name: "New Caledonia",
    continent: "Australia",
    currencyId: "XPF",
    phoneCode: "687",
    flag: "🇳🇨",
  },
  {
    id: "NZ",
    name: "New Zealand",
    continent: "Australia",
    currencyId: "NZD",
    phoneCode: "64",
    flag: "🇳🇿",
  },
  {
    id: "NI",
    name: "Nicaragua",
    continent: "North America",
    currencyId: "NIO",
    phoneCode: "55",
    flag: "🇳🇮",
  },
  {
    id: "NE",
    name: "Niger",
    continent: "Africa",
    currencyId: "XOF",
    phoneCode: "227",
    flag: "🇳🇪",
  },
  {
    id: "NG",
    name: "Nigeria",
    continent: "Africa",
    currencyId: "NGN",
    phoneCode: "234",
    flag: "🇳🇬",
  },
  {
    id: "NU",
    name: "Niue",
    continent: "Australia",
    currencyId: "NZD",
    phoneCode: "683",
    flag: "🇳🇺",
  },
  {
    id: "NF",
    name: "Norfolk Island",
    continent: "Australia",
    currencyId: "AUD",
    phoneCode: "6723",
    flag: "🇳🇫",
  },
  {
    id: "KP",
    name: "North Korea",
    continent: "Asia",
    currencyId: "KPW",
    phoneCode: "850",
    flag: "🇰🇵",
  },
  {
    id: "MP",
    name: "Northern Mariana Islands",
    continent: "Australia",
    currencyId: "USD",
    phoneCode: "1670",
    flag: "🇲🇵",
  },
  {
    id: "NO",
    name: "Norway",
    continent: "Europe",
    currencyId: "NOK",
    phoneCode: "47",
    flag: "🇳🇴",
  },
  {
    id: "OM",
    name: "Oman",
    continent: "Asia",
    currencyId: "OMR",
    phoneCode: "968",
    flag: "🇴🇲",
  },
  {
    id: "PK",
    name: "Pakistan",
    continent: "Asia",
    currencyId: "PKR",
    phoneCode: "92",
    flag: "🇵🇰",
  },
  {
    id: "PW",
    name: "Palau",
    continent: "Australia",
    currencyId: "USD",
    phoneCode: "680",
    flag: "🇵🇼",
  },
  {
    id: "PS",
    name: "Palestinian Territory",
    continent: "Asia",
    currencyId: "ILS",
    phoneCode: "970",
    flag: "🇵🇸",
  },
  {
    id: "PA",
    name: "Panama",
    continent: "South America",
    currencyId: "USD",
    phoneCode: "57",
    flag: "🇵🇦",
  },
  {
    id: "PG",
    name: "Papua New Guinea",
    continent: "Australia",
    currencyId: "PGK",
    phoneCode: "675",
    flag: "🇵🇬",
  },
  {
    id: "PY",
    name: "Paraguay",
    continent: "South America",
    currencyId: "PYG",
    phoneCode: "595",
    flag: "🇵🇾",
  },
  {
    id: "PE",
    name: "Peru",
    continent: "South America",
    currencyId: "PEN",
    phoneCode: "51",
    flag: "🇵🇪",
  },
  {
    id: "PH",
    name: "Philippines",
    continent: "Asia",
    currencyId: "PHP",
    phoneCode: "63",
    flag: "🇵🇭",
  },
  {
    id: "PN",
    name: "Pitcairn",
    continent: "Australia",
    currencyId: "NZD",
    phoneCode: "649",
    flag: "🇵🇳",
  },
  {
    id: "PL",
    name: "Poland",
    continent: "Europe",
    currencyId: "PLN",
    phoneCode: "48",
    flag: "🇵🇱",
  },
  {
    id: "PT",
    name: "Portugal",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "351",
    flag: "🇵🇹",
  },
  {
    id: "PR",
    name: "Puerto Rico",
    continent: "North America",
    currencyId: "USD",
    phoneCode: "1939",
    flag: "🇵🇷",
  },
  {
    id: "QA",
    name: "Qatar",
    continent: "Asia",
    currencyId: "QAR",
    phoneCode: "974",
    flag: "🇶🇦",
  },
  {
    id: "RE",
    name: "RÉunion",
    continent: "Africa",
    currencyId: "EUR",
    phoneCode: "262",
    flag: "🇷🇪",
  },
  {
    id: "MK",
    name: "Republic of Macedonia",
    continent: "Europe",
    currencyId: "MKD",
    phoneCode: "389",
    flag: "🇲🇰",
  },
  {
    id: "RO",
    name: "Romania",
    continent: "Europe",
    currencyId: "RON",
    phoneCode: "40",
    flag: "🇷🇴",
  },
  {
    id: "RU",
    name: "Russian Federation",
    continent: "Asia",
    currencyId: "RUB",
    phoneCode: "7",
    flag: "🇷🇺",
  },
  {
    id: "RW",
    name: "Rwanda",
    continent: "Africa",
    currencyId: "RWF",
    phoneCode: "250",
    flag: "🇷🇼",
  },
  {
    id: "SH",
    name: "Saint Helena",
    continent: "Africa",
    currencyId: "SHP",
    phoneCode: "290",
    flag: "🇸🇭",
  },
  {
    id: "KN",
    name: "Saint Kitts And Nevis",
    continent: "North America",
    currencyId: "XCD",
    phoneCode: "1869",
    flag: "🇰🇳",
  },
  {
    id: "LC",
    name: "Saint Lucia",
    continent: "South America",
    currencyId: "XCD",
    phoneCode: "1758",
    flag: "🇱🇨",
  },
  {
    id: "PM",
    name: "Saint Pierre And Miquelon",
    continent: "North America",
    currencyId: "EUR",
    phoneCode: "508",
    flag: "🇵🇲",
  },
  {
    id: "VC",
    name: "Saint Vincent and the Grenadines",
    continent: "South America",
    currencyId: "XCD",
    phoneCode: "1784",
    flag: "🇻🇨",
  },
  {
    id: "WS",
    name: "Samoa",
    continent: "Australia",
    currencyId: "WST",
    phoneCode: "685",
    flag: "🇼🇸",
  },
  {
    id: "SM",
    name: "San Marino",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "378",
    flag: "🇸🇲",
  },
  {
    id: "ST",
    name: "Sao Tome And Principe",
    continent: "Africa",
    currencyId: "STD",
    phoneCode: "239",
    flag: "🇸🇹",
  },
  {
    id: "SA",
    name: "Saudi Arabia",
    continent: "Asia",
    currencyId: "SAR",
    phoneCode: "966",
    flag: "🇸🇦",
  },
  {
    id: "SN",
    name: "Senegal",
    continent: "Africa",
    currencyId: "XOF",
    phoneCode: "221",
    flag: "🇸🇳",
  },
  {
    id: "RS",
    name: "Serbia",
    continent: "Europe",
    currencyId: "RSD",
    phoneCode: "381",
    flag: "🇷🇸",
  },
  {
    id: "SC",
    name: "Seychelles",
    continent: "Africa",
    currencyId: "SCR",
    phoneCode: "248",
    flag: "🇸🇨",
  },
  {
    id: "SL",
    name: "Sierra Leone",
    continent: "Africa",
    currencyId: "SLL",
    phoneCode: "232",
    flag: "🇸🇱",
  },
  {
    id: "SG",
    name: "Singapore",
    continent: "Asia",
    currencyId: "SGD",
    phoneCode: "65",
    flag: "🇸🇬",
  },
  {
    id: "SK",
    name: "Slovakia",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "421",
    flag: "🇸🇰",
  },
  {
    id: "SI",
    name: "Slovenia",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "386",
    flag: "🇸🇮",
  },
  {
    id: "SB",
    name: "Solomon Islands",
    continent: "Australia",
    currencyId: "SBD",
    phoneCode: "677",
    flag: "🇸🇧",
  },
  {
    id: "SO",
    name: "Somalia",
    continent: "Africa",
    currencyId: "SOS",
    phoneCode: "252",
    flag: "🇸🇴",
  },
  {
    id: "ZA",
    name: "South Africa",
    continent: "Africa",
    currencyId: "ZAR",
    phoneCode: "27",
    flag: "🇿🇦",
  },
  {
    id: "GS",
    name: "South Georgia And The South Sandwich Islands",
    continent: "South America",
    currencyId: "GBP",
    phoneCode: "500",
    flag: "🇬🇸",
  },
  {
    id: "KR",
    name: "South Korea",
    continent: "Asia",
    currencyId: "KRW",
    phoneCode: "82",
    flag: "🇰🇷",
  },
  {
    id: "ES",
    name: "Spain",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "34",
    flag: "🇪🇸",
  },
  {
    id: "LK",
    name: "Sri Lanka",
    continent: "Asia",
    currencyId: "LKR",
    phoneCode: "94",
    flag: "🇱🇰",
  },
  {
    id: "SD",
    name: "Sudan",
    continent: "Africa",
    currencyId: "SDD",
    phoneCode: "249",
    flag: "🇸🇩",
  },
  {
    id: "SR",
    name: "Suriname",
    continent: "South America",
    currencyId: "SRD",
    phoneCode: "597",
    flag: "🇸🇷",
  },
  {
    id: "SJ",
    name: "Svalbard And Jan Mayen",
    continent: "Europe",
    currencyId: "NOK",
    phoneCode: "47",
    flag: "🇸🇯",
  },
  {
    id: "SZ",
    name: "Swaziland",
    continent: "Africa",
    currencyId: "SZL",
    phoneCode: "268",
    flag: "🇸🇿",
  },
  {
    id: "SE",
    name: "Sweden",
    continent: "Europe",
    currencyId: "SEK",
    phoneCode: "46",
    flag: "🇸🇪",
  },
  {
    id: "CH",
    name: "Switzerland",
    continent: "Europe",
    currencyId: "CHF",
    phoneCode: "41",
    flag: "🇨🇭",
  },
  {
    id: "SY",
    name: "Syrian Arab Republic",
    continent: "Asia",
    currencyId: "SYP",
    phoneCode: "963",
    flag: "🇸🇾",
  },
  {
    id: "TW",
    name: "Taiwan",
    continent: "Asia",
    currencyId: "TWD",
    phoneCode: "886",
    flag: "🇹🇼",
  },
  {
    id: "TJ",
    name: "Tajikistan",
    continent: "Asia",
    currencyId: "RUB",
    phoneCode: "992",
    flag: "🇹🇯",
  },
  {
    id: "TZ",
    name: "Tanzania, United Republic Of",
    continent: "Africa",
    currencyId: "TZS",
    phoneCode: "255",
    flag: "🇹🇿",
  },
  {
    id: "TH",
    name: "Thailand",
    continent: "Asia",
    currencyId: "THB",
    phoneCode: "66",
    flag: "🇹🇭",
  },
  {
    id: "GM",
    name: "The Gambia",
    continent: "Africa",
    currencyId: "GMD",
    phoneCode: "220",
    flag: "🇬🇲",
  },
  {
    id: "TG",
    name: "Togo",
    continent: "Africa",
    currencyId: "XOF",
    phoneCode: "228",
    flag: "🇹🇬",
  },
  {
    id: "TK",
    name: "Tokelau",
    continent: "Australia",
    currencyId: "NZD",
    phoneCode: "690",
    flag: "🇹🇰",
  },
  {
    id: "TO",
    name: "Tonga",
    continent: "Australia",
    currencyId: "TOP",
    phoneCode: "676",
    flag: "🇹🇴",
  },
  {
    id: "TT",
    name: "Trinidad And Tobago",
    continent: "South America",
    currencyId: "TTD",
    phoneCode: "1868",
    flag: "🇹🇹",
  },
  {
    id: "TA",
    name: "Tristan da Cunha",
    continent: "Africa",
    currencyId: "GBP",
    phoneCode: "290",
    flag: "🇸🇭",
  },
  {
    id: "TN",
    name: "Tunisia",
    continent: "Africa",
    currencyId: "TND",
    phoneCode: "216",
    flag: "🇹🇳",
  },
  {
    id: "TR",
    name: "Turkey",
    continent: "Asia",
    currencyId: "TRY",
    phoneCode: "90",
    flag: "🇹🇷",
  },
  {
    id: "TM",
    name: "Turkmenistan",
    continent: "Asia",
    currencyId: "TMM",
    phoneCode: "993",
    flag: "🇹🇲",
  },
  {
    id: "TC",
    name: "Turks And Caicos Islands",
    continent: "North America",
    currencyId: "USD",
    phoneCode: "1649",
    flag: "🇹🇨",
  },
  {
    id: "TV",
    name: "Tuvalu",
    continent: "Australia",
    currencyId: "TVD",
    phoneCode: "688",
    flag: "🇹🇻",
  },
  {
    id: "UG",
    name: "Uganda",
    continent: "Africa",
    currencyId: "UGX",
    phoneCode: "256",
    flag: "🇺🇬",
  },
  {
    id: "UA",
    name: "Ukraine",
    continent: "Europe",
    currencyId: "UAH",
    phoneCode: "380",
    flag: "🇺🇦",
  },
  {
    id: "AE",
    name: "United Arab Emirates",
    continent: "Asia",
    currencyId: "AED",
    phoneCode: "971",
    flag: "🇦🇪",
  },
  {
    id: "GB",
    name: "United Kingdom",
    continent: "Europe",
    currencyId: "GBP",
    phoneCode: "44",
    flag: "🇬🇧",
  },
  {
    id: "US",
    name: "United States",
    continent: "North America",
    currencyId: "USD",
    phoneCode: "1",
    flag: "🇺🇸",
  },
  {
    id: "UY",
    name: "Uruguay",
    continent: "South America",
    currencyId: "UYU",
    phoneCode: "598",
    flag: "🇺🇾",
  },
  {
    id: "UZ",
    name: "Uzbekistan",
    continent: "Asia",
    currencyId: "UZS",
    phoneCode: "998",
    flag: "🇺🇿",
  },
  {
    id: "VU",
    name: "Vanuatu",
    continent: "Australia",
    currencyId: "VUV",
    phoneCode: "678",
    flag: "🇻🇺",
  },
  {
    id: "VA",
    name: "Vatican City",
    continent: "Europe",
    currencyId: "EUR",
    phoneCode: "3906",
    flag: "🇻🇦",
  },
  {
    id: "VE",
    name: "Venezuela",
    continent: "South America",
    currencyId: "VEB",
    phoneCode: "58",
    flag: "🇻🇪",
  },
  {
    id: "VN",
    name: "Viet Nam",
    continent: "Asia",
    currencyId: "VND",
    phoneCode: "84",
    flag: "🇻🇳",
  },
  {
    id: "VI",
    name: "Virgin Islands, U.s.",
    continent: "South America",
    currencyId: "USD",
    phoneCode: "1340",
    flag: "🇻🇮",
  },
  {
    id: "WF",
    name: "Wallis And Futuna",
    continent: "Australia",
    currencyId: "XPF",
    phoneCode: "681",
    flag: "🇼🇫",
  },
  {
    id: "EH",
    name: "Western Sahara",
    continent: "Africa",
    currencyId: "MAD",
    phoneCode: "212",
    flag: "🇪🇭",
  },
  {
    id: "YE",
    name: "Yemen",
    continent: "Asia",
    currencyId: "YER",
    phoneCode: "967",
    flag: "🇾🇪",
  },
  {
    id: "ZM",
    name: "Zambia",
    continent: "Africa",
    currencyId: "ZMK",
    phoneCode: "260",
    flag: "🇿🇲",
  },
  {
    id: "ZW",
    name: "Zimbabwe",
    continent: "Africa",
    currencyId: "ZWD",
    phoneCode: "263",
    flag: "🇿🇼",
  },
];
