import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  DateRangePicker,
  Input,
  Pagination,
  SelectPicker,
  Tag,
} from "rsuite";
import { APi } from "../../Api";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import {
  dateFilter,
  dateTypes,
  serviceTypes,
  transactionEvents,
} from "../../Constants/types";
import format_number from "../../Helpers/number_formatter";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import ResumeCard from "../../Components/ResumeCard";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { useRecoilState } from "recoil";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import { createAPIEndpoint } from "../../Api/authenticated.requests";
export default function AccountsPayableLedgerDetails({ id }) {
  const [data, setdata] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [totalDebit, settotalDebit] = useState(0);
  const [totalCredit, settotalCredit] = useState(0);
  const [clients, setclients] = useState([]);
  const element = useRef();
  const [state, setstate] = useRecoilState(exportAddAtom);

  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 100,
    date: null,
    dateTo: null,
    dateFrom: null,
  });
  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    APi.createAPIEndpoint(
      APi.ENDPOINTS.Ledger,
      {
        ...filterModel,
        clientId: id,
      },
      "/getLedgerClientDetails"
    )
      .customGet()
      .then((res) => {
        console.log(res.data);
        var d = [];
        // for (let i = 0; i < 50; i++) {

        // }
        d = d.concat(res.data);
        setdata(d);
        let vvv = res.data.map((el) => el.debit).reduce((a, b) => a + b);
        settotalDebit(vvv);
        let uuu = res.data.map((el) => el.credit).reduce((a, b) => a + b);
        settotalCredit(uuu);
        settotalCount(res.data.length);
      })
      .catch((e) => {});
  };
  // const getProgressiveSolde = (dateTo = null) => {
  //   alert("test");
  //   createAPIEndpoint(APi.ENDPOINTS.Client + "/GetProgressiveSolde/" + id, {
  //     dateTo,
  //   })
  //     .customGet()
  //     .then((res) => console.log(res.data));
  // };
  const save = () => {
    const doc = new jsPDF();

    // // It can parse html:
    // // <table id="my-table"><!-- ... --></table>
    // autoTable(doc, { html: '#my-table' })
    // = function () {
    //   var doc = new jsPDF()
    //   doc.text('Nested tables', 14, 16)

    //   var nestedTableHeight = 100
    var nestedTableCell = {
      content: "",
      // Dynamic height of nested tables are not supported right now
      // so we need to define height of the parent cell
      styles: { minCellHeight: 100 },
    };
    doc.autoTable({
      styles: { lineWidth: 0 },
      body: [[nestedTableCell]],
      startY: 20,
      didDrawCell: function (data) {
        if (data.row.index === 0 && data.row.section === "body") {
          doc.autoTable({
            startY: data.cell.y + 2,
            margin: { left: data.cell.x + 2 },
            tableWidth: 48,
            styles: {
              maxCellHeight: 4,
              lineWidth: 0,
            },

            body: [{ des: "test" }, { des: "address 4000  sousse " }],
          });
        }
      },
    });

    //   return doc
    // }
    // Or use javascript directly:
    var img = new Image();
    img.src = "/logo.png";
    doc.addImage(img, "png", 10, 10, 28, 8);
    doc.setFontSize(20);
    doc.setTextColor("rgb(76, 103, 194)");
    doc.text("Kounouz Travel", 10, 28);

    doc.autoTable({ startY: 30, body: [{ des: "lel" }] });
    autoTable(doc, {
      startY: 50,
      head: [["Date", "Débit", "Crebit", "Description", "Solde Progressif"]],
      body: data.map((el) => Object.values(el)),
    });

    doc.save("table.pdf");
  };
  // LIFE CYCLES
  useEffect(() => {
    fetch();
    // getProgressiveSolde();
  }, [id]);
  return (
    <div>
      <Filter search={() => fetch()}>
        <Responsive m={6} l={3} xl={3} className="p-5">
          <label>Dates: </label>
          <SelectPicker
            data={dateTypes}
            block
            noSearch
            value={filterModel.dateType}
            onSelect={(dateType) => {
              let today = new Date(moment(Date.now()).format("yyyy-MM-DD"));
              console.log(
                //
                today
              );
              setfilterModel((prev) => {
                return {
                  ...prev,
                  dateType,
                  date:
                    dateType == 7 || dateType == 1
                      ? today
                      : dateType == 2
                      ? moment(moment(Date.now()).add(-1, "d")).format(
                          "yyyy-MM-DD"
                        )
                      : null,
                  dateFrom:
                    dateType == 6
                      ? today
                      : dateType == 3
                      ? moment().startOf("month").format("yyyy-MM-DD")
                      : dateType == 4
                      ? moment(Date.now())
                          .subtract(1, "months")
                          .startOf("month")
                          .format("yyyy-MM-DD")
                      : dateType == 5
                      ? moment().startOf("year").format("yyyy-MM-DD")
                      : null,
                  dateTo:
                    dateType == 6
                      ? new Date(
                          moment(moment(Date.now()).add(1, "d")).format(
                            "yyyy-MM-DD"
                          )
                        )
                      : dateType == 3
                      ? today
                      : dateType == 4
                      ? moment(Date.now())
                          .subtract(1, "months")
                          .endOf("month")
                          .format("yyyy-MM-DD")
                      : null,
                };
              });
            }}
          />
        </Responsive>
        {filterModel.dateType == 7 && (
          <Responsive m={6} l={3} xl={3} className="p-5">
            <label>Date: </label>
            <Input
              type="date"
              value={filterModel.date}
              onChange={(date) => {
                setfilterModel((prev) => {
                  return { ...prev, date };
                });
              }}
            />
          </Responsive>
        )}
        {filterModel.dateType == 6 && (
          <Responsive m={6} l={3} xl={3} className="p-5">
            <label>Plage du temps: </label>
            <DateRangePicker
              block
              value={[filterModel.dateFrom, filterModel.dateTo]}
              onChange={(vs) => {
                setfilterModel((prev) => ({
                  ...prev,
                  dateFrom: vs[0],
                  dateTo: vs[1],
                }));
              }}
            />
          </Responsive>
        )}
        <Responsive m={6} l={6} xl={6} className="p-5">
          <label>Service : </label>
          <SelectPicker
            data={serviceTypes}
            block
            noSearch
            value={filterModel.service}
            onSelect={(service) => {
              setfilterModel((prev) => {
                return { ...prev, service };
              });
            }}
          />
        </Responsive>
      </Filter>
      <hr></hr>
      <div style={{ textAlign: "right" }}>
        <Button
          onClick={save}
          style={{
            color: "#fff",
            background: "rgb(90,44,62)",
            padding: "8px 30px",
            margin: "5px",
          }}
          id="print"
          // onClick={() => {
        >
          <BsFillFileEarmarkPdfFill /> Imprimer
        </Button>
      </div>
      <Responsive className="p-10" s={6} m={6} l={6} xl={6}>
        <ResumeCard text="Total Débit" color="0,169,141" amount={totalDebit} />
      </Responsive>
      <Responsive className="p-10" s={6} m={6} l={6} xl={6}>
        <ResumeCard
          text="Total Crédit"
          amount={totalCredit}
          color="229,57,53"
        />
      </Responsive>
      <div ref={element} style={{ maxWidth: "1000px" }} id="printedt____">
        <Grid
          noAdvancedActions // for custom advanced actions
          columns={columns}
          rows={data}
        />
      </div>

      {/* <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) =>
           {window.scrollTo(0, 0); setfilterModel((prev) => {
              return { ...prev, page };
            })}
          }
          onChangeLimit={(take) => {
            setfilterModel((prev) => {
              return { ...prev, take };
            });
          }}
        />
      </div> */}
    </div>
  );
}
const columns = [
  {
    value: "date",
    value2: "event",
    name: "Date",
    render: (v, v2) => {
      return (
        <div
          style={{
            maxWidth: "150px",
            padding: "2px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: "#4667d1",
            fontSize: "12px",
            textTransform: "upperCase",
            fontWeight: "500",
          }}
        >
          {/* <Tag
            style={{ maxWidth: "120px", fontSize: "11px" }}
            color={
              v2 == 1
                ? "blue"
                : v2 == 2
                ? "green"
                : v2 == 3
                ? "violet"
                : v2 == 4
                ? "black"
                : "yellow"
            }
          >
            {transactionEvents.find((el) => el.value == v2).label}
          </Tag> */}
          {moment(v).format("yyyy-MM-DD")}
        </div>
      );
    },
  },
  // {
  //   value: "event",
  //   name: "Type",
  //   render: (v) => (

  //   ),
  // },
  {
    value: "description",
    name: "Description",
    render: (v) => (
      <b style={{ letterSpacing: "1px", color: "#111", fontSize: "12px" }}>
        {v}
      </b>
    ),
  },
  {
    value: "debit",
    name: "Débit",
    render: (v) => (
      <b style={{ fontSize: "16px" }}>{v ? format_number(v) : 0}</b>
    ),
  },
  {
    value: "credit",
    name: "Crédit ",
    render: (v) => (
      <b style={{ fontSize: "16px" }}>{v ? format_number(v) : 0}</b>
    ),
  },
  {
    value: "progressiveBalance",
    name: "Solde Progressif ",
    render: (v) => (
      <b style={{ fontSize: "16px" }}>{v ? format_number(v) : 0}</b>
    ),
  },
];
