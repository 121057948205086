import "../../../style/packageUmrah/hotelMakkah/hotelMakkah.css";
import { DestinationUmrahModel } from "../../../Models/packageomra.model";

const PackageUmrahAddButton = ({
  destinationModel,
  setModel,
  setDestinationModel,
}) => {
  const updateHotelsAndPrices = () => {
    setModel((prev) => {
      const updatedHotels = (destinationModel.hotelsUmrah || []).map(
        (hotel) => ({
          ...hotel,
          isMakkah: destinationModel.name?.toLowerCase() === "makkah",
        })
      );

      const updatedDestinationModel = {
        ...destinationModel,
        hotelsUmrah: updatedHotels,
      };

      const updatedPrices = prev.prices.map((price) => {
        const hotelName = prev.destinations
          .flatMap((destination) => destination.hotelsUmrah || [])
          .find((hotel) =>
            updatedDestinationModel.hotelsUmrah?.some(
              (modelHotel) =>
                (hotel.id && hotel.id === modelHotel.id) ||
                (hotel.uuid && hotel.uuid === modelHotel.uuid)
            )
          )?.name;

        if (price.hotelMakkahName === hotelName) {
          return {
            ...price,
            hotelMakkahName: updatedDestinationModel.hotelsUmrah?.[0]?.name,
          };
        }
        return price;
      });

      const updatedDestinations = [...prev.destinations];
      const destinationIndex = updatedDestinations.findIndex(
        (destination) =>
          (destination.id && destination.id === destinationModel.id) ||
          (destination.uuid && destination.uuid === destinationModel.uuid)
      );

      if (destinationIndex !== -1) {
        updatedDestinations[destinationIndex] = updatedDestinationModel;
      } else {
        updatedDestinations.push({
          ...updatedDestinationModel,
          uuid: Date.now(),
        });
      }

      return {
        ...prev,
        destinations: updatedDestinations,
        prices: updatedPrices,
      };
    });

    setDestinationModel(new DestinationUmrahModel());
  };

  const buttonText =
    destinationModel.id || destinationModel.uuid ? "modifier" : "ajouter";

  return (
    <div className="button-container">
      <button className="button-primary" onClick={updateHotelsAndPrices}>
        {buttonText}
      </button>
    </div>
  );
};

export default PackageUmrahAddButton;
