const styles = {
  thead: {
    background: "rgb(70, 103, 209)",
    color: "#fff",
  },
  th: {
    padding: "5px 8px",
    textAlign: "left",
  },
  td: {
    padding: "5px 8px",
  },
};

export default styles;
