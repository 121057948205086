const styles = {
  extratsMainContainer: {
    display: "flex",
    flexDirection: "column",
  },
  conatainer: {
    display: "flex",
  },
};

export default styles;
