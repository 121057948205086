import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Input } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api/";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import moment from "moment";
import { agencyAtom } from "../../Atoms/agency.atom";
import { PackageUmrahAtom } from "../../Atoms/packageUmrah.atom";
import format_number from "../../Helpers/number_formatter";
import { DefaultTemplateUmrah } from "../../Atoms/defaultuMrahPack";
import AddEditPackageUmrah from "./AddEditPackageUmrah.component";
import { CaracteristiquesPackageUmrahModel } from "../../Models/packageomra.model";
import appendModelToFormData from "../../Helpers/appendToFormData";
import { Notification, toaster } from "rsuite";
import Translation from "../../translation/translation";

export default function PackagesUmrah(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [providers, setproviders] = useState([]);
  const agency = useRecoilValue(agencyAtom);
  const [template, settemplate] = useRecoilState(DefaultTemplateUmrah);
  const [totalCount, settotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 20,
    q: "",
    date: null,
  });

  const [error, setError] = useState("");
  const [model, setmodel] = useRecoilState(PackageUmrahAtom);

  const [state, setstate] = useRecoilState(exportAddAtom);
  const reset = () => {
    setmodel(template);
    setError("");
  };
  const fetchProviders = (q) => {
    if (typeof q == "undefined" || q.length > 0) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Provider, { q }, "/autocomplete2")
        .customGet()
        .then((res) => setproviders(res.data));
    }
  };

  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah, {
      ...filterModel,
      date: filterModel.date
        ? moment(filterModel.date).format("DD MMM YYYY")
        : null,
    })
      .customGet()
      .then((res) => {
        setdata(res.data.data);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
      })
      .catch((e) => {
        setError(e.Message);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      });
  };

  const save = () => {
    setstate((prev) => ({ ...prev, loading: true }));
    let m = model;
    const formData = new FormData();

    appendModelToFormData(formData, m);

    const apiEndpoint = APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah);
    const apiCall = m.id
      ? apiEndpoint.update(m.id, formData)
      : apiEndpoint.create(formData);

    apiCall
      .then(() => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: m.id
            ? "Élément a été bien modifié !"
            : "Élément a été bien ajouté !",
          showConfirmButton: false,
          timer: 1500,
        });
        reset();
        setstate((prev) => ({ ...prev, open: false, loading: false }));
      })
      .catch((error) => {
        setstate((prev) => ({ ...prev, loading: false }));
        toaster.push(
          <Notification type="error" header={Translation.errorHeader} closable>
            {error?.response?.data?.title || Translation.error}
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah)
      .delete(id)

      .then((res) => {
        APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah + "/getForPrices", {
          q: "",
          notFromCache: true,
        })
          .customGet()
          .then((res) => fetch());
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };

  const getDefault = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah + "/getDefault", {})
      .customGet()
      .then((res) => {
        let _m = { ...res.data };
        _m.isDefault = false;
        let _d = Date.now();

        if (_m.disponibilities) {
          _m.disponibilities = _m.disponibilities.map((d, i) => {
            return { ...d, index: _d + i };
          });
        }
        if (_m.differentFees) {
          _m.differentFees = _m.differentFees.map((d, i) => {
            return { ...d, index: _d + i };
          });
        }
        if (_m.packageUmrahCosts) {
          _m.packageUmrahCosts = _m.packageUmrahCosts.map((d, i) => {
            delete d.updatedDate;
            return { ...d, uuid: _d + i };
          });
        }
        if (_m.prices) {
          _m.prices = _m.prices.map((d, i) => {
            return d;
          });
        }
        _m.imageUrls = [];
        _m.destinations = [];
        _m.images = [];
        _m.idsDelete = [];
        _m.caracteristique = new CaracteristiquesPackageUmrahModel();
        _m.isDefault = false;
        setmodel(_m);
        settemplate(_m);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getBYId = (id) => {
    setError("");
    setIsLoading(true);
    APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah)
      .fetchById(id)
      .then((res) => {
        let m = { ...res.data };
        setmodel(m);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    reset();
    fetchProviders();
    setmodel(template);
    getDefault();
  }, []);

  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);

  return (
    <div>
      <Filter search={() => fetch()}>
        <Responsive m={6} l={6} xl={6} className="p-10">
          <label>Désignation: </label>
          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </Responsive>

        <Responsive m={6} l={6} xl={6} className="p-10">
          <label>Date : </label>
          <Input
            type="date"
            value={filterModel.date}
            onChange={(date) => {
              setfilterModel((prev) => {
                return { ...prev, date };
              });
            }}
          />
        </Responsive>
      </Filter>
      <ExportAdd
        ActionOnClose={reset}
        noExport
        size="xl"
        save={save}
        title="Ajouter package umrah"
        AddComponent={
          <AddEditPackageUmrah
            providers={providers}
            fetchProviders={fetchProviders}
            error={error}
            loading={state.loading}
            isLoading={isLoading}
          />
        }
      />{" "}
      <Grid
        editAction={(id) => {
          getBYId(id);
          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
      </div>
    </div>
  );
}

const columns = [
  {
    value: "name",
    name: "Nom",
    render: (v) => <a>{v ? v.toUpperCase() : ""}</a>,
  },
  {
    value: "commission",
    name: "Commission",
    render: (v) => <a>{format_number(v)}</a>,
  },
  {
    value: "b2Bcommission",
    name: "Commission B2B",
    render: (v) => <a>{format_number(v)}</a>,
  },
];
