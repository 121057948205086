import { atom } from "recoil";
import ForcastModel from "../Models/ForcastModel";
import { ForcastVoModel } from "../Models/ForcastModel";

export const ForcastAtom = new atom({
  key: "ForcastAtom",
  default: new ForcastModel(),
  reset: true,
});
export const ForcastVoAtom = new atom({
  key: "ForcastVoAtom",
  default: new ForcastVoModel(),
  reset: true,
});
