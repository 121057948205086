import React from "react";
import { Button, Loader, Modal } from "rsuite";
import AddProgram from "../../Screens/Program/addProgram";
import styles from "./styles/modalAddProgramStyles";

export default function AddProgramModal({
  open,
  handleClose,
  save,
  error,
  isLoading,
}) {
  return (
    <div style={styles.modalContainer}>
      <Modal
        size="xl"
        overflow={false}
        style={styles.modal}
        open={open}
        onClose={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Ajouter un programme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={styles.modalBody}>
            <AddProgram isLoading={false} error={error} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={save} appearance="primary">
            {isLoading ? <Loader size="sm" /> : "Enregistrer"}
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
