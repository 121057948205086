import { Checkbox, Input } from "rsuite";
import format_number from "../../../../Helpers/number_formatter";

const RowOfTable = ({ index, model, data, keyName, setModel, grouppedBy }) => {
  return (
    <tr
      style={{
        background: index % 2 ? "rgb(244,81,108,0)" : "rgb(70,103,209,0.2)",
        borderBottom: "3px solid #eee",
      }}
    >
      <td>
        <Checkbox
          onChange={(v) => {
            let prices = [...model.prices];
            data[keyName].map((el) => {
              let _index = prices.findIndex(
                (_el) => JSON.stringify(el) === JSON.stringify(_el)
              );
              prices[_index] = {
                ...prices[_index],
                isHidden: !prices[_index].isHidden,
              };
            });
            grouppedBy(prices);
            setModel((prev) => ({ ...prev, prices }));
          }}
          checked={
            !model.prices.filter((el) =>
              data[keyName].find(
                (_el) => JSON.stringify(el) === JSON.stringify(_el)
              )
            )[0] ||
            !model.prices.filter((el) =>
              data[keyName].find(
                (_el) => JSON.stringify(el) === JSON.stringify(_el)
              )
            )[0].isHidden
          }
        />
      </td>
      <td>
        <h6>{keyName}</h6>
      </td>

      <td>{format_number(model.b2Bcommission + model.commission)}</td>
      {["QUAD", "TRIPLE", "DOUBLE"].map((type) => {
        const entry = data[keyName].find(
          (el) =>
            el.arrangementMadinahDesignation.toUpperCase().includes(type) &&
            el.arrangementMakkahDesignation.toUpperCase().includes(type)
        );

        return (
          <td key={type}>
            {entry ? (
              <>
                <b>
                  {format_number(
                    entry.baseCost + model.b2Bcommission + model.commission
                  )}
                </b>
                <Input
                  value={
                    model.prices.find((__el) => {
                      return (
                        __el.hotelMadinahName === entry.hotelMadinahName &&
                        __el.hotelMakkahName === entry.hotelMakkahName &&
                        __el.arrangementMadinahDesignation ===
                          entry.arrangementMadinahDesignation &&
                        __el.arrangementMakkahDesignation ===
                          entry.arrangementMakkahDesignation
                      );
                    })?.price || 0
                  }
                  onChange={(price) => {
                    let prices = [...model.prices];
                    let _index = prices.findIndex(
                      (__el) =>
                        __el.hotelMadinahName === entry.hotelMadinahName &&
                        __el.hotelMakkahName === entry.hotelMakkahName &&
                        __el.arrangementMadinahDesignation ===
                          entry.arrangementMadinahDesignation &&
                        __el.arrangementMakkahDesignation ===
                          entry.arrangementMakkahDesignation
                    );
                    prices[_index] = {
                      ...prices[_index],
                      price: parseFloat(price),
                    };

                    setModel((prev) => ({ ...prev, prices }));
                  }}
                />
              </>
            ) : (
              0
            )}
          </td>
        );
      })}
    </tr>
  );
};

export default RowOfTable;
