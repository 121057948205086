import {
  CaracteristiqueHotelModel,
  CaracteristiquesPackageUmrahModel,
  DisponibilityModel,
} from "./packageomra.model";

export default class PackageModel {
  constructor() {
    this.id = 0;
    this.name = null;
    this.prices = [];
    this.note = "";
    this.disponibilities = [new DisponibilityModel()];
    this.extrats = [new ExtraModel()];
    this.description = "";
    this.caracteristique = new CaracteristiquesPackageUmrahModel();
    this.destinations = [];
    this.images = [];
    this.imageUrls = [];
    this.programId = 0;
  }
}

export class ExtraModel {
  constructor() {
    this.id = 0;
    this.providerId = 0;
    this.service = 0;
    this.unitCost = 0;
    this.unitFee = 0;
    this.note = "";
    this.exchange = 1;
  }
}

export class PackageCost {
  constructor() {
    this.id = 0;
    this.providerId = null;
    this.cost = null;
    this.exchange = null;
    this.packegePriceId = null;
    this.currencyId = null;
    this.provider = null;
  }
}

export class DestinationVoModel {
  constructor() {
    this.id = 0;
    this.name = "";
    this.order = 0;
    this.nbrDay = 1;
    this.hotelsVo = [new HotelVoModel()];
    this.excursionDestIds = [];
  }
}

export class HotelVoModel {
  constructor(name) {
    this.id = 0;
    this.duration = 1;
    this.checkin = new Date();
    this.exchange = 0;
    this.currencyId = 0;
    this.providerId = 0;
    this.name = name || "";
    this.hotelListId = 0;
    this.destinationId = 0;
    this.packageMealTypes = [];
    this.caracteristique = new CaracteristiqueHotelModel();
  }
}
