import { useCallback, useMemo, useRef, useState } from "react";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import "react-image-crop/dist/ReactCrop.css";
import FileUploadModal from "../../../Components/ImageCropper/fileUploadModal";
import { getCroppedImg } from "../../../Helpers/setCanvasPreview";
import { ALFRISCO } from "../../../Config/api.config";
import styles from "./styles/attachmentStyles";
import FilePreviewList from "./filePreviewList";

const Attachments = ({
  model,
  setModel,
  hasVideo = false,
  isProgram = false,
  index,
  showFileInput = true,
  program,
}) => {
  const [state, setState] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [warningMessage, setWarningMessage] = useState("");
  const fileInputRef = useRef(null);

  const fixedCropSize = { width: 85, height: 80 };
  const MAX_FILE_SIZE = 1 * 1024 * 1024;
  const MAX_FILE_VIDEO_SIZE = 10 * 1024 * 1024;
  const MIN_IMAGE_COUNT = 5;

  const [crop, setCrop] = useState({
    unit: "%",
    x: 25,
    y: 25,
    width: fixedCropSize.width,
    height: fixedCropSize.height,
  });

  useMemo(() => {
    const addFilesToState = (newFiles) => {
      setState((prev) => {
        const existingFileUrls = new Set(prev.map((file) => file.url));
        const filteredFiles = newFiles.filter(
          (file) => !existingFileUrls.has(file.url)
        );
        return [...prev, ...filteredFiles];
      });
    };

    if (model.imageIds && model.imageIds.length !== 0) {
      const imageIdFiles = model.imageIds.map((id) => ({
        id,
        fileKey: null,
        blobFile: { type: "image" },
        url: `${ALFRISCO}${id}/content`,
        name: null,
        status: "finished",
      }));
      addFilesToState(imageIdFiles);
    }

    if (model.imageUrls && model.imageUrls.length !== 0) {
      const imageUrlFiles = model.imageUrls.map((id) => ({
        id,
        fileKey: null,
        blobFile: { type: "image" },
        url: `${ALFRISCO}${id}/content`,
        name: null,
        status: "finished",
      }));
      addFilesToState(imageUrlFiles);
    }

    if (isProgram && program) {
      setState([]);
    }

    if (
      isProgram &&
      model.programsLists &&
      model.programsLists[index].imageIds !== 0
    ) {
      const programImageIdFiles = model.programsLists?.[index].imageIds.map(
        (id) => ({
          id,
          fileKey: null,
          blobFile: { type: "image" },
          url: `${ALFRISCO}${id}/content`,
          name: null,
          status: "finished",
        })
      );
      addFilesToState(programImageIdFiles);
    }

    if (isProgram && program && program.imageIds !== 0) {
      const programImageIdFiles = program.imageIds.map((id) => ({
        id,
        fileKey: null,
        blobFile: { type: "image" },
        url: `${ALFRISCO}${id}/content`,
        name: null,
        status: "finished",
      }));
      addFilesToState(programImageIdFiles);
    }
    if (hasVideo && model.videoId) {
      const videoFile = {
        id: model.videoId,
        fileKey: null,
        blobFile: { type: "video" },
        url: `${ALFRISCO}${model.videoId}/content`,
        name: null,
        status: "finished",
      };
      addFilesToState([videoFile]);
    }

    if (hasVideo && model.videoIds) {
      const videoFiles = model.videoIds.map((id) => ({
        id,
        fileKey: null,
        blobFile: { type: "video" },
        url: `${ALFRISCO}${id}/content`,
        name: null,
        status: "finished",
      }));
      addFilesToState(videoFiles);
    }
  }, [model, hasVideo, program, isProgram]);

  const handleUploadInModal = async () => {
    if (selectedFile) {
      const croppedBlob = await getCroppedImg(
        URL.createObjectURL(selectedFile.blobFile),
        crop
      );

      const croppedFile = new File([croppedBlob], selectedFile.name, {
        type: selectedFile.blobFile.type,
      });

      const newFile = {
        ...selectedFile,
        id: null,
        blobFile: croppedBlob,
        status: "pending",
      };

      setState((prev) => [...prev, newFile]);

      const updatedImages = [...(model.images || []), croppedFile];

      if (isProgram) {
        const newFileDayEntry = {
          fileName: croppedFile.name,
          day: model.programsLists[index].day,
        };
        setModel((prev) => ({
          ...prev,
          images: updatedImages,
          fileDay: [...(prev.fileDay || []), newFileDayEntry],
        }));
      } else {
        setModel((prev) => ({ ...prev, images: updatedImages }));
      }

      setOpenModal(false);
      setSelectedFile(null);

      if (updatedImages.length >= MIN_IMAGE_COUNT) {
        setWarningMessage("");
      } else {
        setWarningMessage(
          `Vous devez télécharger au moins ${MIN_IMAGE_COUNT} images`
        );
      }
    }
  };

  const handleDelete = useCallback(
    (index) => {
      if (index < 0 || index >= state.length) return;

      const selectedFile = state[index];
      const updatedImages = [...(model.images || [])];
      updatedImages.splice(index, 1);

      if (selectedFile.id) {
        let updatedImageIdentifiers = model.imageUrls || model.imageIds || [];
        let updatedVideos = model.videos || [];
        let updatedVideoIds = model.videoIds || [];

        if (selectedFile.blobFile.type === "video") {
          updatedVideos = updatedVideos.filter(
            (video) => video.id !== selectedFile.id
          );
          updatedVideoIds = updatedVideoIds.filter(
            (id) => id !== selectedFile.id
          );
        } else {
          updatedImageIdentifiers = updatedImageIdentifiers.filter(
            (id) => id !== selectedFile.id
          );
        }

        setModel((prev) => ({
          ...prev,
          idsDelete: [...(prev.idsDelete || []), selectedFile.id],
          images: updatedImages,
          imageUrls: model.imageUrls ? updatedImageIdentifiers : prev.imageUrls,
          imageIds: model.imageIds ? updatedImageIdentifiers : prev.imageIds,
          videos: model.videos ? updatedVideos : prev.videos,
          videoIds: model.videoIds ? updatedVideoIds : prev.videoIds,
        }));
      } else {
        setModel((prev) => ({
          ...prev,
          images: updatedImages,
        }));
      }

      setState((prev) => prev.filter((_, i) => i !== index));

      if (updatedImages.length < MIN_IMAGE_COUNT) {
        setWarningMessage(
          `Vous devez télécharger au moins ${MIN_IMAGE_COUNT} images`
        );
      } else {
        setWarningMessage("");
      }
    },
    [model.images, state, model.videos]
  );

  const handleFileSelection = (event) => {
    const file = event.target.files[0];
    if (file.size > MAX_FILE_SIZE && file.type.startsWith("image")) {
      alert("La taille du fichier dépasse la limite de 1 Mo.");
      return;
    }

    if (file.size > MAX_FILE_VIDEO_SIZE && file.type.startsWith("video")) {
      alert("La taille du fichier dépasse la limite de 10 Mo.");
      return;
    }

    const newFile = {
      id: null,
      fileKey: Date.now(),
      blobFile: file,
      name: file.name,
      status: "pending",
    };

    if (file.type.startsWith("video")) {
      setState((prev) => [...prev, newFile]);
      const videos = [...(model.videos || []), file];
      setModel((prev) => ({
        ...prev,
        videos,
      }));
    } else if (file.type.startsWith("image")) {
      setSelectedFile(newFile);
      setOpenModal(true);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <div style={styles.container}>
        {showFileInput && (
          <div style={styles.fileUploadWrapper}>
            <input
              type="file"
              onChange={(e) => handleFileSelection(e)}
              accept={hasVideo ? "image/*,video/*" : "image/*"}
              style={{ display: "none" }}
              id={`file-input-${index}`}
              name="files"
              ref={fileInputRef}
            />
            <label
              htmlFor={`file-input-${index}`}
              style={styles.fileInputLabel}
            >
              <div style={styles.fileUploadIcon}>
                <FileUploadIcon color="#3598ff" />
              </div>
              <h4 style={styles.fileInputText}>
                Cliquez ou Faites glisser les fichiers vers cette zone
              </h4>
            </label>
          </div>
        )}

        <FilePreviewList
          state={state}
          handleDelete={handleDelete}
          warningMessage={warningMessage}
          canDelete={!!program}
        />
      </div>

      <FileUploadModal
        open={openModal}
        selectedFile={selectedFile}
        onClose={handleModalClose}
        onUploadInModal={handleUploadInModal}
        crop={crop}
        setCrop={setCrop}
      />
    </>
  );
};

export default Attachments;
