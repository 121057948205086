export const clientTypes = [
  { label: "B2B", value: 1 },
  { label: "B2C", value: 2 },
];
// _________________________________________________________________________
export const checkStatus = [
  { label: "Reçu", value: 1 },
  { label: "Remise à l'encaissement", value: 2 },
  { label: "Logé dans le compte", value: 3 },
  { label: "Preavis", value: 4 },
  { label: "Preavis annulé", value: 5 },
  { label: "CNP", value: 6 },
  { label: "Paiement (ARP)", value: 7 },
  { label: "Paiement (Espèces)", value: 8 },
  { label: "Litige", value: 9 },
];
export const ConfirmationStatus = [
  { label: "En Attente", value: 1 },
  { label: "Non Confirmé Par Client", value: 2 },
  { label: "Confirmé", value: 3 },
  { label: "Refusé", value: 4 },
];
export const ticketType = [
  { label: "Tous", value: "All" },
  { label: "TKTT", value: "TKTT" },
  { label: "EMDS", value: "EMDS" },
  { label: "RFND", value: "RFND" },
  { label: "CANX", value: "CANX" },
  { label: "CAN", value: "CAN" },
  { label: "RFNC", value: "RFNC" },
  { label: "SPDR", value: "SPDR" },
  { label: "ADMS", value: "ADMS" },
  { label: "ADMA", value: "ADMA" },
  { label: "ACMS", value: "ACMS" },
  { label: "ACMA", value: "ACMA" },
];
export const ticketType2 = [
  { label: "Tous", value: "All" },
  { label: "TKTT", value: "TKTT" },
  { label: "RFND", value: "RFND" },
  { label: "CANX", value: "CANX" },
];
export const dateTypes = [
  { label: "Aujourd'hui", value: 1 },
  { label: "Hier", value: 2 },
  // { label: "Cette semaine", value: 3 },
  // { label: "la semaine dernière", value: 4 },
  { label: "Ce mois", value: 3 },
  { label: "Le mois dernier", value: 4 },
  { label: "Cette année", value: 5 },
  { label: "Personnalisé", value: 6 },
  { label: "Jour", value: 7 },
];

export const checkPurpose = [
  { label: "Recouvrement", value: 1 },
  // { label: "Payment Client B2C", value: 2 },
  { label: "Garantie Client", value: 2 },
];
// CheckPurpose
//     {
//         RecouvAgence = 1,
//         RecouvClient,
//         Garanty,
//     }
//_________________________________________________________________________
export const transactionEvents = [
  { label: "Vente", value: 1 },
  { label: "Recouvrement", value: 2 },
  { label: "Annulation", value: 3 },
  { label: "Remboursement", value: 4 },
  { label: "Service", value: 5 },
  { label: "Garaantie", value: 6 },
];
export const paymentType = [
  { label: "Chéque", value: 1 },
  { label: "Espèces ", value: 2 },
  { label: "Solde", value: 3 },
  // { label: "CCA", value: 4 },
  // { label: "Versement", value: 5 }, // chechout
  { label: "Virement Bancaire", value: 4 },
];
// public enum PaymentType
// {
//     Check = 1,
//     Cash,
//     Solde,
//     Transfert,
//     Versement,
//     Deposit
// }
export const PaymentEventType = [
  { label: "Récompense", value: 1 },
  { label: "Sortie", value: 2 },
];
// RECOVERY_CLIENT = 1, GUARANTEE_CLIENT,
// PAYMENT_PROVIDER,
// GUARANTEE_PROVIDER,
export const SaleStatus = [
  { label: "Non Payé", value: 1 },
  { label: "Payé en Partie", value: 2 },
  { label: "En Attente", value: 3 },
  { label: "Payé", value: 4 },
  { label: "Remboursé", value: 5 },
  { label: "Annulé", value: 6 },
  { labe: "Confirmé", value: 7 },
];

// public enum SaleStatus
//         {
//         [Description("Not paid")]
//         NotPaid,
//         [Description("Partial Paid")]
//         PartialPaid,
//         [Description("Pending")]
//         Pending,
//         [Description("Paid")]
//         Paid,
//         [Description("Refund")]
//         Refund,
//         [Description("Canceled")]
//         Canceled
//     }
// ---------------------------------------------------------------------------
export const dateFilter = [
  { label: "Aujourd'hui", value: 1 },
  { label: "Hier", value: 2 },
  { label: "Cette semaine", value: 3 },
  { label: "la semaine dernière", value: 4 },
  { label: "Ce mois", value: 5 },
  { label: "Le mois dernier", value: 6 },
  { label: "Cette année", value: 7 },
  { label: "Personnalisé", value: 8 },
  { label: "Jour", value: 9 },
];

//-----------------------------------

export const serviceTypes = [
  { label: "Billetterie", value: 1 },
  { label: "Hôtel", value: 2 },
  { label: "Bateau", value: 3 },
  { label: "Autobus", value: 4 },
  { label: "Excursion", value: 5 },
  { label: "Voyage Organisé", value: 6 },
  { label: "Omrah", value: 7 },
  { label: "Autres", value: 8 },
];
// ticketing=1, hotel,ship,bus, excursion,
// trip,
// umrah, others

export const Gender = [
  { label: "Masculin", value: 1 },
  { label: "Féminin", value: 2 },
];
export const AgeType = [
  { label: "Adulte", value: 1 },
  { label: "Enfant", value: 2 },
];

export const roomTypes = [
  { label: "Chambre simple", value: "Chambre simple" },
  { label: "Chambre double", value: "Chambre double" },
  { label: "Chambre triple", value: "Chambre triple" },
  { label: "Chambre quadruple", value: "Chambre quadruple" },
  { label: "Chambre Familiale", value: "Chambre Familiale" },
  { label: "Suite", value: "Suite" },
];

export const MaritalStatus = [
  { label: "Célibataire", value: 1 },
  { label: "Marrié(e)", value: 2 },
  { label: "Divorcé(e)", value: 3 },
  { label: "Veuf / veuve", value: 4 },
];

export const Titles = [
  { value: 1, label: "السيد" },
  { value: 2, label: "السيدة" },
  { value: 3, label: "الآنسة" },
  { value: 7, label: "أخرى" },
];
export const CustomerType = [
  { value: 1, label: "Client B2C" },
  { value: 2, label: "Guide" },
  { value: 3, label: "Employé" },
];
//_________________________________

export const surveyTitles = [
  ": طريقة السّماع عن كنوز ",
  ": أوّل مرّة ",
  ": الرّضاء على  العمرة ",
  ": الرّضاء على الفرع  ",
  ": مرافق ",
  ": الطيران",
  ": فندق المدينة",
  ": خدمات المدينة",
  ": فندق مكّة",
  ": خدمات مكّة",
  ": مميّزات كنوز",
  ": اقتراح كنوز للنّاس",
  ": استعمال رمز التخفيض",
];
export const surveyStatedata = [
  { label: "أجاب", value: 1 },
  { label: " لم يجب على الهاتف", value: 2 },
  { label: "رقم غير صحيح", value: 3 },
  { label: "لم يكمل سبر الاراء", value: 4 },
  { label: "رفض الاجابة", value: 5 },
  { label: "إعادة الاتصال ", value: 6 },
];
export const goodBadResponse = [
  { label: "ممتازة", value: 1 },
  { label: "عادية", value: 2 },
  { label: "سيئة", value: 3 },
];
export const UmrahState = [
  { label: "ممتازة ", value: 1 },
  { label: "جيدة", value: 2 },
  { label: "متوسطة/ مقبولة", value: 3 },
  { label: "سيئة", value: 4 },
];
export const yesOrNoResponse = [
  { label: "نعم", value: true },
  { label: "لا", value: false },
];

export const guide = [
  {
    label: "معاملة طيبة و  قدم برشة خدمات",
    value: 1,
  },
  { label: "عمل اللي عليه ", value: 2 },
  { label: " ما قامش بدوره كمرافق", value: 3 },
];
export const branchTreatment = [
  { label: "معاملة جيدة", value: 1 },
  { label: "معاملة عادية", value: 2 },
  { label: " معاملة سيئة من الموظفين", value: 3 },
];
export const BestInKounouz = [
  { label: "الخدمات و اللهوة", value: 1 },
  { label: "السعر", value: 2 },
  { label: "توفر العمرة في كل الأوقات", value: 3 },
  { label: "كل شيئ", value: 4 },
  { label: " لا شيئ", value: 5 },
];
export const heardOf = [
  { label: " زيارة الفرع", value: 6 },
  { label: "رئيس مجموعة", value: 5 },
  { label: "صديق/قريب", value: 4 },
  { label: "انستغرام", value: 3 },
  { label: "فايسبوك", value: 2 },
  { label: "تلفزة", value: 1 },
  { label: "وسيلة أخرى", value: 7 },
];

export const generalLabels = [
  "أجاب",
  " لم يجب على الهاتف",
  "رقم غير صحيح",
  "لم يكمل سبر الاراء",
  "رفض الاجابة",
  "إعادة الاتصال ",
];

export const CaracteristiqueHotelTranslations = {
  hasWifi: "Wi-Fi",
  hasBreakfast: "Petit-déjeuner",
  hasLunch: "Déjeuner",
  hasDinner: "Dîner",
  hasPosition: "Position",
  hasBus: "Bus",
  hasTransfert: "Transfert",
  hasVol: "Vol",
};

export const CaracteristiquePackageTranslations = {
  hasHotels: "Hôtels",
  hasVisa: "Visa",
  hasMeal: "Repas",
  hasTransfert: "Transfert",
  hasVol: "Vol",
};

export const typeTravler = [
  { label: "Adulte", value: "Adult" },
  { label: "Enfant", value: "Child" },
  { label: "Bébé", value: "Baby" },
];

export const TypeTransfert = [
  { label: "Destination", value: 0 },
  { label: "Aéroport", value: 1 },
  { label: "Hôtel", value: 2 },
];

export const CaracteristiqueTransfert = {
  hasBus: "Bus",
  hasBreakfast: "Petit-déjeuner",
  hasWifi: "Wi-Fi",
  hasTransfert: "Transfert",
  hasVol: "Vol",
};

export const CaracteristiqueAirline = {
  hasBus: "Bus",
  hasBreakfast: "Petit-déjeuner",
  hasTransfert: "Transfert",
  hasVol: "Vol",
  hasWifi: "Wi-Fi",
};

export const TypePackage = [
  { label: "Umrah", value: 0 },
  { label: "Voyage Organisé", value: 1 },
];

export const classFlight = [
  { label: "Economic ", value: "Y" },
  { label: "Economic Standard", value: "M" },
  { label: "Economic Premium,", value: "W" },
  { label: "Business", value: "C" },
  { label: "First", value: "F" },
];

export const destinations = [
  { label: "Makkah", value: "makkah" },
  { label: "Madinah", value: "madinah" },
];

export const TypeExtrat = [
  { value: "Others", label: "Others" },
  { value: "Excursion", label: "Excursion" },
  { value: "ServiceEtage", label: "Service d'étage" },
  {
    value: "ÉqpmentHandicap",
    label: "Équipements pour les personnes handicapées",
  },
  { value: "NavetteAéroport", label: "Navette Aéroport" },
  { value: "ticket", label: "Ticket" },
  { value: "bus", label: "Bus" },
  { value: "ship", label: "Ship" },
  { value: "visa", label: "Visa" },
];

export const stepsTitles = [
  "Infos Générales",
  "Vols",
  "Destination et Hôtels",
  "Programme",
  "Prix",
  "Attachments",
];

export const typeMeal = [
  { label: "Petit Déjeuner", value: 0 },
  { label: "Déjeuner", value: 1 },
  { label: "Dinner", value: 2 },
  { label: "Iftar", value: 3 },
  { label: "Souhour", value: 4 },
  { label: "Others", value: 5 },
];

export const services = [
  { label: "Others", value: 0 },
  { label: "Excursion", value: 1 },
  { label: "Service d'étage", value: 2 },
  { label: "Équipements pour les personnes handicapées", value: 3 },
  { label: "Navette Aéroport", value: 4 },
  { label: "Ticket", value: 5 },
  { label: "Bus", value: 6 },
  { label: "Ship", value: 7 },
  { label: "Visa", value: 8 },
];
