import { IconButton, Input, SelectPicker } from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import Responsive from "../../../Components/Responsive";
import format_number from "../../../Helpers/number_formatter";
import { MealTypeModel } from "../../../Models/packageomra.model";
import { typeMeal } from "../../../Constants/types";

const PackageMealTypes = ({
  packageMealTypes,
  setPackageMealTypes,
  exchange,
  duration,
}) => {
  const handleMealTypeChange = (index, typeMeal) => {
    const updatedPackageMealTypes = [...packageMealTypes];
    updatedPackageMealTypes[index] = {
      ...updatedPackageMealTypes[index],
      typeMeal,
    };
    setPackageMealTypes(updatedPackageMealTypes);
  };

  const handleCostChange = (index, cost) => {
    const updatedPackageMealTypes = [...packageMealTypes];
    const updatedItem = {
      ...updatedPackageMealTypes[index],
      cost: parseFloat(cost),
    };
    updatedPackageMealTypes[index] = updatedItem;
    setPackageMealTypes(updatedPackageMealTypes);
  };

  const handleTotalPriceChange = (index, totalPrice) => {
    const updatedPackageMealTypes = [...packageMealTypes];
    updatedPackageMealTypes[index] = {
      ...updatedPackageMealTypes[index],
      totalPrice: parseFloat(totalPrice),
    };
    setPackageMealTypes(updatedPackageMealTypes);
  };

  const addNewMealType = () => {
    setPackageMealTypes([...packageMealTypes, new MealTypeModel()]);
  };

  const removeMealType = (index) => {
    const updatedPackageMealTypes = packageMealTypes.filter(
      (_, i) => i !== index
    );
    setPackageMealTypes(updatedPackageMealTypes);
  };

  const getAvailableMealTypes = (index) => {
    const usedMealTypes = packageMealTypes
      .filter((_, i) => i !== index)
      .map((meal) => meal.typeMeal);

    return typeMeal.filter((option) => !usedMealTypes.includes(option.value));
  };

  return (
    <>
      <h6>
        Repas à l'hôtel :{" "}
        {packageMealTypes.length === 0 && (
          <IconButton
            onClick={addNewMealType}
            color="violet"
            icon={<PlusRoundIcon />}
          />
        )}
      </h6>
      {packageMealTypes.map((meal, i) => (
        <div key={i}>
          <Responsive m={4} l={3} xl={3} className="p-10">
            <label>Repas:</label>
            <SelectPicker
              data={getAvailableMealTypes(i)}
              block
              value={meal.typeMeal}
              onSelect={(typeMeal) => handleMealTypeChange(i, typeMeal)}
            />
          </Responsive>
          <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
            <label>Coût :</label>
            <Input
              type="number"
              step="0.1"
              value={meal.cost}
              onChange={(cost) => handleCostChange(i, cost)}
            />
          </Responsive>

          <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
            <label>Prix TND:</label>
            <h6>{format_number(meal.cost * duration * exchange || 0)}</h6>
          </Responsive>

          <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
            <label>Prix Total :</label>
            <Input
              type="number"
              step="0.1"
              value={meal.totalPrice}
              onChange={(totalPrice) => handleTotalPriceChange(i, totalPrice)}
            />
          </Responsive>

          <Responsive s={2} m={1.6} l={1} xl={1}>
            <div style={{ height: "42px" }}></div>
            <IconButton
              onClick={() => removeMealType(i)}
              color="violet"
              icon={<TrashIcon />}
            />
            {i + 1 === packageMealTypes.length && (
              <IconButton
                onClick={addNewMealType}
                color="violet"
                icon={<PlusRoundIcon />}
              />
            )}
          </Responsive>
        </div>
      ))}
    </>
  );
};

export default PackageMealTypes;
