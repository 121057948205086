import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Input } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api/";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import moment from "moment";
import AddProgram from "./addProgram";
import { ProgramAtom } from "../../Atoms/progam.atom";
import ProgramModel from "../../Models/program.model";
import appendModelToFormData from "../../Helpers/appendToFormData";

const Program = (props) => {
  const [data, setdata] = useState([]);
  const [totalCount, settotalCount] = useState(0);

  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 20,
    q: "",
    date: null,
  });

  const [error, setError] = useState("");
  const [model, setmodel] = useRecoilState(ProgramAtom);
  const [state, setstate] = useRecoilState(exportAddAtom);
  const [isLoading, setIsLoading] = useState(false);

  const reset = () => {
    setmodel(new ProgramModel());
    setError("");
  };

  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    APi.createAPIEndpoint(APi.ENDPOINTS.Programs + "/getAll", {
      ...filterModel,
      date: filterModel.date
        ? moment(filterModel.date).format("DD MMM YYYY")
        : null,
    })
      .customGet()
      .then((res) => {
        setdata(res.data.programs);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
      })
      .catch((e) => {
        setError(e.Message);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      });
  };

  const save = () => {
    setstate((prev) => ({ ...prev, loading: true }));
    let m = model;
    const formData = new FormData();

    appendModelToFormData(formData, m);

    const apiEndpoint = APi.createAPIEndpoint(APi.ENDPOINTS.Programs);
    const apiCall = m.id
      ? apiEndpoint.update(m.id, formData)
      : apiEndpoint.create(formData);

    apiCall
      .then(() => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: m.id
            ? "Élément a été bien modifié !"
            : "Élément a été bien ajouté !",
          showConfirmButton: false,
          timer: 1500,
        });

        reset();
        setstate((prev) => ({ ...prev, open: false, loading: false }));
        apiEndpoint
          .getForPrices({ q: "", notFromCache: true })
          .then(() => fetch());
      })
      .catch((e) => {
        setError(e.Message);
        setstate((prev) => ({ ...prev, loading: false }));
      });
  };

  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Programs)
      .delete(id)
      .then((res) => {
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };

  const getBYId = (id) => {
    setError("");
    setIsLoading(true);
    APi.createAPIEndpoint(APi.ENDPOINTS.Programs)
      .fetchById(id)
      .then((res) => {
        let m = { ...res.data };
        setmodel(m);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);

  return (
    <div>
      <Filter search={() => fetch()}>
        <Responsive m={6} l={6} xl={6} className="p-10">
          <label>Désignation: </label>
          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </Responsive>

        <Responsive m={6} l={6} xl={6} className="p-10">
          <label>Date : </label>
          <Input
            type="date"
            value={filterModel.date}
            onChange={(date) => {
              setfilterModel((prev) => {
                return { ...prev, date };
              });
            }}
          />
        </Responsive>
      </Filter>
      <ExportAdd
        ActionOnClose={reset}
        noExport
        size="xl"
        save={save}
        title="Ajouter programme"
        AddComponent={<AddProgram isLoading={isLoading} error={error} />}
      />
      <Grid
        editAction={(id) => {
          getBYId(id);
          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
      </div>
    </div>
  );
};

const columns = [
  {
    value: "name",
    name: "Nom",
    render: (v) => <a>{v}</a>,
  },
  {
    value: "isUmrah",
    name: "Type",
    render: (v) => <a>{v === 1 ? "Voyage Organisé" : "Umrah"}</a>,
  },
];

export default Program;
