const styles = {
  container: {
    padding: "10px",
  },
  fileUploadWrapper: {
    background: "rgba(200,200,200,0.1)",
  },
  fileInputLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  fileUploadIcon: {
    fontSize: "50px",
    cursor: "pointer",
  },
  fileInputText: {
    color: "#bbb",
    fontWeight: "400",
  },
  warningMessage: {
    color: "red",
    marginTop: "10px",
  },
  fileContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
  },
  filePreviewContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  filePreview: {
    width: "150px",
    height: "120px",
    position: "relative",
    border: "1px solid #ddd",
    borderRadius: "5px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteIcon: {
    cursor: "pointer",
  },
  file: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
};

export default styles;
