import {
  DestinationUmrahModel,
  HotelUmrahModel,
} from "../../../Models/packageomra.model";
import { useState } from "react";
import DestinationsTable from "./destinationsTable";
import Hotels from "./hotel/hotels";
import {
  DestinationVoModel,
  HotelVoModel,
} from "../../../Models/Package.model";
import PackageVoAddButton from "../../Packages/buttonAdd/packageAddButton";
import DestinationForm from "./destinationForm";
import PackageUmrahAddButton from "./packageUmrahAddButton";

const Destination = ({
  providers,
  currencies,
  exchanges,
  fetchProviders,
  setModel,
  model,
  title,
  fetchPays,
  fetchHotels,
  hotelData,
  excursionData,
  isUmrah,
  paysData,
  city,
  setCity,
}) => {
  const [destinationModel, setDestinationModel] = useState(
    isUmrah ? new DestinationUmrahModel() : new DestinationVoModel()
  );

  const addHotel = (name) => {
    setDestinationModel((prev) => ({
      ...prev,
      [isUmrah ? "hotelsUmrah" : "hotelsVo"]: [
        ...(isUmrah ? prev.hotelsUmrah : prev.hotelsVo),
        isUmrah ? new HotelUmrahModel(name) : new HotelVoModel(name),
      ],
    }));
  };

  const updateHotel = (index, updatedHotel) => {
    setDestinationModel((prev) => ({
      ...prev,
      [isUmrah ? "hotelsUmrah" : "hotelsVo"]: (isUmrah
        ? prev.hotelsUmrah
        : prev.hotelsVo
      ).map((hotel, i) =>
        i === index ? { ...hotel, ...updatedHotel } : hotel
      ),
    }));
  };

  const removeHotel = (index) => {
    setDestinationModel((prev) => ({
      ...prev,
      [isUmrah ? "hotelsUmrah" : "hotelsVo"]: (isUmrah
        ? prev.hotelsUmrah
        : prev.hotelsVo
      ).filter((_, i) => i !== index),
    }));
  };

  return (
    <div>
      <h3>{title}</h3>
      <div style={{ background: "rgb(70,90,238,0.2)" }} className="p-10">
        <DestinationForm
          destinationModel={destinationModel}
          setDestinationModel={setDestinationModel}
          fetchPays={fetchPays}
          excursionData={excursionData}
          isUmrah={isUmrah}
          paysData={paysData}
          city={city}
          setCity={setCity}
        />

        <Hotels
          hotels={
            isUmrah ? destinationModel.hotelsUmrah : destinationModel.hotelsVo
          }
          updateHotel={updateHotel}
          addHotel={addHotel}
          removeHotel={removeHotel}
          hotelData={hotelData}
          setDestinationModel={setDestinationModel}
          fetchHotels={fetchHotels}
          providers={providers}
          currencies={currencies}
          exchanges={exchanges}
          fetchProviders={fetchProviders}
          destinationModel={destinationModel}
          isUmrah={isUmrah}
        />

        <hr />

        {isUmrah ? (
          <PackageUmrahAddButton
            destinationModel={destinationModel}
            setModel={setModel}
            setDestinationModel={setDestinationModel}
          />
        ) : (
          <PackageVoAddButton
            destinationModel={destinationModel}
            setModel={setModel}
            setDestinationModel={setDestinationModel}
          />
        )}

        <DestinationsTable
          destinations={model.destinations}
          setDestinationModel={setDestinationModel}
          updateModel={setModel}
        />
      </div>
    </div>
  );
};

export default Destination;
