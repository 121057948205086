const styles = {
  mainContainer: {
    gap: 8,
    display: "flex",
    flexDirection: "column",
  },
  hotel: {
    border: "1px solid",
    width: "95%",
    gap: 4,
    display: "flex",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    gap: 5,
    alignItems: "center",
  },
  iconButton: {
    height: "42px",
  },
};

export default styles;
