import { useCallback, useEffect, useState } from "react";
import DepartureFlightForm from "./departureFlightForm";
import { APi } from "../../../../Api";

const DepartureFlightList = ({ index, flight, type, setList }) => {
  const [airportData, setAirportData] = useState({
    departure: [],
    arrival: [],
  });
  const [city, setCity] = useState({ departure: "", arrival: "" });
  const [paysData, setPaysData] = useState([]);

  const handleChange = (flightIndex, field, value) => {
    setList((prevFlights) => {
      return prevFlights.map((flightItem, idx) => {
        if (idx === flightIndex) {
          return { ...flightItem, [field]: value };
        }
        return flightItem;
      });
    });
  };

  const fetchAirports = useCallback(
    (cityKey) => {
      const cityName = city[cityKey];
      if (!cityName) return;

      APi.createAPIEndpoint(APi.ENDPOINTS.Airport, { city: cityName })
        .customGet()
        .then((res) => {
          setAirportData((prev) => ({
            ...prev,
            [cityKey]: res.data.data || [],
          }));
        });
    },
    [city]
  );

  const fetchPays = useCallback(() => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Pays, {})
      .customGet()
      .then((res) => setPaysData(res.data));
  }, []);

  useEffect(() => {
    fetchAirports("departure");
    fetchAirports("arrival");
  }, [fetchAirports]);

  useEffect(() => {
    fetchPays();
  }, [fetchPays]);

  return (
    <div
      style={{
        background: "rgb(70, 90, 238, 0.2)",
        padding: "10px",
      }}
      className="p-5"
    >
      <DepartureFlightForm
        flight={flight}
        handleChange={handleChange}
        type={type}
        index={index}
        departureAirportData={airportData.departure}
        arrivalAirportData={airportData.arrival}
        paysData={paysData}
        setArrivalCity={(value) =>
          setCity((prev) => ({ ...prev, arrival: value }))
        }
        setDepartureCity={(value) =>
          setCity((prev) => ({ ...prev, departure: value }))
        }
        arrivalCity={city.arrival}
        departureCity={city.departure}
      />
    </div>
  );
};

export default DepartureFlightList;
