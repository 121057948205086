import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Input } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api/";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import { multiDataSet } from "./excel_data";
import moment from "moment";
import { agencyAtom } from "../../Atoms/agency.atom";
import PackageModel from "../../Models/Package.model";
import { PackageAtom } from "../../Atoms/Package.atom";
import AddEditOrganizedTravel from "./AddEditOrganizedTravel.component";
import appendModelToFormData from "../../Helpers/appendToFormData";
import { Notification, toaster } from "rsuite";
import Translation from "../../translation/translation";

export default function Packages(props) {
  const [data, setdata] = useState([]);
  const [providers, setproviders] = useState([]);
  const agency = useRecoilValue(agencyAtom);
  const [totalCount, settotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 20,

    date: null,
  });
  const [error, setError] = useState("");
  const [model, setmodel] = useRecoilState(PackageAtom);
  const [state, setstate] = useRecoilState(exportAddAtom);

  const reset = () => {
    setmodel(new PackageModel());
    setError("");
  };

  const fetchProviders = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Provider, { q }, "/autocomplete2")
        .customGet()
        .then((res) => setproviders(res.data));
    }
  };

  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    APi.createAPIEndpoint(APi.ENDPOINTS.Package, {
      ...filterModel,
      date: filterModel.date
        ? moment(filterModel.date).format("DD MMM YYYY")
        : null,
    })
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
      })
      .catch((e) => {
        setError(e.Message);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      });
  };

  const save = () => {
    setstate((prev) => ({ ...prev, loading: true }));
    let m = model;
    const formData = new FormData();

    appendModelToFormData(formData, m);

    const apiEndpoint = APi.createAPIEndpoint(APi.ENDPOINTS.Package);
    const apiCall = m.id
      ? apiEndpoint.update(m.id, formData)
      : apiEndpoint.create(formData);

    apiCall
      .then(() => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: m.id
            ? "Élément a été bien modifié !"
            : "Élément a été bien ajouté !",
          showConfirmButton: false,
          timer: 1500,
        });

        reset();
        setstate((prev) => ({ ...prev, open: false, loading: false }));
      })
      .catch((error) => {
        toaster.push(
          <Notification type="error" header={Translation.errorHeader} closable>
            {error?.response?.data?.title || Translation.error}
          </Notification>,
          { placement: "topEnd" }
        );

        setstate((prev) => ({ ...prev, loading: false }));
      });
  };

  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Package)
      .delete(id)
      .then(() => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };

  const getBYId = (id) => {
    setError("");
    setIsLoading(true);
    APi.createAPIEndpoint(APi.ENDPOINTS.Package)
      .fetchById(id)
      .then((res) => {
        let m = { ...res.data };
        if (m.disponibilities)
          m.disponibilities = m.disponibilities.map((item) => {
            return { ...item, date: item.date.substring(0, 10) };
          });
        setmodel(m);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);

  return (
    <div>
      <Filter search={() => fetch()}>
        <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Date : </label>
          <Input
            type="date"
            value={filterModel.date}
            onChange={(date) => {
              setfilterModel((prev) => {
                return { ...prev, date };
              });
            }}
          />
        </Responsive>
      </Filter>
      <ExportAdd
        ActionOnClose={reset}
        excelData={multiDataSet(data)}
        nameExcel="packages"
        size="xl"
        save={save}
        title="Ajouter un voyage organisé"
        AddComponent={
          <AddEditOrganizedTravel
            providers={providers}
            fetchProviders={fetchProviders}
            error={error}
            isLoading={isLoading}
          />
        }
      />{" "}
      <Grid
        editAction={(id) => {
          getBYId(id);
          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
      </div>
    </div>
  );
}

const columns = [
  {
    value: "id",
    name: "Identifiant",
    render: (v) => <h5>{v}</h5>,
  },
  {
    value: "name",
    name: "Nom",
    render: (v) => <a>{v ? v.toUpperCase() : ""}</a>,
  },

  {
    value: "description",
    name: "Notes",
    render: (v) => {
      return (
        <div
          style={{
            maxWidth: "260px",
            padding: "10px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {v}
        </div>
      );
    },
  },
];
