import ImageIcon from "@rsuite/icons/Image";
import TrashIcon from "@rsuite/icons/Trash";
import React, { useState } from "react";
import { Button, IconButton, SelectPicker, Tag } from "rsuite";
import Responsive from "../../Components/Responsive";

import PlusRoundIcon from "@rsuite/icons/PlusRound";
import moment from "moment";
import { useEffect } from "react";
import { MdOutlineBedroomChild } from "react-icons/md";
import { APi } from "../../Api";
import { BASE_URL } from "../../Config/api.config";
import { createAPIEndpoint } from "../../Api/authenticated.requests";
import { ENDPOINTS } from "../../Api/enpoints";
import { FaChevronCircleDown } from "react-icons/fa";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
export default function Rooming() {
  const [vols, setvols] = useState([]);

  const [rooms, setrooms] = useState([]);
  const [filterModel, setfilterModel] = useState({
    disponibilityId: 0,
    page: 1,
    take: 1000,
  });
  const [localFilter, setlocalFilter] = useState(0);
  const [openedRooms, setopenedRooms] = useState([]);

  const [pilgrims, setpilgrims] = useState([]);
  const [_id, setid] = useState(0);
  const [draggedPilgrim, setdraggedPilgrim] = useState(null);
  function allowDrop(ev) {
    ev.preventDefault();
  }
  //
  function drag(ev, id) {
    ev.dataTransfer.setData("pilgrim", id);
    setdraggedPilgrim(pilgrims.find((el) => el.id == id));
  }
  //querySelector("[data-user='poppy']")
  function drop(ev, _id, isRoot = false) {
    var id = ev.dataTransfer.getData("pilgrim");
    let sel = "[data-id='" + id + "']";
    let _pilgrims = [...pilgrims];
    let i = _pilgrims.findIndex((el) => el.id == draggedPilgrim.id);
    let pp = { ..._pilgrims[i] };
    let att = ev.target.getAttribute("data-id");
    let type = ev.target.getAttribute("data-type");
    if (
      att &&
      _pilgrims.find((el) => el.id == att) &&
      !_pilgrims.find((el) => el.id == att).roomId
    ) {
      let ____root = document.querySelector("#__root");
      ____root.appendChild(document.querySelector(sel));
      pp.roomId = 0;
      _pilgrims[i] = pp;
      setpilgrims(_pilgrims);
    } else if (att) {
      return false;
    } else if (ev.target.id == "__root" && !att) {
      ev.target.appendChild(document.querySelector(sel));
      pp.roomId = 0;
      _pilgrims[i] = pp;
      setpilgrims(_pilgrims);
    } else if (
      type &&
      draggedPilgrim.price &&
      type == draggedPilgrim.price.arrangementMakkahDesignation &&
      pilgrims.filter((el) => el.roomId == _id).length <
        rooms.find((el) => el.id == _id).nbr
    ) {
      console.log(rooms.find((el) => el.id == _id).nbr);
      console.log(pilgrims.filter((el) => el.roomId == _id).length);
      ev.target.appendChild(document.querySelector(sel));
      pp.roomId = _id;
      _pilgrims[i] = pp;
      setpilgrims(_pilgrims);
    } else return false;
  }
  const fetch = async () => {
    // let ____root = document.querySelector("#__root");
    // ____root.innerHTML = "";
    let __rooms = [];
    let res = await createAPIEndpoint(ENDPOINTS.Rooming + "/GetRooming", {
      disponibilityId: filterModel.disponibilityId,
    }).fetch();
    setid(res.data.id);
    setrooms(res.data.rooms || []);
    __rooms = res.data.rooms ? [...res.data.rooms] : [];
    console.log(res.data);

    let res2 = await APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Umrah", {
      ...filterModel,
    }).fetchAll();

    setpilgrims(
      res2.data.data.map((el) => {
        delete el.PackageUmrah;
        let _r = __rooms.find((r) => {
          console.log(r.orderUmrahIds.split(",").includes(el.id + ""));
          return (
            r.orderUmrahIds && r.orderUmrahIds.split(",").includes(el.id + "")
          );
        });
        let _el = { ...el };
        if (_r) {
          _el.roomId = _r.id;
          console.log(_el);
        }

        return _el;
      })
    );
    // if (res.data.data.length == 0) {
    //   let ____root = document.querySelector("#__root");/api/Rooming/GetRooming
    //   ____root.innerHTML = "";
    // }
    // setrooms([]);
  };

  //_________________
  const fetchPackages = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah + "/getForPrices", { q })
        .customGet()
        .then((res) => {
          let _vols = res.data.data.reduce(
            (a, b) => a.concat(b.disponibilities),
            []
          );
          setvols(_vols.filter((d) => d.onStock));
        });
    }
  };
  useEffect(() => {
    fetchPackages();
  }, []);
  useEffect(() => {
    // let elements = document.querySelectorAll(".____node");
    // // console.log(elements);

    // if (elements)
    //   try {
    //     elements.forEach((element) => console.log(element.parentNode));
    //   } catch (e) {
    //     console.log(e);
    //   }
    let ____root = document.querySelector("#__root");

    pilgrims
      .filter((el) => el.roomId)
      .map((el) => {
        let sel = "[data-id='" + el.id + "']";
        ____root.appendChild(document.querySelector(sel));
      });
    if (filterModel.disponibilityId) fetch();
  }, [filterModel.disponibilityId]);

  useEffect(() => {
    setTimeout(() => {
      console.log("tetst");
      pilgrims
        .filter((el) => el.roomId)
        .map((el) => {
          console.log(el.roomId);
          let sel_room = "[data-roomId='" + el.roomId + "']";
          let ___room = document.querySelector(sel_room);
          let sel = "[data-id='" + el.id + "']";
          ___room.appendChild(document.querySelector(sel));
        });
    }, 500);
  }, [pilgrims.length]);
  const save = () => {
    let m = { disponibilityId: filterModel.disponibilityId };
    m.rooms = rooms.map((r) => {
      let _r = {
        ...r,
        orderUmrahIds: pilgrims.filter((p) => p.roomId == r.id)
          ? pilgrims
              .filter((p) => p.roomId == r.id)
              .map((a) => a.id)
              .join(",")
          : "",
      };
      delete _r.id;
      return _r; // 95132499
    });
    console.log(m);
    if (_id) {
      createAPIEndpoint(ENDPOINTS.Rooming)
        .update2({ id: _id, ...m })
        .then((res) => console.log(res.data));
    } else {
      createAPIEndpoint(ENDPOINTS.Rooming)
        .create(m)
        .then((res) => console.log(res.data));
    }
  };
  return (
    <div>
      {/* <h6>En cours de developpement</h6> */}
      <Responsive m={4} l={4} xl={4}>
        <label>Vol: </label>
        <SelectPicker
          data={[{ label: "Tout", value: 0 }].concat(
            vols
              ? vols.map((el) => {
                  return {
                    label: (
                      <div style={{ color: "#232323" }}>
                        {moment(el.date).format("DD MMM YYYY")}
                      </div>
                    ),
                    value: el.id,
                  };
                })
              : []
          )}
          block
          noSearch
          value={filterModel.disponibilityId}
          onSelect={(disponibilityId) => {
            setfilterModel((prev) => {
              return { ...prev, disponibilityId };
            });
          }}
        />
        <div
          style={{
            borderBottom: "1px solid #eee",
            textTransform: "uppercase",
            fontWeight: "lighter",
            color: "#232323",
          }}
          className="p-5"
        >
          Liste Des Pélérins :
        </div>
        <div style={{ width: "100%", overflowY: "auto" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              onClick={() => {
                setlocalFilter(0);
              }}
              style={{
                background: localFilter ? "#fff" : "#232323",
                color: !localFilter ? "#fff" : "#232323",
                border: "1px solid #232323",
                padding: "4px 15px",
                borderRadius: "60px",
                margin: "5px",
              }}
            >
              Tout
            </button>
            <button
              onClick={() => {
                setlocalFilter(2);
              }}
              style={{
                background: localFilter != 2 ? "#fff" : "#232323",
                color: localFilter == 2 ? "#fff" : "#232323",
                border: "1px solid #232323",
                padding: "4px 15px",
                borderRadius: "60px",
                margin: "5px",
              }}
            >
              Double
            </button>
            <button
              onClick={() => {
                setlocalFilter(3);
              }}
              style={{
                background: localFilter != 3 ? "#fff" : "#232323",
                color: localFilter == 3 ? "#fff" : "#232323",
                border: "1px solid #232323",
                padding: "4px 15px",
                borderRadius: "60px",
                margin: "5px",
              }}
            >
              Triple
            </button>
            <button
              onClick={() => {
                setlocalFilter(4);
              }}
              style={{
                background: localFilter != 4 ? "#fff" : "#232323",
                color: localFilter == 4 ? "#fff" : "#232323",
                border: "1px solid #232323",
                padding: "4px 15px",
                borderRadius: "60px",
                margin: "5px",
              }}
            >
              Quad
            </button>
          </div>
        </div>
        <div
          id="__root"
          onDrop={(e) => drop(e, null, true)}
          onDragOver={allowDrop}
          style={{
            height: "calc(100vh - 100px)",
            overflowY: "auto",
            background: "#fff",
            borderRadius: "5px",
          }}
        >
          {pilgrims.map((p, k) => (
            <div
              key={p.id}
              className="____node"
              draggable
              style={{
                background:
                  p.customer.gender == 1
                    ? "rgb(0,169,141,0.1)"
                    : "rgb(229,57,53,0.1)",
                borderRadius: "5px",
                margin:
                  p.roomId ||
                  !localFilter ||
                  (p.price &&
                    ((localFilter == 4 &&
                      p.price.arrangementMakkahDesignation.includes("quad")) ||
                      (localFilter == 3 &&
                        p.price.arrangementMakkahDesignation.includes(
                          "triple"
                        )) ||
                      (localFilter == 2 &&
                        p.price.arrangementMakkahDesignation.includes(
                          "double"
                        ))))
                    ? "5px"
                    : "0",
                display: "flex",
                alignItems: "center",
                // ,
                height:
                  p.roomId ||
                  !localFilter ||
                  (p.price &&
                    ((localFilter == 4 &&
                      p.price.arrangementMakkahDesignation.includes("quad")) ||
                      (localFilter == 3 &&
                        p.price.arrangementMakkahDesignation.includes(
                          "triple"
                        )) ||
                      (localFilter == 2 &&
                        p.price.arrangementMakkahDesignation.includes(
                          "double"
                        ))))
                    ? "unset"
                    : "0",
                overflow: "hidden",
              }}
              data-id={p.id}
              onDragStart={(e) => drag(e, p.id)}
              onTouchStart={(e) => drag(e, p.id)}
            >
              {p.customer.picture ? (
                <img
                  alt=""
                  src={
                    BASE_URL + "Uploads/CustomerPictures/" + p.customer.picture
                  }
                  width="43"
                  height="43"
                  style={{
                    border: "1px solid #eee",
                    margin: "3px",
                    borderColor:
                      p.customer.gender == 1
                        ? "rgb(0,169,141,01)"
                        : "rgb(229,57,53,01)",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <div
                  className="non_drop"
                  style={{
                    fontSize: "15px",
                    border: "1px dashed #888",
                    borderRadius: "50%",
                    width: "43px",
                    height: "43px",
                    textAlign: "center",
                    lineHeight: "42px",
                  }}
                >
                  <ImageIcon color="#3598ff"></ImageIcon>
                </div>
              )}
              <div
                className="non_drop"
                style={{
                  paddingLeft: "5px",
                  fontSize: "12px",
                }}
              >
                {p ? p.customer.name.toUpperCase() : ""}
                <br></br>
                <Tag size="xs" color={p.customer.gender == 1 ? "green" : "red"}>
                  <b style={{ fontSize: "11px", padding: "0 6px" }}>
                    {p ? p.customer.passportNumber : ""}
                  </b>
                </Tag>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "13px",
                  }}
                >
                  <MdOutlineBedroomChild
                    color="rgb(191,155,48)"
                    fontWeight="lighter"
                    size="20px"
                  />{" "}
                  <p>
                    <b>
                      {p.price &&
                        p.price.hotelMakkahName +
                          " - " +
                          p.price.hotelMadinahName}
                    </b>{" "}
                    (
                    {p.price &&
                      (p.price.arrangementMakkahDesignation ==
                      "Chambre quadruple"
                        ? "Quad"
                        : p.price.arrangementMakkahDesignation ==
                          "Chambre triple"
                        ? "Triple"
                        : "Double")}
                    )
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Responsive>

      <Responsive m={8} l={8} xl={8} className="p-5">
        <div style={{ textAlign: "right", padding: "10px" }}>
          <Button appearance="primary" color="blue" onClick={save}>
            Enregistrer
          </Button>
        </div>
        <div
          style={{
            background: "#fff",
            borderRadius: "10px",
            border: "1px solid #ddd",
            minHeight: "500px",
            maxHeight: "90vh",
            overflowY: "auto",
          }}
          className="p-5"
        >
          <Responsive m={4} l={4} xl={4} className="p-5">
            {rooms
              .filter((r) => r.nbr == 4)
              .map((r) => (
                <div
                  style={{
                    background: "#fff",
                    minHeight: openedRooms.find((el) => el == r.id)
                      ? "240px"
                      : "60px",
                    maxHeight: openedRooms.find((el) => el == r.id)
                      ? ""
                      : "70px",
                    overflow: "hidden",

                    verticalAlign: "top",
                    borderRadius: "5px",
                    margin: "5px 0",
                    position: "relative",
                    border: "1px dashed rgb(98,118,171)",
                    paddingBottom:
                      pilgrims.filter((el) => el.roomId == r.id).length <
                        r.nbr && openedRooms.find((el) => el == r.id)
                        ? "50px"
                        : "5px",
                  }}
                  onDrop={(e) => drop(e, r.id)}
                  onDragOver={allowDrop}
                  data-type="Chambre quadruple"
                  data-roomId={r.id}
                >
                  <div
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  >
                    <IconButton
                      appearance="primary"
                      color="red"
                      onClick={() => {
                        let ____root = document.querySelector("#__root");

                        let pilgrims2 = [...pilgrims];
                        pilgrims2.map((el) => {
                          if (el.roomId == r.id) {
                            let _el = { ...el };
                            el.roomId = 0;
                            let sel = "[data-id='" + el.id + "']";

                            ____root.appendChild(document.querySelector(sel));
                            return _el;
                          } else {
                            return el;
                          }
                        });
                        setpilgrims(pilgrims2);
                        setrooms((prev) => prev.filter((rr) => rr.id != r.id));
                      }}
                      icon={<TrashIcon />}
                      circle
                    />
                    <button
                      className="button_icon"
                      onClick={() => {
                        let opened = [...openedRooms];
                        if (opened.find((el) => el == r.id)) {
                          setopenedRooms(opened.filter((el) => el != r.id));
                        } else {
                          setopenedRooms((prev) => [...prev, r.id]);
                        }
                      }}
                    >
                      {openedRooms.find((el) => el == r.id) ? (
                        <BiChevronUp />
                      ) : (
                        <BiChevronDown />
                      )}
                    </button>
                  </div>
                </div>
              ))}
            <button
              onClick={() => {
                let __id = Date.now();
                setopenedRooms((prev) => [...prev, __id]);
                setrooms((prev) =>
                  prev.concat([{ nbr: 4, id: __id, draft: true }])
                );
              }}
              className="adda_room "
              style={{
                backgroundColor: "rgb(98,118,171)",
                borderRadius: "5px",
              }}
            >
              <PlusRoundIcon />
              Quad
            </button>
          </Responsive>
          <Responsive m={4} l={4} xl={4} className="p-5">
            {rooms
              .filter((r) => r.nbr == 3)
              .map((r) => (
                <div
                  style={{
                    background: "#fff",
                    minHeight: openedRooms.find((el) => el == r.id)
                      ? "180px"
                      : "60px",
                    maxHeight: openedRooms.find((el) => el == r.id)
                      ? ""
                      : "70px",
                    overflow: "hidden",

                    verticalAlign: "top",
                    borderRadius: "5px",
                    margin: "5px 0",
                    position: "relative",
                    border: "1px dashed rgb(98,118,171)",
                    paddingBottom:
                      pilgrims.filter((el) => el.roomId == r.id).length <
                        r.nbr && openedRooms.find((el) => el == r.id)
                        ? "50px"
                        : "5px",
                  }}
                  data-type="Chambre triple"
                  data-roomId={r.id}
                  onDrop={(e) => drop(e, r.id)}
                  onDragOver={allowDrop}
                >
                  {" "}
                  <div
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  >
                    <IconButton
                      appearance="primary"
                      color="red"
                      onClick={() => {
                        let ____root = document.querySelector("#__root");

                        let pilgrims2 = [...pilgrims];
                        pilgrims2.map((el) => {
                          if (el.roomId == r.id) {
                            let _el = { ...el };
                            el.roomId = 0;
                            let sel = "[data-id='" + el.id + "']";

                            ____root.appendChild(document.querySelector(sel));
                            return _el;
                          } else {
                            return el;
                          }
                        });
                        setpilgrims(pilgrims2);
                        setrooms((prev) => prev.filter((rr) => rr.id != r.id));
                      }}
                      icon={<TrashIcon />}
                      circle
                    />
                    <button
                      className="button_icon"
                      onClick={() => {
                        let opened = [...openedRooms];
                        if (opened.find((el) => el == r.id)) {
                          setopenedRooms(opened.filter((el) => el != r.id));
                        } else {
                          setopenedRooms((prev) => [...prev, r.id]);
                        }
                      }}
                    >
                      {openedRooms.find((el) => el == r.id) ? (
                        <BiChevronUp />
                      ) : (
                        <BiChevronDown />
                      )}
                    </button>
                  </div>
                </div>
              ))}
            <button
              onClick={() => {
                let __id = Date.now();
                setopenedRooms((prev) => [...prev, __id]);
                setrooms((prev) =>
                  prev.concat([{ nbr: 3, id: __id, draft: true }])
                );
              }}
              className="adda_room "
              style={{
                backgroundColor: "rgb(252,179,34)",
                borderRadius: "5px",
              }}
            >
              <PlusRoundIcon />
              Triple
            </button>
          </Responsive>
          <Responsive m={4} l={4} xl={4} className="p-5">
            {rooms
              .filter((r) => r.nbr == 2)
              .map((r) => (
                <div
                  style={{
                    background: "#fff",
                    minHeight: openedRooms.find((el) => el == r.id)
                      ? "120px"
                      : "60px",
                    maxHeight: openedRooms.find((el) => el == r.id)
                      ? ""
                      : "70px",
                    overflow: "hidden",

                    verticalAlign: "top",
                    borderRadius: "5px",
                    margin: "5px 0",
                    position: "relative",
                    border: "1px dashed rgb(98,118,171)",
                    paddingBottom:
                      pilgrims.filter((el) => el.roomId == r.id).length <
                        r.nbr && openedRooms.find((el) => el == r.id)
                        ? "50px"
                        : "5px",
                  }}
                  onDrop={(e) => drop(e, r.id)}
                  data-roomId={r.id}
                  data-type="Chambre double"
                  onDragOver={allowDrop}
                >
                  {" "}
                  <div
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  >
                    <IconButton
                      appearance="primary"
                      color="red"
                      onClick={() => {
                        let ____root = document.querySelector("#__root");

                        let pilgrims2 = [...pilgrims];
                        pilgrims2.map((el) => {
                          if (el.roomId == r.id) {
                            let _el = { ...el };
                            el.roomId = 0;
                            let sel = "[data-id='" + el.id + "']";

                            ____root.appendChild(document.querySelector(sel));
                            return _el;
                          } else {
                            return el;
                          }
                        });
                        setpilgrims(pilgrims2);
                        setrooms((prev) => prev.filter((rr) => rr.id != r.id));
                      }}
                      icon={<TrashIcon />}
                      circle
                    />{" "}
                    <button
                      className="button_icon"
                      onClick={() => {
                        let opened = [...openedRooms];
                        if (opened.find((el) => el == r.id)) {
                          setopenedRooms(opened.filter((el) => el != r.id));
                        } else {
                          setopenedRooms((prev) => [...prev, r.id]);
                        }
                      }}
                    >
                      {openedRooms.find((el) => el == r.id) ? (
                        <BiChevronUp />
                      ) : (
                        <BiChevronDown />
                      )}
                    </button>
                  </div>
                </div>
              ))}
            <button
              onClick={() => {
                let __id = Date.now();
                setopenedRooms((prev) => [...prev, __id]);
                setrooms((prev) =>
                  prev.concat([{ nbr: 2, id: __id, draft: true }])
                );
              }}
              className="adda_room "
              style={{
                backgroundColor: "green",
                borderRadius: "5px",
              }}
            >
              <PlusRoundIcon />
              double
            </button>
          </Responsive>
        </div>
      </Responsive>
      <style jxs>{`button.adda_room {
        display:flex; align-items:center; justify-content:space-between;
         width:100%; color:#fff
      } `}</style>
    </div>
  );
}
