import React from "react";
import { useState } from "react";
import { APi } from "../../Api";
import { PackageUmrahAtom } from "../../Atoms/packageUmrah.atom";
import { useRecoilState } from "recoil";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { DefaultTemplateUmrah } from "../../Atoms/defaultuMrahPack";
import AddEditPackageUmrah from "./AddEditPackageUmrah.component";

export default function DefaultPack() {
  const [providers, setproviders] = useState([]);
  const [model, setmodel] = useRecoilState(PackageUmrahAtom);
  const [template, settemplate] = useRecoilState(DefaultTemplateUmrah);

  const fetchProviders = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Provider, { q }, "/autocomplete2")
        .customGet()
        .then((res) => setproviders(res.data));
    }
  };
  const get = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah + "/getDefault", {})
      .customGet()
      .then((res) => {
        let m = { ...res.data };
        console.log(res.data);
        setmodel((prev) => m);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    get();
  }, []);
  const save = () => {
    let m = { ...model, isDefault: true };

    if (m.id) {
      APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah)
        .update(m.id, m)
        .then((res) => {
          get();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Élément a été bien modifié !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {});
    } else
      APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah)
        .create(m)
        .then((res) => {
          get();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {});
  };
  return (
    <>
      <div style={{ background: "#fff", padding: "10px", borderRadius: "5px" }}>
        <h5>Template</h5>
        <AddEditPackageUmrah
          providers={providers}
          fetchProviders={fetchProviders}
          isDefault
          save={save}
        />
      </div>
    </>
  );
}
