const styles = {
  programContainer: {
    backgroundColor: "rgba(70, 103, 209, 0.2)",
    padding: "10px",
    margin: "10px 0",
  },
  dayLabel: {
    fontWeight: "bold",
  },
  programContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  responsiveBlock: {
    padding: "10px",
  },
};

export default styles;
