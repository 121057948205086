import { useEffect, useState } from "react";
import Responsive from "../../../Components/Responsive";
import { IconButton, Input, SelectPicker } from "rsuite";
import styles from "./styles/programStyles";
import Attachments from "../../Packages/Attachments/Attachments.component";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import AddProgramModal from "../../../Components/modal/modalAddProgram";
import { useRecoilState } from "recoil";
import { ProgramAtom } from "../../../Atoms/progam.atom";
import { APi } from "../../../Api";
import Swal from "sweetalert2";
import ProgramModel from "../../../Models/program.model";
import appendModelToFormData from "../../../Helpers/appendToFormData";

const ProgramPackage = ({ model, setModel, programs, fetchPrograms }) => {
  const [program, setProgram] = useState();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [modelProgram, setModelProgram] = useRecoilState(ProgramAtom);
  const [isLoading, setIsLoading] = useState(false);

  const handleProgramSelect = (id) => {
    const selectedProgram = programs?.find((program) => program.id === id);
    if (selectedProgram) {
      setProgram(selectedProgram);
      setModel((prev) => ({
        ...prev,
        programId: selectedProgram.id,
      }));
    }
  };

  const getProgramsData = () =>
    programs?.map((program) => {
      return {
        label: program.name,
        value: program.id,
      };
    });

  useEffect(() => {
    const selectedProgram = programs?.find(
      (program) => program.id === model.programId
    );
    if (selectedProgram) {
      setProgram(selectedProgram);
    }
  }, [model.programId, programs]);

  const reset = () => {
    setModelProgram(new ProgramModel());
    setError("");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    setIsLoading(true);
    let m = modelProgram;
    const formData = new FormData();

    appendModelToFormData(formData, m);

    const apiEndpoint = APi.createAPIEndpoint(APi.ENDPOINTS.Programs);
    const apiCall = m.id
      ? apiEndpoint.update(m.id, formData)
      : apiEndpoint.create(formData);

    apiCall
      .then(() => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: m.id
            ? "Élément a été bien modifié !"
            : "Élément a été bien ajouté !",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchPrograms();
        handleClose();
        reset();
      })
      .catch((e) => {
        setError(e.Message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div>
        <Responsive className="p-5" m={6} l={3} xl={1.6}>
          <label>Programs: </label>{" "}
          <IconButton
            onClick={() => {
              setOpen(true);
            }}
            color="violet"
            icon={<PlusRoundIcon />}
          />
          <SelectPicker
            data={getProgramsData()}
            block
            value={model.programId}
            onSelect={handleProgramSelect}
          />
        </Responsive>
        {program &&
          program.programsLists
            ?.slice()
            .sort((a, b) => a.day - b.day)
            .map((p, index) => (
              <div key={index} style={styles.programContainer}>
                <div style={styles.dayLabel}>Jour {p.day}</div>
                <div style={styles.programContent}>
                  <Responsive l={5} xl={4} className="p-10">
                    <label>Nom : </label>
                    <Input block value={p.name} disabled />
                  </Responsive>
                  <Responsive l={5} xl={5} className="p-10">
                    <label>Description : </label>
                    <Input
                      as="textarea"
                      rows={3}
                      block
                      value={p.description}
                      disabled
                    />
                  </Responsive>
                </div>
                <Attachments
                  model={model}
                  setModel={setModel}
                  isProgram
                  index={index}
                  showFileInput={false}
                  program={p}
                />
              </div>
            ))}
      </div>
      <AddProgramModal
        open={open}
        handleClose={handleClose}
        save={save}
        error={error}
        isLoading={isLoading}
      />
    </>
  );
};

export default ProgramPackage;
