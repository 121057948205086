import React from 'react'
import { useState } from 'react';
import { useRef } from 'react';
import Progress from '../../Components/media_library/components/Progress/Progress';
import { FileDrop } from 'react-file-drop';
import { createAPIEndpoint } from '../../Api/authenticated.requests';
import { ENDPOINTS } from '../../Api/enpoints';
import "../../Components/media_library/filedrop.scss";
import "../../Components/media_library/style.module.scss";
import { useEffect } from 'react';

export default function UploadVisas() {
  return (
    <div>
    <div>  <FileD
              refresh={() => {
                // refresh();
                // setadd_opened(false);
              }}
            /></div>
    </div>
  )
}
export function FileD({ refresh, single =true}) {
    const inputRef = useRef();
    const [filenames, setNames] = useState([]);
    const [tag, settag] = useState("");
  
    const fileHandler = async (files) => {
      const extension = files[0].name.split(".")[1]?.toLowerCase();
      console.log(files)
      if (extension !== undefined) {
        const fNames = Object.keys(files).map((name) => {
          return {
            name: files[name].name,
            icon: files[name].name.split(".")[1]?.toUpperCase().trim(),
            newName: "",
          };
        });
let _names= [...filenames,...fNames]
        setNames((prev) => [..._names]);
        // console.log(fNames)
        let ar=Array.from(files)
        for(let _i=0; _i<ar.length;_i++) {
          try{  
            let uploaded = await createAPIEndpoint(ENDPOINTS.File+"/Visa").upload1(ar[_i]);
          console.log(uploaded)
          if(uploaded.data) {
            let i=_names.findIndex(el=>el.name==uploaded.data)
            console.log(i)
            let _f={..._names[i]}
            _f.newName = uploaded.data;
            _names[i]=_f
  console.log("a")}
        }catch(e){}
    }
     
        setNames((prev) => [..._names]);
  console.log(_names)
        //  if (single) {
        //}
      } else {
        alert("file type not supported");
      }
    };
  
    const filePicker = () => {
      inputRef.current.click();
    };
 useEffect(()=>{
    // console.log(filenames)
 },[filenames])
    return (
      <div className="container">
        <h3>UPLOAD FILES</h3>
        <div className="progressContainer">
          {filenames &&
            filenames.map((file, i) => (
              <Progress
                key={i}
                name={file.name}
                icon={file.icon}
                uploaded={filenames[i].newName}
                
              />
            ))}
        </div>
  
        <FileDrop onTargetClick={filePicker} onDrop={(f) => fileHandler(f)}>
          <p className="placeholder">
            DRAG FILE HERE <br /> OR <span>BROWSE</span>
          </p>
          <input
            accept=" .png,  .jpg, .jpeg"
            value=""
            style={{ visibility: "hidden", opacity: 0 }}
            ref={inputRef}
            multiple="multiple"
            type="file"
            onChange={(e) => fileHandler(e.target.files)}
          />
        </FileDrop>
     
       
      </div>
    );
  }
  