import Responsive from "../../Components/Responsive";
import { IconButton, Input, Loader, SelectPicker } from "rsuite";
import { useRecoilState } from "recoil";
import styles from "./styles/programStyles";
import { ProgramAtom } from "../../Atoms/progam.atom";
import TrashIcon from "@rsuite/icons/Trash";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import { ListProgramModel } from "../../Models/program.model";
import ProgramComponent from "./programComponent";
import { TypePackage } from "../../Constants/types";

const AddProgram = ({ isLoading, error }) => {
  const [model, _setModel] = useRecoilState(ProgramAtom);
  const handleAddProgram = () => {
    _setModel((prev) => {
      const maxDay = Math.max(
        ...prev.programsLists.map((program) => program.day),
        0
      );
      return {
        ...prev,
        programsLists: [
          ...prev.programsLists,
          { ...new ListProgramModel(), day: maxDay + 1 },
        ],
      };
    });
  };

  const handleRemoveProgram = (index) => {
    _setModel((prev) => ({
      ...prev,
      programs: prev.programsLists.filter((_, i) => i !== index),
    }));
  };

  const handleChange = (value) => {
    _setModel((prev) => ({
      ...prev,
      isUmrah: value === 1 ? false : true,
    }));
  };

  const handleChangeName = (value) => {
    _setModel((prev) => ({
      ...prev,
      name: value,
    }));
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <Loader size="lg" />
        </div>
      ) : (
        <>
          <Responsive className="p-5" m={6} l={3} xl={3}>
            <label>Nom: </label>
            <Input
              block
              value={model.name || ""}
              onChange={(name) => handleChangeName(name)}
            />
          </Responsive>
          <Responsive className="p-5" m={6} l={3} xl={3}>
            <label>Programme type</label>
            <SelectPicker
              data={TypePackage}
              block
              noSearch
              value={model.isUmrah ? 0 : 1}
              onChange={(type) => handleChange(type)}
            />
          </Responsive>

          {[...model.programsLists]
            .sort((a, b) => a.day - b.day)
            .map((program, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProgramComponent
                  index={index}
                  program={program}
                  model={model}
                  setModel={_setModel}
                />
                <Responsive
                  s={2}
                  m={2}
                  l={2}
                  xl={2}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px",
                    width: "5%",
                  }}
                >
                  <IconButton
                    onClick={() => handleRemoveProgram(index)}
                    color="violet"
                    icon={<TrashIcon />}
                    style={styles.iconButton}
                  />
                  {index + 1 === model.programsLists.length && (
                    <IconButton
                      onClick={handleAddProgram}
                      color="violet"
                      icon={<PlusRoundIcon />}
                    />
                  )}
                </Responsive>
              </div>
            ))}
        </>
      )}
    </>
  );
};

export default AddProgram;
