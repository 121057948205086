import Responsive from "../../../Components/Responsive";
import TrashIcon from "@rsuite/icons/Trash";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import "../../../style/packageUmrah/hotelMakkah/hotelMakkah.css";
import { IconButton, Input, SelectPicker } from "rsuite";
import { roomTypes, typeTravler } from "../../../Constants/types";
import format_number from "../../../Helpers/number_formatter";
import { ArrangementModel } from "../../../Models/packageomra.model";

const ArrangementSection = ({
  arrangements,
  setArrangements,
  exchange,
  duration,
}) => {
  const handleCostChange = (index, cost) => {
    const updatedArrangements = arrangements.map((arrangement, i) =>
      i === index
        ? {
            ...arrangement,
            cost,
            unitCost: calculateUnitCost(cost, arrangement.designation),
          }
        : arrangement
    );
    setArrangements(updatedArrangements);
  };

  const calculateUnitCost = (cost, designation) => {
    const divisor =
      designation === "Chambre quadruple"
        ? 4
        : designation === "Chambre triple"
        ? 3
        : 2;
    return cost / divisor;
  };

  const addArrangement = () => {
    const newArrangements = [...arrangements, new ArrangementModel()];
    setArrangements(newArrangements);
  };

  const removeArrangement = (index) => {
    const updatedArrangements = arrangements.filter((_, i) => i !== index);
    setArrangements(updatedArrangements);
  };

  const getAvailableArrangment = (index) => {
    const usedArrangements = arrangements
      .filter((_, i) => i !== index)
      .map((arr) => arr.designation);

    return roomTypes.filter(
      (option) => !usedArrangements.includes(option.value)
    );
  };

  return (
    <>
      <h6>
        Arrangements :{" "}
        {arrangements.length === 0 && (
          <IconButton
            onClick={addArrangement}
            color="violet"
            icon={<PlusRoundIcon />}
          />
        )}
      </h6>

      {arrangements.map((arrangement, index) => (
        <div key={index}>
          <Responsive className="p-10" m={6} l={4} xl={2}>
            <label>Type du Chambre:</label>
            <SelectPicker
              data={getAvailableArrangment(index)}
              block
              noSearch
              value={arrangement.designation}
              onChange={(designation) => {
                const updatedArrangements = arrangements.map((arr, i) =>
                  i === index ? { ...arr, designation } : arr
                );
                setArrangements(updatedArrangements);
              }}
            />
          </Responsive>
          <Responsive className="p-10" m={6} l={3} xl={1.8}>
            <label>type voyageur:</label>
            <SelectPicker
              data={typeTravler}
              block
              noSearch
              value={arrangement.typeTraveler}
              onChange={(typeTraveler) => {
                const updatedArrangements = arrangements.map((arr, i) =>
                  i === index ? { ...arr, typeTraveler } : arr
                );
                setArrangements(updatedArrangements);
              }}
            />
          </Responsive>
          <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
            <label>Coût:</label>
            <Input
              type="number"
              step="0.1"
              value={arrangement.cost || 0}
              onChange={(cost) => {
                handleCostChange(index, parseFloat(cost) || 0);
              }}
            />
          </Responsive>
          <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
            <label>Coût Par Personne:</label>
            <Input
              type="number"
              step="0.1"
              disabled
              value={arrangement.unitCost || 0}
            />
          </Responsive>

          <Responsive s={6} m={4} l={2} xl={1.4} className="p-10">
            <label>Coût Total TND:</label>
            <h6 className="p-10">
              {format_number(arrangement.unitCost * exchange * duration || 0)}
            </h6>
          </Responsive>

          <Responsive s={2} m={1.6} l={1} xl={1}>
            <div style={{ height: "42px" }}> </div>
            <IconButton
              onClick={() => removeArrangement(index)}
              color="violet"
              icon={<TrashIcon />}
            />
            {index + 1 === arrangements.length && (
              <IconButton
                onClick={addArrangement}
                color="violet"
                icon={<PlusRoundIcon />}
              />
            )}
          </Responsive>
        </div>
      ))}
    </>
  );
};

export default ArrangementSection;
