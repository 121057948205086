import React, { useState } from "react";
import { useEffect } from "react";
import HotelPricesTable from "./table/table";
import { PriceModel } from "../../../Models/packageomra.model";

const Price = ({ model, setModel }) => {
  const [data, setData] = useState(null);
  const calculateAddFees = (fees) =>
    fees ? fees.reduce((total, fee) => total + fee.cost, 0) : 0;

  const calculateBaseCost = (packageCosts) =>
    packageCosts.reduce((total, cost) => total + cost.cost * cost.exchange, 0);

  const createPrices = () => {
    const prices = [...(model.prices || [])];
    const addFees = calculateAddFees(model.differentFees);
    const baseCost = calculateBaseCost(model.packageUmrahCosts);
    const makkahDestinations = model.destinations.filter(
      (h) => h.name === "makkah"
    );

    model.destinations
      .filter((h) => h.name === "madinah")
      .forEach((hMadinah) => {
        hMadinah.hotelsUmrah.forEach((hotelMadinah) => {
          hotelMadinah.arrangements.forEach((arr) => {
            makkahDestinations.forEach((hMakkah) => {
              hMakkah.hotelsUmrah.forEach((hotelMakkah) => {
                const arrangementMakkah = hotelMakkah.arrangements.find(
                  (_arr) => _arr.designation === arr.designation
                );

                if (arrangementMakkah) {
                  const price = new PriceModel();
                  price.typeTraveler = arrangementMakkah.typeTraveler;
                  price.baseCost =
                    addFees +
                    baseCost +
                    arr.unitCost *
                      hotelMadinah.duration *
                      hotelMadinah.exchange +
                    arrangementMakkah.unitCost *
                      hotelMakkah.duration *
                      hotelMakkah.exchange;

                  if (model.nbr_for_free_place) {
                    price.baseCost *= 1 + 1 / model.nbr_for_free_place;
                  }

                  price.hotelMadinahName = hotelMadinah.name;
                  price.hotelMakkahName = hotelMakkah.name;
                  price.arrangementMadinahDesignation = arr.designation;
                  price.arrangementMakkahDesignation =
                    arrangementMakkah.designation;

                  const existingPrice = prices.find(
                    (_p) =>
                      _p.hotelMadinahName === price.hotelMadinahName &&
                      _p.hotelMakkahName === price.hotelMakkahName &&
                      _p.arrangementMadinahDesignation ===
                        price.arrangementMadinahDesignation &&
                      _p.arrangementMakkahDesignation ===
                        price.arrangementMakkahDesignation
                  );

                  if (!existingPrice) {
                    prices.push(price);
                  }
                }
              });
            });
          });
        });
      });

    return prices;
  };

  useEffect(() => {
    const prices = createPrices();
    if (prices.length) grouppedBy(prices);
    setModel((prev) => ({ ...prev, prices }));
  }, [
    model.nbr_for_free_place,
    model.packageUmrahCosts,
    model.b2Bcommission,
    model.commission,
    model.differentFees,
    model.disponibilities,
    model.destinations,
  ]);

  const grouppedBy = (arr) => {
    arr = arr.map((el) => ({
      ...el,
      h: `${el.hotelMakkahName} - ${el.hotelMadinahName}`,
    }));

    const eles = new Set(arr.map((el) => el.h));

    const res = {};
    eles.forEach((el) => {
      res[el] = arr
        .filter((p) => p.h === el)
        .map((_el) => {
          delete _el.h;
          return _el;
        });
    });
    setData(res);
  };

  return (
    <div className="p-10">
      <HotelPricesTable
        model={model}
        data={data}
        setModel={setModel}
        grouppedBy={grouppedBy}
      />
    </div>
  );
};

export default Price;
