import moment from "moment";
// import parseFloat from "../../Helpers/number_formatter";

export const multiDataSet = (data) => {
  if (data && data.length)
    return [
      {
        columns: [
          { title: "Voyage", width: { wpx: 120 } },

          ...data[0].costs
            .map((c) => {
              if (c.hotelName)
                return [
                  { title: "Nom du fournisseur", width: { wpx: 150 } },
                  { title: "Coût", width: { wpx: 100 } }, //pixels width
                  { title: "Hôtel", width: { wpx: 100 } }, //char width
                  { title: "Arrangement", width: { wpx: 150 } },
                ];
              else
                return [
                  { title: "Nom du fournisseur", width: { wpx: 150 } },
                  { title: "Coût", width: { wpx: 100 } },
                ];
            })
            .reduce((a, b) => [...a, ...b], []),
          { title: "Coût total", width: { wpx: 100 } },
          { title: "Client", width: { wpx: 150 } }, //char width

          { title: "Voyageur", width: { wpx: 100 } },
          { title: "Numéro de passeport", width: { wpx: 120 } },

          { title: "commission", width: { wpx: 150 } },
          { title: "Prix total", width: { wpx: 100 } }, //pixels width
          { title: "net A payer", width: { wpx: 100 } }, //char width
          { title: "Benifice", width: { wpx: 100 } }, //char width
        ],
        data: data.map((item) => {
          let item2 = [
            {
              value: item.packageName,
              style: {
                fill: { fgColor: { rgb: "eeeeee" } },
                font: { sz: "13", color: { rgb: "2590b5" } },
                alignment: { horizontal: "center" },
              },
            },

            ...item.costs
              .map((c) => {
                if (c.hotelName || c.arrangement)
                  return [
                    {
                      value: c.providerName,
                      style: {
                        font: { sz: "14", color: { rgb: "ffffff" } },
                        alignment: { horizontal: "center" },
                        fill: { fgColor: { rgb: "2f1a4c" } },
                      },
                    },
                    {
                      value: c.cost,
                      style: {
                        font: { sz: "14", color: { rgb: "2f1a4c" } },
                        alignment: { horizontal: "center" },
                      },
                    },
                    {
                      value: c.hotelName,
                      style: {
                        font: { sz: "14", color: { rgb: "4c67c2" } },
                        alignment: { horizontal: "left" },
                      },
                    },
                    {
                      value: c.arrangement,
                      style: {
                        font: { sz: "14", color: { rgb: "00a98d" } },
                        alignment: { horizontal: "left" },
                      },
                    },
                  ];
                else
                  return [
                    {
                      value: c.providerName,
                      style: {
                        font: { sz: "14", color: { rgb: "ffffff" } },
                        alignment: { horizontal: "center" },
                        fill: { fgColor: { rgb: "2f1a4c" } },
                      },
                    },
                    {
                      value: c.cost,
                      style: {
                        font: { sz: "14", color: { rgb: "2f1a4c" } },
                        alignment: { horizontal: "center" },
                      },
                    },
                  ];
              })
              .reduce((a, b) => [...a, ...b], []),
            {
              value: parseFloat(
                item.costs.reduce((an, a) => an + a.cost, 0)
              ),
              style: {
                font: { sz: "16", color: { rgb: "ffffff" } },
                fill: { fgColor: { rgb: "ad5389" } },

                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.client ? item.client.name : "",
              style: {
                font: { sz: "14", color: { rgb: "4c67c2" } },
                alignment: { horizontal: "left" },
              },
            },
            {
              value: item.customer.name,
              style: {
                font: { sz: "14", color: { rgb: "5a2c3e" } },
                alignment: { horizontal: "left" },
              },
            },
            {
              value: item.customer.passportNumber,
              style: {
                fill: { fgColor: { rgb: "eeeeee" } },
                alignment: { horizontal: "center" },
              },
            },

            {
              value: parseFloat(item.commission),
              style: {
                fill: { fgColor: { rgb: "eeeeee" } },

                font: { sz: "14", color: { rgb: "2f1a4c" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: parseFloat(item.totatlPrice),
              style: {
                fill: { fgColor: { rgb: "eeeeee" } },

                font: { sz: "14", color: { rgb: "2f1a4c" } },
                alignment: { horizontal: "center" },
              },
            },
            // {
            //   value: checkPurpose.find((el) => el.value == item.checkPurpose)
            //     .label,
            //   style: {
            //     font: {
            //       sz: "14",
            //       color: {
            //         rgb:
            //           item.checkPurpose == 1
            //             ? "673ab7"
            //             : item.checkPurpose == 2
            //             ? "5a2c3e"
            //             : "f0b217",
            //       },
            //     },
            //     alignment: { horizontal: "center" },
            //   },
            // },
            {
              value: parseFloat(item.totatlPrice - item.commission),
              style: {
                font: { sz: "14", color: { rgb: "2f1a4c" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: parseFloat(
                item.totatlPrice -
                  item.commission -
                  item.costs.reduce((an, a) => an + a.cost, 0)
              ),
              style: {
                font: { sz: "16", color: { rgb: "ffffff" } },
                fill: { fgColor: { rgb: "0b9666" } },

                alignment: { horizontal: "center" },
              },
            },
          ];
          return item2;
        }),
      },
    ];
};
