export default class PackageUmrahModel {
  constructor() {
    this.id = 0;
    this.name = "";
    this.b2Bcommission = 0;
    this.commission = 0;
    this.nbr_places = 0;
    this.nbr_for_free_place = 0;
    this.isDefault = false;
    this.baseCost = 0;
    this.imageUrls = [];
    this.price = 0;
    this.caracteristique = new CaracteristiquesPackageUmrahModel();
    this.description = "";
    this.idDossAlfresco = "";
    this.prices = [new PriceModel()];
    this.differentFees = [new DifferentFeesModal()];
    this.destinations = [];
    this.disponibilities = [new DisponibilityModel()];
    this.packageUmrahCosts = [];
    this.images = [];
    this.idsDelete = [];
    this.extrats = [];
    this.programId = 0;
  }
}

export class PackageUmrahCost {
  constructor() {
    this.id = 0;
    this.packageUmrahId = 0;
    this.providerId = 0;
    this.exchange = 0;
    this.currencyId = 0;
    this.cost = 0;
    this.designation = "";
    this.tag = "";
    this.service_fees = 0;
  }
}

export class CaracteristiquesPackageUmrahModel {
  constructor() {
    this.hasVol = false;
    this.hasTransfert = false;
    this.hasHotels = false;
    this.hasVisa = false;
    this.hasMeal = false;
  }
}

export class PriceModel {
  constructor() {
    this.id = 0;
    this.packageUmrahId = 0;
    this.typeTraveler = "";
    this.baseCost = 0;
    this.hotelMadinahName = "";
    this.hotelMakkahName = "";
    this.arrangementMadinahDesignation = "";
    this.arrangementMakkahDesignation = "";
    this.price = 0;
    this.isHidden = false;
  }
}

export class ExtratsModal {
  constructor() {
    this.id = 0;
    this.packageUmrahId = 0;
    this.providerId = 0;
    this.designation = "";
    this.cost = 0;
    this.exchange = 1;
    this.currencyId = 0;
  }
}

export class DifferentFeesModal {
  constructor() {
    this.id = 0;
    this.packageUmrahId = 0;
    this.designation = "";
    this.cost = 0;
  }
}

export class DestinationUmrahModel {
  constructor() {
    this.id = 0;
    this.name = "";
    this.order = 0;
    this.nbrDay = 1;
    this.packageUmrahId = 0;
    this.hotelsUmrah = [new HotelUmrahModel()];
    this.excursionDestIds = [];
  }
}

export class DisponibilityModel {
  constructor() {
    this.id = 0;
    this.date = "";
    this.returnDate = "";
    this.onStock = 0;
    this.cost = 0;
    this.commission = 0;
    this.b2BCommission = 0;
    this.isDefault = false;
    this.airlineId = null;
    this.transfert = new Transfert();
    this.flights = [];
  }
}

export class FlightsModel {
  constructor(type, airlineId) {
    this.id = 0;
    this.name = "";
    this.departDate = "";
    this.arrivalDate = "";
    this.description = "";
    this.departureAirportId = 0;
    this.arrivalAirportId = 0;
    this.airlineId = airlineId | null;
    this.class = "";
    this.flightNumber = "";
    this.flightDuration = "";
    this.handBaggages = "";
    this.baggages = "";
    this.type = type;
  }
}

export class HotelUmrahModel {
  constructor(name) {
    this.id = 0;
    this.duration = 1;
    this.checkin = new Date();
    this.exchange = 0;
    this.currencyId = 0;
    this.providerId = 0;
    this.isMakkah = false;
    this.distanceToKaaba = 0;
    this.name = name || "";
    this.packageUmrahId = 0;
    this.hotelListId = 0;
    this.destinationId = 0;
    this.arrangements = [];
    this.packageMealTypes = [];
    this.caracteristique = new CaracteristiqueHotelModel();
  }
}

export class ArrangementModel {
  constructor() {
    this.id = 0;
    this.cost = 0;
    this.designation = "";
    this.hotelUmrahId = 0;
    this.unitCost = 0;
    this.typeTraveler = "";
  }
}

export class MealTypeModel {
  constructor() {
    this.id = 0;
    this.hotelId = 0;
    this.totalPrice = 0;
    this.cost = 0;
    this.typeMeal = null;
  }
}

export class CaracteristiqueHotelModel {
  constructor() {
    this.hasWifi = false;
    this.hasBreakfast = false;
    this.hasLunch = false;
    this.hasDinner = false;
    this.hasPosition = false;
    this.hasVol = false;
    this.hasTransfert = false;
    this.hasBus = false;
  }
}

export class Program {
  constructor() {
    this.id = 0;
    this.name = "";
    this.description = "";
    this.packageUmrahId = 0;
    this.day = 0;
    this.dayName = "";
  }
}

export class Transfert {
  constructor() {
    this.id = 0;
    this.name = "";
    this.description = "";
    this.packageUmrahId = 0;
    this.caracteristiqueId = 0;
    this.caracteristiques = new CaracteristiquesTransfert();
    this.transfertLists = [new TransfertListModel()];
  }
}

export class CaracteristiquesTransfert {
  constructor() {
    this.hasVol = false;
    this.hasTransfert = false;
    this.hasWifi = false;
    this.hasBreakfast = false;
    this.hasBus = false;
  }
}

export class TransfertListModel {
  constructor() {
    this.id = 0;
    this.name = "";
    this.date = "";
    this.departName = "";
    this.departType = "";
    this.arrivalName = "";
    this.arrivalType = "";
    this.modeTransports = [new ModeTransportMdel()];
  }
}

export class ModeTransportMdel {
  constructor() {
    this.id = 0;
    this.name = "";
    this.price = 0;
  }
}

export class Excursion {
  constructor(id = 0) {
    this.id = id;
  }
}
