import { Input, SelectPicker } from "rsuite";
import Responsive from "../../../Components/Responsive";
import format_number from "../../../Helpers/number_formatter";
import { PackageUmrahCost } from "../../../Models/packageomra.model";

const PackageUmrahCosts = ({
  costModel,
  fetchProviders,
  exchanges,
  currencies,
  model,
  setModel,
  setCostModel,
  providers,
}) => {
  const handleChange = (key, value, parseFunction) => {
    setCostModel((prev) => ({
      ...prev,
      [key]: parseFunction ? parseFunction(value) : value,
    }));
  };

  const handleProviderSelect = (providerId) => {
    const provider = providers.find((p) => p.id === providerId);
    const currencyId = provider?.currencies?.[0]?.currencyId || 0;
    const exchangeRate =
      exchanges.find((el) => el.currencySourceId === currencyId)?.rate || 1;

    setCostModel((prev) => ({
      ...prev,
      providerId,
      currencyId,
      exchange: exchangeRate,
    }));
  };

  const handleCurrencySelect = (currencyId) => {
    const exchangeRate =
      exchanges.find((el) => el.currencySourceId === currencyId)?.rate || 1;

    setCostModel((prev) => ({
      ...prev,
      currencyId,
      exchange: exchangeRate,
    }));
  };

  const getCurrencyOptions = () => {
    const provider = providers.find((p) => p.id === costModel.providerId);
    return (
      provider?.currencies?.map((c) => ({
        label: c.currency.name,
        value: c.currency.id,
      })) ||
      currencies.map((c) => ({
        label: c.name,
        value: c.id,
      }))
    );
  };

  const handleSubmit = () => {
    setModel((prev) => {
      const packageUmrahCosts = [...model.packageUmrahCosts];
      const index = packageUmrahCosts.findIndex(
        (el) =>
          (el.id && el.id === costModel.id) ||
          (!el.id && el.uuid && el.uuid === costModel.uuid)
      );

      if (index !== -1) {
        packageUmrahCosts[index] = costModel;
      } else {
        packageUmrahCosts.push({
          ...costModel,
          uuid: Date.now(),
        });
      }

      return { ...prev, packageUmrahCosts };
    });

    setCostModel(new PackageUmrahCost());
  };

  return (
    <div>
      <Responsive l={5} xl={5} className="p-10">
        <label>Désignation : </label>
        <Input
          block
          value={costModel.designation}
          onChange={(designation) => handleChange("designation", designation)}
        />
      </Responsive>
      <Responsive m={1} l={1} xl={1} className="p-10">
        <label>tag : </label>
        <Input
          value={costModel.tag}
          onChange={(tag) => handleChange("tag", tag.toUpperCase())}
        />
      </Responsive>
      <Responsive className="p-10" m={4} l={2.5} xl={1.8}>
        <label>fournisseur : </label>
        <SelectPicker
          onSearch={(q) => fetchProviders(q)}
          data={providers.map((c) => ({ label: c.name, value: c.id }))}
          block
          noSearch
          value={costModel.providerId}
          onSelect={handleProviderSelect}
        />
      </Responsive>

      <Responsive s={3} m={3} l={1.5} xl={1} className="p-10">
        <label>Coût :</label>
        <Input
          type="number"
          step="0.1"
          value={costModel.cost}
          onChange={(cost) => handleChange("cost", parseFloat(cost))}
        />
      </Responsive>
      <Responsive s={3} m={3} l={1.5} xl={1.2} className="p-10">
        <label>Devise :</label>
        <SelectPicker
          data={getCurrencyOptions()}
          block
          noSearch
          value={costModel.currencyId}
          onSelect={handleCurrencySelect}
        />
      </Responsive>
      <Responsive s={3} m={3} l={1.5} xl={0.8} className="p-10">
        <label>Cour:</label>
        <h6 className="p-10">{costModel.exchange}</h6>
      </Responsive>
      <Responsive s={3} m={3} l={1.5} xl={1} className="p-10">
        <label>Coût TND:</label>
        <h6 className="p-10">
          {format_number(costModel.cost * costModel.exchange || 0)}
        </h6>
      </Responsive>
      <div style={{ textAlign: "right" }} className="p-10">
        <button
          style={{ background: "rgb(70,90,238,1)", color: "#fff" }}
          onClick={handleSubmit}
        >
          {costModel.id || costModel.uuid ? "Modifier" : "Ajouter"}
        </button>
      </div>
    </div>
  );
};

export default PackageUmrahCosts;
