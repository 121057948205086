import FileDownloadIcon from "@rsuite/icons/FileDownload";
import ImageIcon from "@rsuite/icons/Image";
import TextImageIcon from "@rsuite/icons/TextImage";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaFile } from "react-icons/fa";
import { ImCheckboxChecked } from "react-icons/im";
import { useRecoilState } from "recoil";
import { Button, Checkbox, IconButton, Input, SelectPicker, Tag } from "rsuite";
import Pagination from "rsuite/Pagination";
import { APi } from "../../Api/";
import { ENDPOINTS } from "../../Api/enpoints";
import { ConfigState } from "../../Atoms/config.atom";
import { groupsAtoms } from "../../Atoms/groups.atoms";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import { BASE_URL } from "../../Config/api.config";
import WakilModel from "../../Models/wakilVisa/WakilModel";
export default function SendToWakil(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [clients, setclients] = useState([]);
  const [packages, setpackages] = useState([]);
  const [checkeds, setcheckeds] = useState([]);
  const [data2, setdata2] = useRecoilState(groupsAtoms);
  const [checkTime, setcheckTime] = useState(Date.now());
  const [imagesLoaded, setImagesLoaded] = useState(0);
  // Set the total number of images
  const [loadPrint, setloadPrint] = useState(false);
  const [vols, setvols] = useState([]);
  const [configState, setConfigState] = useRecoilState(ConfigState);
  const [totalCount, settotalCount] = useState(0);
  const [selected, setselected] = useState(false);
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 100,
    clientId: 0,
    status: 3,
    date: null,
    tagId: 0,
  });
  const [changegroupModel, setchangegroupModel] = useState({
    groupId: null,
    orders: [],
  });
  // --- add edit model ---
  const [error, setError] = useState("");

  // API CALLS
  const fetchClients = (q, forFilter = true) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Client, { q }, "/autocomplete")
        .customGet()
        .then((res) => setclients(res.data));
    }
  };

  const fetchPackages = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah + "/getForPrices", { q })
        .customGet()
        .then((res) => {
          setpackages(res.data.data);

          let _vols = res.data.data.reduce(
            (a, b) => a.concat(b.disponibilities),
            []
          );
          console.log(_vols);
          setvols(_vols.filter((d) => d.onStock));
        });
    }
  };
  const _fetch = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Umrah", {
      ...filterModel,
      date: filterModel.date
        ? moment(filterModel.date).format("DD MMM YYYY")
        : null,
    })
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        setcheckeds([]);

        settotalCount(res.data.totalCount);
      })
      .catch((e) => {});
  };

  // LIFE CYCLES
  useEffect(() => {
    fetchClients();
    fetchPackages();
  }, []);
  useEffect(() => {
    _fetch();
  }, [filterModel.page, filterModel.take]);
  const columns = [
    {
      value: "id",
      name: "-",
      render: (id) => (
        <Checkbox
          onChange={(v) => {
            if (checkeds.find((el) => el == id))
              setcheckeds((prev) => prev.filter((l) => l != id));
            else setcheckeds((prev) => [...prev, id]);
          }}
          checked={checkeds.find((el) => el == id) != null}
        ></Checkbox>
      ),
    },

    {
      value: "groupId",
      name: "Groupe",
      render: (v) => (
        <a>
          <b>
            {v && data2.find((el) => el.id == v)
              ? data2.find((el) => el.id == v).name
              : ""}
          </b>
        </a>
      ),
    },
    {
      value: "customer",
      value2: "onlyTicket",
      value3: "withoutVisa",
      value4: "withoutTicket",
      name: "Client B2C",
      render: (v, onlyTicket, withoutVisa, withoutTicket) => (
        <b style={{ display: "flex", alignItems: "center" }}>
          {v.picture ? (
            <img
              alt=""
              src={
                BASE_URL +
                "Uploads/CustomerPictures/" +
                v.picture +
                "?" +
                Date.now()
              }
              width="43"
              height="43"
              style={{
                border: "1px solid #eee",
                borderRadius: "50%",
                boxShadow: "-11px -5px 20px -13px rgba(0,0,0,0.75)",
              }}
            />
          ) : (
            <div
              style={{
                fontSize: "15px",
                border: "1px dashed #888",
                borderRadius: "50%",
                width: "43px",
                height: "43px",
                textAlign: "center",
                lineHeight: "42px",
              }}
            >
              <ImageIcon color="#3598ff"></ImageIcon>
            </div>
          )}
          <div style={{ padding: "0 5px" }}>
            {v.firstName + "  " + v.lastName}
            {"  "}
            <b
              style={{
                color: "#111",
                fontSize: "15px",
              }}
            >
              {v ? v.passportNumber : ""}
            </b>
            <br></br>
            <b style={{ color: "#330033" }}>
              {v.firstNameAr + "  " + v.lastNameAr}
            </b>
            {onlyTicket ? <Tag color="blue">Billet</Tag> : ""}{" "}
            {withoutVisa ? <Tag color="orange">sans visa</Tag> : ""}
            {withoutTicket ? <Tag color="violet">Sans Billet</Tag> : ""}
          </div>
        </b>
      ),
    },

    {
      value: "disponibility",
      name: "Vol",
      render: (c) =>
        c ? (
          <b
            style={{
              display: "flex",
              alignItems: "center",
              padding: "3px",

              borderRadius: "5px",
            }}
          >
            <img
              alt=""
              style={{ width: "30px", height: "30px", margin: "0 6px" }}
              src={c.airline ? c.airline.logo : ""}
            ></img>
            <b>
              {" "}
              <div style={{ color: "#232323" }}>
                {moment(c.date).format("DD MMM YYYY")}
              </div>
              <b style={{ color: "#888", fontWeight: "lighter" }}>
                {" "}
                {c.airline ? c.airline.name : ""}
              </b>
            </b>
          </b>
        ) : (
          <b>-</b>
        ),
    },

    {
      value: "fingerprint",
      name: "Empreinte",
      render: (v) => (v ? <ImCheckboxChecked color="green" /> : <b>-</b>),
    },
    {
      value: "client",
      name: "Client B2B",
      render: (v) => <a>{v ? v.name.toUpperCase() : ""}</a>,
    },

    {
      value: "codeMoufa",

      name: "Code Wakil",
      render: (v, v2) => (
        <span
          style={{
            color: v ? "green" : v2 ? "red" : "",
          }}
        >
          {v ? "Envoyé vers wakil" : "-"}
        </span>
      ),
    },
    {
      value: "errorMessage",
      value2: "codeMoufa",
      name: "Erreur",
      render: (v, v2) => (
        <span
          style={{
            color: v ? "green" : v ? "red" : "",
          }}
        >
          {v ? (v2 ? "" : "Probléme envoie") : "-"}
        </span>
      ),
    },
    {
      value: "visaCode",
      name: "Visa",
      render: (v) => (
        <b
          style={{
            color: v ? "white" : "",
            padding: "5px 10px",
            background: v ? "blue" : "",
            borderRadius: "5px",
          }}
        >
          {v ? v : "-"}
        </b>
      ),
    },
  ];
  const getImages2 = async () => {
    const response = await fetch("http://localhost:8733/getImages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(checkeds),
    });
    if (response) alert("succés");
  };
  const getImages = async (meth = "exportPictures") => {
    try {
      const response = await fetch(
        `https://financeapi.kounouz.travel/${meth}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(checkeds),
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = meth == "exportPictures" ? "pictures.zip" : "passports.zip";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading ZIP file:", error);
    }

    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', 'file.zip'); //set download attribute to link
    // document.body.appendChild(link);
    // link.click()
    // .then((file) =>  FileSaver.saveAs(file, '-results.zip'));
  };
  const saveSrdocs = () => {
    if (checkeds && checkeds.length) {
      let _data = data
        .filter((el) => checkeds.some((id) => id == el.id))
        .map((t) => {
          return {
            ...t,
            _name: `${t.customer.lastName
              .trim()
              .toUpperCase()}-${t.customer.firstName.trim().toUpperCase()}`,
          };
        })
        .sort((a, b) => ("" + a._name).localeCompare(b._name));
      const element = document.createElement("a");
      let text = ``;
      let text2 = ``;
      _data.map((t, k) => {
        text =
          text +
          `${k && !(k % 5) ? "\n" : ""}SRDOCS ${t.disponibility.airline.logo
            .replace("https://images.kiwi.com/airlines/64/", "")
            .substring(0, 2)} HK1-P-TUN-${
            t.customer.passportNumber
          }-TUN-${moment(t.customer.birthDate) // nationality
            .format("DDMMMYY")
            .toUpperCase()}-${t.customer.gender == 1 ? "M" : "F"}-${moment(
            t.customer.expirationPassportDate
          )
            .format("DDMMMYY")
            .toUpperCase()}-${t.customer.lastName
            .trim()
            .toUpperCase()}-${t.customer.firstName.trim().toUpperCase()}/P${
            k + 1
          };\n`;
        text2 =
          text2 +
          (k % 10 ? "" : `\nNM1 `) +
          `${t.customer.lastName.trim().toUpperCase()}/${t.customer.firstName
            .trim()
            .toUpperCase()} ${
            t.customer.title == 2 ? "MRS" : t.customer.title == 3 ? "MSS" : "MR"
          }${k % 10 < 9 && k < _data.length - 1 ? " 1 " : " "}`;
      });
      const file = new Blob([text], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = "srdocs.txt";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      const file2 = new Blob([text2], { type: "text/plain" });
      element.href = URL.createObjectURL(file2);
      element.download = "pnr.txt";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  };
  useEffect(() => {
    setchangegroupModel((prev) => {
      return { ...prev, orders: checkeds };
    });
  }, [checkeds.length]);
  const handlePrint = () => {
    setloadPrint(true);
    const iframe = document.getElementById("iframe");
    if (checkeds.length && imagesLoaded === checkeds.length) {
      iframe.contentDocument.body.innerHTML = "";

      const content = generateHTMLContent();
      iframe.contentDocument.write(content);
      iframe.contentWindow.print();
      setloadPrint(false);
      setImagesLoaded(0);
    } else
      setTimeout(() => {
        if (checkeds.length && imagesLoaded === checkeds.length) {
          iframe.contentDocument.body.innerHTML = "";

          const content = generateHTMLContent();
          iframe.contentDocument.write(content);
          iframe.contentWindow.print();
          setloadPrint(false);
          setImagesLoaded(0);
        } else
          setTimeout(() => {
            iframe.contentDocument.body.innerHTML = "";

            const content = generateHTMLContent();
            iframe.contentDocument.write(content);
            iframe.contentWindow.print();
            setloadPrint(false);
            setImagesLoaded(0);
          }, 5000);
      }, 10000);
  };

  function generateHTMLContent() {
    // Replace this with your dynamic content generation logic

    let els = data.filter((p1) => checkeds.find((_p) => _p == p1.id));

    // Generate a list of image tags dynamically
    const imageTags = els
      .map((el) => el.customer)
      .map(
        (v, index) =>
          `  <img style="display:inline-block; width:6in;
          height:4.1in; 
border-radius:10px; vertical-align:top ; margin:auto; margin-bottom:10px
          ; border:1px dashed #888" src=${
            BASE_URL + "Uploads/" + v.passportPicture + "?t=" + checkTime
          } />
          
       `
      )
      .join("");
    //   <div rtl="true"><span style="color:#4667d1; font-size:22px">: مندوب  مدينة  <span></div>
    //   <div rtl="true"><span style="color:#4667d1; font-size:22px">: مندوب  مدينة  <span></div>
    // The complete HTML document structure
    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${Date.now()}</title>
      </head>
      <body>
 ${imageTags}
      </body>
      </html>
    `;
  }
  return (
    <div>
      <div style={{ overflow: "hidden", height: 0 }}>
        <iframe id="iframe" title="printFrame" width="100%"></iframe>

        {data
          .filter((p1) => checkeds.find((_p) => _p == p1.id))
          .map((el) => el.customer)
          .map((c) => (
            <img
              onLoad={() =>
                setImagesLoaded((prevImagesLoaded) => prevImagesLoaded + 1)
              }
              onError={() =>
                setImagesLoaded((prevImagesLoaded) => prevImagesLoaded + 1)
              }
              src={
                BASE_URL + "Uploads/" + c.passportPicture + "?t=" + checkTime
              }
              key={c.id}
              alt=""
            ></img>
          ))}
      </div>
      <div>
        <Filter search={() => _fetch()}>
          <Responsive m={3} l={2.4} xl={2.4} className="p-10">
            <label>Recherche: </label>
            <Input
              placeholder="recherche"
              onChange={(q) => {
                setfilterModel((prev) => {
                  return { ...prev, q };
                });
              }}
            />
          </Responsive>
          <Responsive m={3} l={2.4} xl={2.4} className="p-10">
            <label>Group: </label>
            <SelectPicker
              data={[{ label: "Tout", value: 0 }].concat(
                data2.map((c) => {
                  return { label: c.name, value: c.id };
                })
              )}
              block
              noSearch
              value={filterModel.groupId}
              onSelect={(groupId) => {
                setfilterModel((prev) => {
                  return { ...prev, groupId };
                });
              }}
            />
          </Responsive>
          <Responsive m={3} l={2.4} xl={2.4} className="p-10">
            <label>Client: </label>
            <SelectPicker
              onSearch={(q) => fetchClients(q)}
              data={[{ label: "Tout", value: 0 }].concat(
                clients.map((c) => {
                  return { label: c.name, value: c.id };
                })
              )}
              block
              noSearch
              value={filterModel.clientId}
              onSelect={(clientId) => {
                setfilterModel((prev) => {
                  return { ...prev, clientId };
                });
              }}
            />
          </Responsive>

          <Responsive m={3} l={2.4} xl={2.4} className="p-10">
            <label>Nom Omrah: </label>
            <SelectPicker
              onSearch={fetchPackages}
              data={
                packages
                  ? [{ label: "Tout", value: 0 }].concat(
                      packages.map((el) => {
                        return { label: el.name, value: el.id };
                      })
                    )
                  : [{ label: "Tout", value: 0 }]
              }
              block
              noSearch
              value={filterModel.packageUmrahId}
              onSelect={(packageUmrahId) => {
                setfilterModel((prev) => {
                  return { ...prev, packageUmrahId };
                });
              }}
            />
          </Responsive>
          <Responsive m={3} l={2.4} xl={2.4} className="p-10">
            <label>Vol: </label>
            <SelectPicker
              data={[{ label: "Tout", value: 0 }].concat(
                vols
                  ? vols.map((el) => {
                      return {
                        label: (
                          <div style={{ color: "#232323" }}>
                            {moment(el.date).format("DD MMM YYYY")}
                          </div>
                        ),
                        value: el.id,
                      };
                    })
                  : []
              )}
              block
              noSearch
              value={filterModel.disponibilityId}
              onSelect={(disponibilityId) => {
                setfilterModel((prev) => {
                  return { ...prev, disponibilityId };
                });
              }}
            />
          </Responsive>
        </Filter>
        <ExportAdd
          // excelData={multiDataSet(data)}
          // ActionOnClose={reset}
          nameExcel="saleUmrah"
          size="lg"
          // save={save}
          noAdd
          additionalBtn={
            <div className="p-5">
              <Button
                color="blue"
                appearance="primary"
                onClick={() => {
                  getImages2();
                }}
              >
                téléchargement auto
              </Button>
            </div>
          }
        />
        <div>
          <IconButton
            icon={<FileDownloadIcon />}
            onClick={() => getImages()}
            appearance="primary"
          >
            Images{" "}
          </IconButton>{" "}
          <IconButton
            icon={<TextImageIcon />}
            appearance="primary"
            onClick={() => getImages("exportPassportPictures")}
          >
            Passports{" "}
          </IconButton>
          <Button
            onClick={() => {
              saveSrdocs();
            }}
            color="orange"
            appearance="ghost"
          >
            <FaFile /> SRDOCS{" "}
          </Button>{" "}
          <Button
            loading={loadPrint}
            appearance="primary"
            color="orange"
            onClick={handlePrint}
          >
            imprimer passports
          </Button>
        </div>
        <div
          onClick={(e) =>
            setcheckeds((prev) => (prev.length ? [] : data.map((el) => el.id)))
          }
          style={{
            display: "inline-block",
            padding: "8px",
            borderRadius: "4px",
          }}
        >
          <Checkbox checked={checkeds.length > 0}></Checkbox> Sélectionner Tout
        </div>
        <div className="p-10">
          <Responsive s={6} m={6} l={4} xl={3}>
            <SelectPicker
              data={[{ label: "Tout", value: 0 }].concat(
                data2.map((c) => {
                  return { label: c.name, value: c.id };
                })
              )}
              block
              noSearch
              value={changegroupModel.groupId}
              onSelect={(groupId) => {
                setchangegroupModel((prev) => {
                  return { ...prev, groupId };
                });
              }}
            />
          </Responsive>{" "}
          <Button
            appearance="primary"
            color="blue"
            onClick={() => {
              APi.createAPIEndpoint(ENDPOINTS.Order + "/changGgroup")
                .create(changegroupModel)
                .then((res) => {
                  _fetch();
                  alert("success");
                });
            }}
          >
            changer
          </Button>
        </div>
        <Grid columns={columns} rows={data} />
        <div style={{ padding: 20, background: "#fff" }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={totalCount}
            limitOptions={[10, 20, 50, 100, 200, 250, 500, 1000]}
            limit={filterModel.take}
            activePage={filterModel.page}
            onChangePage={(page) => {
              window.scrollTo(0, 0);
              setfilterModel((prev) => {
                return { ...prev, page };
              });
            }}
            onChangeLimit={(take) => {
              console.log(take);
              setfilterModel((prev) => {
                return { ...prev, take, page: 1 };
              });
            }}
          />
        </div>
      </div>{" "}
    </div>
  );
}
