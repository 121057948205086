export default class ExcursionModel {
  constructor() {
    this.name = "";
    this.latitude = 0;
    this.typeExcursion = 0;
    this.longitude = 0;
    this.description = "";
    this.caracteristique = new CaracteristiquesExcursion();
    this.images = [];
    this.ImageIds = [];
    this.videos = [];
    this.videoIds = [];
  }
}

export class CaracteristiquesExcursion {
  constructor() {
    this.hasVol = false;
    this.hasTransfert = false;
    this.hasWifi = false;
    this.hasBreakfast = false;
    this.hasBus = false;
  }
}
