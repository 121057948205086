const styles = {
  section: {
    paddingBottom: "10px",
  },
  flightContainer: {
    display: "flex",
    flexDirection: "column",
  },
  flightRow: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: "16px",
  },
  flightContent: {
    flexGrow: 1,
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    width: "5%",
  },
  iconButton: {
    width: "30px",
    height: "30px",
  },
  container: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
  },
};

export default styles;
