import FileDownloadIcon from "@rsuite/icons/FileDownload";
import ImageIcon from "@rsuite/icons/Image";
import TextImageIcon from "@rsuite/icons/TextImage";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaFile } from "react-icons/fa";
import { ImCheckboxChecked } from "react-icons/im";
import { useRecoilState } from "recoil";
import { Button, Checkbox, IconButton, Input, SelectPicker, Tag } from "rsuite";
import Pagination from "rsuite/Pagination";
import { APi } from "../../Api/";
import { ENDPOINTS } from "../../Api/enpoints";
import { ConfigState } from "../../Atoms/config.atom";
import { groupsAtoms } from "../../Atoms/groups.atoms";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import { BASE_URL } from "../../Config/api.config";
import WakilModel from "../../Models/wakilVisa/WakilModel";
import { rawdhasAtoms } from "../../Atoms/rawdhasAtoms";
export default function OperationsRawdha(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [clients, setclients] = useState([]);
  const [packages, setpackages] = useState([]);
  const [checkeds, setcheckeds] = useState([]);
  const [data2, setdata2] = useRecoilState(rawdhasAtoms);
  // Set the total number of images
  const [vols, setvols] = useState([]);
  const [configState, setConfigState] = useRecoilState(ConfigState);
  const [totalCount, settotalCount] = useState(0);
  const [selected, setselected] = useState(false);
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 100,
    clientId: 0,
    status: 3,
    date: null,
    tagId: 0,
  });
  const [changeRawdhaModel, setchangeRawdhaModel] = useState({
    rawdhaId: null,
    orders: [],
  });
  // --- add edit model ---
  const [error, setError] = useState("");

  // API CALLS
  const fetchClients = (q, forFilter = true) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Client, { q }, "/autocomplete")
        .customGet()
        .then((res) => setclients(res.data));
    }
  };

  const fetchPackages = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah + "/getForPrices", { q })
        .customGet()
        .then((res) => {
          setpackages(res.data.data);

          let _vols = res.data.data.reduce(
            (a, b) => a.concat(b.disponibilities),
            []
          );
          console.log(_vols);
          setvols(_vols.filter((d) => d.onStock));
        });
    }
  };
  const _fetch = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Umrah", {
      ...filterModel,
      date: filterModel.date
        ? moment(filterModel.date).format("DD MMM YYYY")
        : null,
    })
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        setcheckeds([]);

        settotalCount(res.data.totalCount);
      })
      .catch((e) => {});
  };

  // LIFE CYCLES
  useEffect(() => {
    fetchClients();
    fetchPackages();
  }, []);
  useEffect(() => {
    _fetch();
  }, [filterModel.page, filterModel.take]);
  const columns = [
    {
      value: "id",
      name: "-",
      render: (id) => (
        <Checkbox
          onChange={(v) => {
            if (checkeds.find((el) => el == id))
              setcheckeds((prev) => prev.filter((l) => l != id));
            else setcheckeds((prev) => [...prev, id]);
          }}
          checked={checkeds.find((el) => el == id) != null}
        ></Checkbox>
      ),
    },

    {
      value: "rawdhaId",
      name: "Rawdha",
      render: (v) => (
        <a>
          <b>
            {v && data2.find((el) => el.id == v)
              ? data2.find((el) => el.id == v).designation
              : ""}
          </b>
        </a>
      ),
    },
    {
      name: "Rawdha",
      value: "isRawdhaSent",
      render: (t) => (
        <Tag color={t ? "blue" : "orange"}>{t ? "oui" : "non"}</Tag>
      ),
    },
    {
      value: "customer",
      value2: "onlyTicket",
      value3: "withoutVisa",
      value4: "withoutTicket",
      name: "Client B2C",
      render: (v, onlyTicket, withoutVisa, withoutTicket) => (
        <b style={{ display: "flex", alignItems: "center" }}>
          {v.picture ? (
            <img
              alt=""
              src={
                BASE_URL +
                "Uploads/CustomerPictures/" +
                v.picture +
                "?" +
                Date.now()
              }
              width="43"
              height="43"
              style={{
                border: "1px solid #eee",
                borderRadius: "50%",
                boxShadow: "-11px -5px 20px -13px rgba(0,0,0,0.75)",
              }}
            />
          ) : (
            <div
              style={{
                fontSize: "15px",
                border: "1px dashed #888",
                borderRadius: "50%",
                width: "43px",
                height: "43px",
                textAlign: "center",
                lineHeight: "42px",
              }}
            >
              <ImageIcon color="#3598ff"></ImageIcon>
            </div>
          )}
          <div style={{ padding: "0 5px" }}>
            {v.firstName + "  " + v.lastName}
            {"  "}
            <b
              style={{
                color: "#111",
                fontSize: "15px",
              }}
            >
              {v ? v.passportNumber : ""}
            </b>
            <br></br>
            <b style={{ color: "#330033" }}>
              {v.firstNameAr + "  " + v.lastNameAr}
            </b>
            {onlyTicket ? <Tag color="blue">Billet</Tag> : ""}{" "}
            {withoutVisa ? <Tag color="orange">sans visa</Tag> : ""}
            {withoutTicket ? <Tag color="violet">Sans Billet</Tag> : ""}
          </div>
        </b>
      ),
    },

    {
      value: "disponibility",
      name: "Vol",
      render: (c) =>
        c ? (
          <b
            style={{
              display: "flex",
              alignItems: "center",
              padding: "3px",

              borderRadius: "5px",
            }}
          >
            <img
              alt=""
              style={{ width: "30px", height: "30px", margin: "0 6px" }}
              src={c.airline ? c.airline.logo : ""}
            ></img>
            <b>
              {" "}
              <div style={{ color: "#232323" }}>
                {moment(c.date).format("DD MMM YYYY")}
              </div>
              <b style={{ color: "#888", fontWeight: "lighter" }}>
                {" "}
                {c.airline ? c.airline.name : ""}
              </b>
            </b>
          </b>
        ) : (
          <b>-</b>
        ),
    },

    {
      value: "client",
      name: "Client B2B",
      render: (v) => <a>{v ? v.name.toUpperCase() : ""}</a>,
    },
  ];

  useEffect(() => {
    setchangeRawdhaModel((prev) => {
      return { ...prev, orders: checkeds };
    });
  }, [checkeds.length]);

  return (
    <div>
      <div>
        <Filter search={() => _fetch()}>
          <Responsive m={3} l={2.4} xl={2.4} className="p-10">
            <label>Recherche: </label>
            <Input
              placeholder="recherche"
              onChange={(q) => {
                setfilterModel((prev) => {
                  return { ...prev, q };
                });
              }}
            />
          </Responsive>
          <Responsive m={3} l={2.4} xl={2.4} className="p-10">
            <label>Rawdha: </label>
            <SelectPicker
              data={[{ label: "Tout", value: 0 }].concat(
                data2.map((c) => {
                  return { label: c.name, value: c.id };
                })
              )}
              block
              noSearch
              value={filterModel.rawdhaId}
              onSelect={(rawdhaId) => {
                setfilterModel((prev) => {
                  return { ...prev, rawdhaId };
                });
              }}
            />
          </Responsive>
          <Responsive m={3} l={2.4} xl={2.4} className="p-10">
            <label>Client: </label>
            <SelectPicker
              onSearch={(q) => fetchClients(q)}
              data={[{ label: "Tout", value: 0 }].concat(
                clients.map((c) => {
                  return { label: c.name, value: c.id };
                })
              )}
              block
              noSearch
              value={filterModel.clientId}
              onSelect={(clientId) => {
                setfilterModel((prev) => {
                  return { ...prev, clientId };
                });
              }}
            />
          </Responsive>

          <Responsive m={3} l={2.4} xl={2.4} className="p-10">
            <label>Nom Omrah: </label>
            <SelectPicker
              onSearch={fetchPackages}
              data={
                packages
                  ? [{ label: "Tout", value: 0 }].concat(
                      packages.map((el) => {
                        return { label: el.name, value: el.id };
                      })
                    )
                  : [{ label: "Tout", value: 0 }]
              }
              block
              noSearch
              value={filterModel.packageUmrahId}
              onSelect={(packageUmrahId) => {
                setfilterModel((prev) => {
                  return { ...prev, packageUmrahId };
                });
              }}
            />
          </Responsive>
          <Responsive m={3} l={2.4} xl={2.4} className="p-10">
            <label>Vol: </label>
            <SelectPicker
              data={[{ label: "Tout", value: 0 }].concat(
                vols
                  ? vols.map((el) => {
                      return {
                        label: (
                          <div style={{ color: "#232323" }}>
                            {moment(el.date).format("DD MMM YYYY")}
                          </div>
                        ),
                        value: el.id,
                      };
                    })
                  : []
              )}
              block
              noSearch
              value={filterModel.disponibilityId}
              onSelect={(disponibilityId) => {
                setfilterModel((prev) => {
                  return { ...prev, disponibilityId };
                });
              }}
            />
          </Responsive>
        </Filter>

        <div
          onClick={(e) =>
            setcheckeds((prev) => (prev.length ? [] : data.map((el) => el.id)))
          }
          style={{
            display: "inline-block",
            padding: "8px",
            borderRadius: "4px",
          }}
        >
          <Checkbox checked={checkeds.length > 0}></Checkbox> Sélectionner Tout
        </div>
        <div className="p-10">
          <Responsive s={6} m={6} l={4} xl={3}>
            <SelectPicker
              data={[{ label: "Tout", value: 0 }].concat(
                data2.map((c) => {
                  return { label: c.designation, value: c.id };
                })
              )}
              block
              noSearch
              value={changeRawdhaModel.rawdhaId}
              onSelect={(rawdhaId) => {
                setchangeRawdhaModel((prev) => {
                  return { ...prev, rawdhaId };
                });
              }}
            />
          </Responsive>{" "}
          <Button
            appearance="primary"
            color="blue"
            onClick={() => {
              APi.createAPIEndpoint(ENDPOINTS.Order + "/changeRawdha")
                .create(changeRawdhaModel)
                .then((res) => {
                  _fetch();
                  alert("success");
                });
            }}
          >
            changer
          </Button>
        </div>
        <Grid columns={columns} rows={data} />
        <div style={{ padding: 20, background: "#fff" }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={totalCount}
            limitOptions={[10, 20, 50, 100, 200, 250, 500, 1000]}
            limit={filterModel.take}
            activePage={filterModel.page}
            onChangePage={(page) => {
              window.scrollTo(0, 0);
              setfilterModel((prev) => {
                return { ...prev, page };
              });
            }}
            onChangeLimit={(take) => {
              console.log(take);
              setfilterModel((prev) => {
                return { ...prev, take, page: 1 };
              });
            }}
          />
        </div>
      </div>{" "}
    </div>
  );
}
