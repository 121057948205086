import React, { useEffect, useState } from "react";
import { Button, Modal, Tag } from "rsuite";
import Responsive from "../../Components/Responsive";
import { ticketType } from "../../Constants/types";
import { IoIosAirplane } from "react-icons/io";
import airlines from "../../Data/airlines.json";
import TicketModel, {
  fake_details,
  TicketDetailsModel,
} from "../../Models/Ticket.model";
import moment from "moment";
import { Fly } from "./fly";

function OverView({ _model }) {
  const [model, setmodel] = useState({});
  useEffect(() => {
    setmodel((prev) => _model);
  }, [_model.id]);

  return !model.id ? (
    <>... loading </>
  ) : (
    <div>
      <Responsive
        l={5.5}
        margin="5px 2%"
        xl={5.5}
        className="p-5"
        style={{
          // border: "1px solid #eee",
          borderRadius: "5px",
          // boxShadow: `0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
          //   0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
          //   0 0.25rem 0.53125rem rgb(4 9 20 / 5%),
          //   0 0.125rem 0.1875rem rgb(4 9 20 / 3%)`,
          // background: "rgb(70,103,209,0.1)",
        }}
      >
        <div>
          <strong style={{ color: "rgb(70,103,209,1)" }}>N°Ticket : </strong>{" "}
          {model.ticket.ticketNumber}{" "}
        </div>
        <div>
          <strong style={{ color: "rgb(70,103,209,1)" }}>PNR : </strong>{" "}
          {model.ticket.pnr}{" "}
        </div>
        <div>
          <strong style={{ color: "rgb(70,103,209,1)" }}>Statut : </strong>{" "}
          {
            <Tag
              color={
                model.ticket.tranc == "EMDS"
                  ? "blue"
                  : model.ticket.tranc == "RFND"
                  ? "green"
                  : model.ticket.tranc == "AMDA"
                  ? "violet"
                  : model.ticket.tranc == "CANX"
                  ? "black"
                  : "yellow"
              }
            >
              {model.ticket.tranc}
            </Tag>
          }{" "}
        </div>
        <div>
          <strong style={{ color: "rgb(70,103,209,1)" }}>
            Date d'emission :{" "}
          </strong>{" "}
          {moment(model.ticket.doEmmission).format("DD MMM YYYY")}{" "}
        </div>
        <div>
          <strong style={{ color: "rgb(70,103,209,1)" }}>
            Date de remboursement :{" "}
          </strong>{" "}
          {model.ticket.refundDate
            ? moment(model.ticket.refundDate).format("DD MMM YYYY")
            : " - "}{" "}
        </div>
        {/* <div>
          <strong>Nom du fichier AIR : </strong> {ticket.docName}
        </div> */}

        <hr></hr>
        <div>
          <strong style={{ color: "rgb(70,103,209,1)" }}>
            Nom Voyageur :{" "}
          </strong>
          {model.customer.name}{" "}
        </div>
        {/* <div>
          <strong>Type de Voyageur : </strong>
          {ticket.paxType}{" "}
        </div> */}
      </Responsive>
      <Responsive
        margin="5px 2%"
        l={5.5}
        xl={5.5}
        className="p-5"
        style={{
          // border: "1px solid #eee",
          borderRadius: "5px",
          // boxShadow: `0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
          //   0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
          //   0 0.25rem 0.53125rem rgb(4 9 20 / 5%),
          //   0 0.125rem 0.1875rem rgb(4 9 20 / 3%)`,
          // background: "rgb(70,103,209,0.1)",
        }}
      >
        <h4>Détails Du Prix </h4>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <strong style={{ color: "rgb(70,103,209,1)" }}>Fare : </strong>
          <b>{model.ticket.fare}</b>
        </div>{" "}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <strong style={{ color: "rgb(70,103,209,1)" }}>EMD : </strong>
          <b> {model.ticket.emd}</b>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <strong style={{ color: "rgb(70,103,209,1)" }}>Taxes : </strong>
          <b> {model.ticket.tax}</b>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderTop: "1px dashed #eee",
            marginTop: "5px",
          }}
        >
          <strong style={{ color: "rgb(70,103,209,1)" }}>
            Prix De Billet :{" "}
          </strong>
          <b> {model.fare}</b>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <strong style={{ color: "rgb(70,103,209,1)" }}>Commission : </strong>
          <b>{model.ticket.commission}</b>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderTop: "1px dashed #eee",
            marginTop: "17px",
          }}
        >
          <strong>Prix De Vente : </strong>
          <b style={{ color: "#53af50", fontSize: "18px" }}>
            {model.totalPrice.toFixed(3) + " "}
            <span
              style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}
            >
              {model.currency ? model.currency.code : "TND"}
            </span>
          </b>
        </div>
      </Responsive>
      {/* <div
        style={{
          margin: "10px 0",
          padding: "10px",
        }}
      >
        {model.ticket.segments &&
          model.ticket.segments.map((seg, i) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "rgba(246,137,51,0.2)",
                padding: "10px",
                border: "1px dashed rgba(246,137,51,1)",
              }}
              key={i}
            >
              <div>
                <div>
                  <strong>{seg.departAirport.name}</strong>
                </div>{" "}
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  <img
                    style={{ width: "30px" }}
                    src={"/countries/" + seg.departAirport.country.logo}
                  />
                  <span>{seg.departAirport.city}</span>
                </div>
              </div>
              <div style={{ textAlign: "center", flexGrow: 2 }}>
                <IoIosAirplane size={"30px"} />
              </div>
              <div>
                <div>
                  <strong>{seg.destinationAirport.name}</strong>
                </div>
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  <img
                    style={{ width: "30px" }}
                    src={"/countries/" + seg.destinationAirport.country.logo}
                  />
                  <span>{seg.destinationAirport.city}</span>
                </div>
              </div>
            </div>
          ))}
      </div> */}
      <Fly
        airlineCode={model.ticket.airlineCode}
        segments={model.ticket.segments}
        AgentName={model.ticket.agent ? model.ticket.agent.name : ""}
      />
    </div>
  );
}
const OverViewWrapper = ({ size, open, handleClose, model }) => {
  return (
    <Modal
      size={size ? size : "lg"}
      overflow={false}
      open={open}
      onClose={handleClose}
    >
      <Modal.Header>
        <Modal.Title>Détails Ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <OverView _model={model} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} appearance="subtle">
          fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default OverViewWrapper;
