import { useRecoilValue } from "recoil";
import TrashIcon from "@rsuite/icons/Trash";
import Responsive from "../../../Components/Responsive";
import { IconButton, Input, SelectPicker } from "rsuite";
import { Airlines } from "../../../Atoms/airlines.atom";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import { useEffect } from "react";
import { DisponibilityModel } from "../../../Models/packageomra.model";
import TransfertDisponibilties from "./transfert/Transfert";
import DepartureFlights from "./departureFlights/departureFlights";

const Disponibilities = ({ model, setModel, isUmrah }) => {
  const airlines = useRecoilValue(Airlines);

  useEffect(() => {
    setModel((prev) => {
      const sortedDisponibilities = [...prev.disponibilities].sort((a, b) => {
        if (a && !b) return -1;
        return new Date(a.date) >= new Date(b.date) ? 1 : -1;
      });
      return { ...prev, disponibilities: sortedDisponibilities };
    });
  }, [model.id]);

  const updateDisponibility = (index, updatedFields) => {
    setModel((prev) => ({
      ...prev,
      disponibilities: prev.disponibilities.map((disponibility, idx) => {
        if (idx === index) {
          const updatedFlights = disponibility.flights.map((flight) => ({
            ...flight,
            airlineId: updatedFields.airlineId,
          }));

          return {
            ...disponibility,
            ...updatedFields,
            flights: updatedFlights,
          };
        }
        return disponibility;
      }),
    }));
  };

  const handleCostChange = (cost) => {
    const costElement = model.packageUmrahCosts.find(
      (el) => el.designation.toUpperCase() === "VOL"
    );
    if (costElement) {
      const index = model.packageUmrahCosts.indexOf(costElement);
      setModel((prev) => {
        const updatedCosts = [...prev.packageUmrahCosts];
        updatedCosts[index] = {
          ...updatedCosts[index],
          cost: parseFloat(cost),
        };
        return { ...prev, packageUmrahCosts: updatedCosts };
      });
    }
  };

  const addDisponibility = () => {
    setModel((prev) => ({
      ...prev,
      disponibilities: [...prev.disponibilities, new DisponibilityModel()],
    }));
  };

  const removeDisponibility = (index) => {
    setModel((prev) => ({
      ...prev,
      disponibilities: prev.disponibilities.filter((_, i) => i !== index),
    }));
  };

  return (
    <>
      <h6>Vols</h6>
      {isUmrah && (
        <div>
          <label>Tarif Vol</label>
          {model.packageUmrahCosts.find(
            (el) => el.designation.toUpperCase() === "VOL"
          ) && (
            <Input
              value={
                model.packageUmrahCosts.find(
                  (el) => el.designation.toUpperCase() === "VOL"
                ).cost || 0
              }
              onChange={(cost) => handleCostChange(cost)}
            />
          )}
        </div>
      )}
      {(!model.disponibilities || !model.disponibilities.length) && (
        <div>
          <IconButton
            onClick={addDisponibility}
            color="violet"
            icon={<PlusRoundIcon />}
          >
            ajouter
          </IconButton>
        </div>
      )}
      {model.disponibilities.map((d, i) => (
        <div key={i}>
          <Responsive className="p-10" m={6} l={2} xl={1.5}>
            <label>Date:</label>
            <Input
              type="date"
              value={
                typeof d.date === "string" ? d.date.substring(0, 10) : d.date
              }
              onChange={(date) => updateDisponibility(i, { date })}
            />
          </Responsive>
          <Responsive className="p-10" m={6} l={2} xl={1.5}>
            <label>Date de retour:</label>
            <Input
              min={d.date}
              type="date"
              value={
                typeof d.returnDate === "string"
                  ? d.returnDate.substring(0, 10)
                  : d.returnDate
              }
              onChange={(returnDate) => updateDisponibility(i, { returnDate })}
            />
          </Responsive>

          <Responsive s={2} m={1} l={0.5} xl={0.5} className="p-10">
            {d.airlineId && (
              <img
                alt="Airline logo"
                style={{ width: "50px" }}
                src={airlines.find((ai) => ai.id === d.airlineId)?.logo}
              />
            )}
          </Responsive>

          <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
            <label>Airline:</label>
            <SelectPicker
              data={airlines.map((c) => ({ label: c.name, value: c.id }))}
              block
              value={d.airlineId}
              onSelect={(airlineId) => updateDisponibility(i, { airlineId })}
            />
          </Responsive>
          <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
            <label>Nombre:</label>
            <Input
              type="number"
              step="1"
              value={d.onStock}
              onChange={(onStock) =>
                updateDisponibility(i, { onStock: parseInt(onStock) })
              }
            />
          </Responsive>
          <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
            <label>Cout:</label>
            <Input
              type="number"
              step="0.1"
              value={d.cost}
              onChange={(cost) =>
                updateDisponibility(i, { cost: parseFloat(cost) })
              }
            />
          </Responsive>
          <Responsive s={3} m={2} l={1.5} xl={1.5} className="p-10">
            <label>Commission:</label>
            <Input
              type="number"
              step="1"
              value={d.commission}
              onChange={(commission) =>
                updateDisponibility(i, { commission: parseFloat(commission) })
              }
            />
          </Responsive>
          <Responsive s={3} m={2} l={1.5} xl={1.5} className="p-10">
            <label>Commission B2B:</label>
            <Input
              type="number"
              step="1"
              value={d.b2BCommission || 0}
              onChange={(b2BCommission) =>
                updateDisponibility(i, {
                  b2BCommission: parseFloat(b2BCommission),
                })
              }
            />
          </Responsive>

          <TransfertDisponibilties
            setModel={setModel}
            disponibilitie={d}
            index={i}
          />

          <DepartureFlights
            model={model}
            setModel={setModel}
            disponibilitie={d}
            index={i}
          />

          <Responsive s={2} m={1.6} l={1} xl={1}>
            <div style={{ height: "42px" }}></div>
            <IconButton
              onClick={() => removeDisponibility(i)}
              color="violet"
              icon={<TrashIcon />}
            />
            {i + 1 === model.disponibilities.length && (
              <IconButton
                onClick={addDisponibility}
                color="violet"
                icon={<PlusRoundIcon />}
              />
            )}
          </Responsive>

          <hr />
        </div>
      ))}
    </>
  );
};

export default Disponibilities;
