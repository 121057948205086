import { Input, SelectPicker } from "rsuite";
import Responsive from "../../../Components/Responsive";
import format_number from "../../../Helpers/number_formatter";
import styles from "./styles/informationsStyles";

const ExtratsForm = ({
  model,
  fetchProviders,
  providers,
  handleUpdateExtrat,
  getCurrencyOptions,
  handleCurrencySelect,
  handleProviderSelect,
  exchange,
  providerId,
  currencyId,
}) => {
  return (
    <div style={styles.extratsMainContainer}>
      <h6>Extrats</h6>
      <div style={styles.conatainer}>
        <Responsive className="p-10" m={4} l={3} xl={2.5}>
          <label>Fournisseur:</label>
          <SelectPicker
            onSearch={fetchProviders}
            data={providers.map((p) => ({ label: p.name, value: p.id }))}
            block
            noSearch
            value={providerId}
            onSelect={handleProviderSelect}
          />
        </Responsive>

        <Responsive s={3} m={3} l={2.5} xl={2} className="p-10">
          <label>Devise:</label>
          <SelectPicker
            data={getCurrencyOptions()}
            block
            noSearch
            value={currencyId}
            onSelect={handleCurrencySelect}
          />
        </Responsive>

        <Responsive s={3} m={3} l={1.5} xl={1} className="p-10">
          <label>Cour:</label>
          <Input type="text" value={exchange} disabled />
        </Responsive>
      </div>

      {model.extrats.map((extrat, i) => (
        <div key={i} className="fee-entry" style={styles.conatainer}>
          <Responsive m={4} l={3} xl={3} className="p-10">
            <label>Désignation:</label>
            <Input
              value={extrat.designation}
              onChange={(value) => handleUpdateExtrat(i, "designation", value)}
              disabled
            />
          </Responsive>

          <Responsive s={4} m={4} l={1.5} xl={1.5} className="p-10">
            <label>Coût:</label>
            <Input
              type="number"
              step="1"
              value={extrat.cost}
              onChange={(value) => handleUpdateExtrat(i, "cost", value)}
            />
          </Responsive>

          <Responsive s={4} m={4} l={1.3} xl={1.3} className="p-10">
            <label>Coût TND:</label>
            <Input
              type="text"
              value={format_number(extrat.cost * extrat.exchange)}
              disabled
            />
          </Responsive>
        </div>
      ))}
    </div>
  );
};

export default ExtratsForm;
