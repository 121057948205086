import RowOfTable from "./row";

const HotelPricesTable = ({ model, data, setModel, grouppedBy }) => {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>-</th>
            <th>Designation</th>
            <th>Commission</th>
            <th>QUAD</th>
            <th>TRIPLE</th>
            <th>DOUBLE</th>
          </tr>
        </thead>
        <tbody>
          {model.destinations &&
            model.destinations.length !== 0 &&
            data &&
            Object.keys(data).map((key, _j) => (
              <RowOfTable
                key={key}
                index={_j}
                model={model}
                data={data}
                keyName={key}
                setModel={setModel}
                grouppedBy={grouppedBy}
              />
            ))}
        </tbody>
      </table>

      <style jsx>{`
        thead {
          background: rgb(70, 103, 209);
          color: #fff;
        }
        th,
        td {
          padding: 5px 8px;
        }
        th {
          text-align: left;
        }
      `}</style>
    </div>
  );
};

export default HotelPricesTable;
