import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Divider, Message, Steps, Loader } from "rsuite";
import { CurrencyAtom } from "../../Atoms/currency.atom";
import { ExchangeAtom } from "../../Atoms/exchange.atom";
import { PackageUmrahAtom } from "../../Atoms/packageUmrah.atom";
import Disponibilities from "./disponibilitie/Disponibilities";
import "../../style/packageUmrah/hotelMakkah/hotelMakkah.css";
import Informations from "./informations/informations";
import Price from "./prices/price";
import { APi } from "../../Api";
import { hotelAtoms } from "../../Atoms/hotel.atom";
import Attachments from "../Packages/Attachments/Attachments.component";
import { PackageUmrahCost } from "../../Models/packageomra.model";
import { stepsTitles } from "../../Constants/types";
import Destination from "./destination/destination";
import ProgramPackage from "./program/program";
import styles from "./styles/AddEditPackageUmrah.componentStyles";

function AddEditPackageUmrah({
  error,
  providers,
  save,
  fetchProviders,
  isDefault = false,
  loading,
  isLoading,
}) {
  const currencies = useRecoilValue(CurrencyAtom);
  const exchanges = useRecoilValue(ExchangeAtom);
  const [hotelData, setHotelData] = useRecoilState(hotelAtoms);
  const [excursionData, setExcursionData] = useState([]);
  const [model, _setmodel] = useRecoilState(PackageUmrahAtom);
  const [state, setState] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [costModel, setCostModel] = useState(new PackageUmrahCost());
  const [paysData, setPaysData] = useState([]);
  const [programsData, setProgramsData] = useState([]);

  const fetchHotels = useCallback(() => {
    APi.createAPIEndpoint(APi.ENDPOINTS.HotelList + "/HotelsByCity", {
      city: "Saudi Arabia",
    })
      .customGet()
      .then((res) => {
        setHotelData(res.data.hotels);
      });
  }, []);

  useEffect(() => {
    fetchHotels();
  }, [fetchHotels]);

  useEffect(() => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Excursion + "/getAll", {
      find: "Umrah",
    })
      .customGet()
      .then((res) => {
        setExcursionData(res.data.excursions);
      });
  }, []);

  const fetchPays = useCallback(() => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Pays, {})
      .customGet()
      .then((res) => setPaysData(res.data));
  }, []);

  useEffect(() => {
    fetchPays();
  }, [fetchPays]);

  const nbrDay = useMemo(
    () => model.destinations?.reduce((sum, dest) => sum + dest.nbrDay, 0) || 0,
    [model.destinations]
  );

  const fetchPrograms = useCallback(() => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Programs + "/getAll", {
      isUmrah: true,
      maxDay: nbrDay,
    })
      .customGet()
      .then((res) => {
        setProgramsData(res.data.programs);
      });
  }, [nbrDay]);

  useEffect(() => {
    fetchPrograms();
  }, [fetchPrograms]);

  const stepsContent = [
    <Informations
      model={model}
      setModel={_setmodel}
      setCostModel={setCostModel}
      costModel={costModel}
      fetchProviders={fetchProviders}
      exchanges={exchanges}
      currencies={currencies}
      providers={providers}
      isUmrah={true}
    />,
    <Disponibilities model={model} setModel={_setmodel} isUmrah={true} />,
    <Destination
      title="Destination et Hôtels"
      providers={providers}
      currencies={currencies}
      exchanges={exchanges}
      fetchProviders={fetchProviders}
      setModel={_setmodel}
      model={model}
      fetchPays={fetchPays}
      fetchHotels={fetchHotels}
      hotelData={hotelData || []}
      paysData={paysData || []}
      excursionData={excursionData || []}
      isUmrah={true}
    />,
    <ProgramPackage
      setModel={_setmodel}
      programs={programsData || []}
      model={model}
      fetchPrograms={fetchPrograms}
    />,
    <Price model={model} setModel={_setmodel} isUmrah={true} />,
    <Attachments
      model={model}
      setModel={_setmodel}
      state={state}
      setState={setState}
    />,
  ];

  return (
    <>
      <div style={styles.mainConatiner}>
        <Steps current={currentStep} style={styles.steps}>
          {stepsTitles.map((title, index) => (
            <Steps.Item
              key={index}
              title={title}
              onClick={() => setCurrentStep(index)}
            />
          ))}
        </Steps>

        {isLoading ? (
          <div style={styles.loader}>
            <Loader size="lg" />
          </div>
        ) : (
          stepsContent[currentStep]
        )}
      </div>

      {isDefault && (
        <div style={styles.button}>
          <button onClick={save}>
            {loading ? <Loader size="sm" /> : "Enregistrer"}
          </button>
        </div>
      )}
      <br />
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
      <Divider />
    </>
  );
}

export default AddEditPackageUmrah;
