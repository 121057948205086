import moment from "moment";
import format_number from "../../Helpers/number_formatter";
import {
  BestInKounouz,
  ConfirmationStatus,
  UmrahState,
  branchTreatment,
  generalLabels,
  goodBadResponse,
  guide,
  heardOf,
} from "../../Constants/types";

export const multiDataSet = (data) => {
  if (data && data.length)
    return [
      {
        columns: [
          { title: "Pélérin", width: { wpx: 200 } },
          { title: "Numéro de passeport", width: { wpx: 120 } },

          { title: "Voyage", width: { wpx: 120 } },
          { title: "Vol", width: { wpx: 120 } },
          { title: "Client B2B", width: { wpx: 120 } },
          { title: "Arrangement", width: { wpx: 130 } },

          { title: "Hotel Makkah", width: { wpx: 150 } },
          { title: "Hotel Madinah", width: { wpx: 150 } },

          // { title: "Method of Hearing?", width: { wpx: 100 } },
          // { title: "Is First Time?", width: { wpx: 100 } },

          // { title: "How was your umrah?", width: { wpx: 100 } },
          // { title: "Branch Review", width: { wpx: 100 } },
          // { title: "Guide Review", width: { wpx: 100 } },
          // { title: "Airline Review", width: { wpx: 100 } },
          // { title: "Hotel Madinah Review", width: { wpx: 100 } },
          // { title: "Madinah Services Review", width: { wpx: 100 } },
          // { title: "Hotel Makkah Review", width: { wpx: 100 } },
          // { title: "Makkah Services Review", width: { wpx: 100 } },

          // { title: "Best Of", width: { wpx: 100 } },
          // { title: "Would you recommend us?", width: { wpx: 100 } },
          // { title: "Note", width: { wpx: 300 } },

          { title: "Status", width: { wpx: 160 } },

          { title: "Prix total", width: { wpx: 100 } }, //pixels width
        ],
        data: data.map((item) => {
          let item2 = [
            {
              value: item.customer.name,
              style: {
                fill: { fgColor: { rgb: "eeeeee" } },
                font: { sz: "12", color: { rgb: "5a2c3e" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.customer.passportNumber,
              style: {
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.packageUmrah ? item.packageUmrah.name : "-",
              style: {
                fill: { fgColor: { rgb: "eeeeee" } },
                font: { sz: "13", color: { rgb: "2590b5" } },
                alignment: { horizontal: "center" },
              },
            },

            {
              value: item.disponibility
                ? moment(item.disponibility.date).format("DD MMM") +
                  (item.disponibility.airline
                    ? item.disponibility.airline.name
                    : "")
                : "-",
              style: {
                font: { sz: "13", color: { rgb: "5a2c3e" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.client ? item.client.name : "-",
              style: {
                font: { sz: "13", color: { rgb: "5a2c3e" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.price
                ? item.price.arrangementMadinahDesignation
                : "-",
              style: {
                fill: { fgColor: { rgb: "eeeeee" } },
                font: {
                  sz: "13",
                  color: {
                    rgb: item.price
                      ? item.price.arrangementMadinahDesignation ==
                        "Chambre triple"
                        ? "2f1a4c"
                        : item.price.arrangementMadinahDesignation ==
                          "Chambre double"
                        ? "ecb61c"
                        : "5a2c3e"
                      : "5a2c3e",
                  },
                },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.price ? item.price.hotelMakkahName : "-",
              style: {
                font: {
                  sz: "13",
                  color: {
                    rgb: "777777",
                  },
                },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.price ? item.price.hotelMadinahName : "-",
              style: {
                fill: { fgColor: { rgb: "eeeeee" } },
                font: {
                  sz: "13",
                  color: {
                    rgb: "888888",
                  },
                },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: ConfirmationStatus.find(
                (el) =>
                  item.confirmationStatus === null ||
                  el.value === item.confirmationStatus
              )
                ? ConfirmationStatus.find(
                    (el) =>
                      item.confirmationStatus === null ||
                      el.value === item.confirmationStatus
                  ).label
                : ConfirmationStatus.find((el) => el.value === 1).label,
              style: {
                font: {
                  sz: "13",
                  color: {
                    rgb:
                      item.confirmationStatus === 2
                        ? "663399"
                        : item.confirmationStatus == 3
                        ? "0b9666"
                        : item.confirmationStatus == 4
                        ? "b61955"
                        : "e68019",
                  },
                },
                alignment: { horizontal: "center" },
              },
            },

            {
              value: format_number(item.totalPrice),
              style: {
                fill: { fgColor: { rgb: "eeeeee" } },

                font: { sz: "13", color: { rgb: "2f1a4c" } },
                alignment: { horizontal: "center" },
              },
            },
            // {
            //   value: item.customer.survey
            //     ? heardOf.find(
            //         (el) =>
            //           el.value == item.customer.survey.methodOfHearingAbout
            //       )
            //       ? heardOf.find(
            //           (el) =>
            //             el.value == item.customer.survey.methodOfHearingAbout
            //         ).label
            //       : ""
            //     : "-",
            //   style: {
            //     fill: { fgColor: { rgb: "ffffff" } },
            //     font: {
            //       sz: "13",
            //       color: {
            //         rgb: "888888",
            //       },
            //     },
            //     alignment: { horizontal: "center" },
            //   },
            // },
            // {
            //   value: item.customer.survey && item.customer.survey.isFirstTime,
            //   style: {
            //     fill: { fgColor: { rgb: "eeeeee" } },
            //     font: {
            //       sz: "13",
            //       color: {
            //         rgb: "888888",
            //       },
            //     },
            //     alignment: { horizontal: "center" },
            //   },
            // },

            // {
            //   value: item.customer.survey
            //     ? UmrahState.find(
            //         (el) => el.value == item.customer.survey.generalReview
            //       )
            //       ? UmrahState.find(
            //           (el) => el.value == item.customer.survey.generalReview
            //         ).label
            //       : ""
            //     : "-",
            //   style: {
            //     fill: { fgColor: { rgb: "ffffff" } },
            //     font: {
            //       sz: "13",
            //       color: {
            //         rgb: "888888",
            //       },
            //     },
            //     alignment: { horizontal: "center" },
            //   },
            // },
            // {
            //   value: item.customer.survey
            //     ? branchTreatment.find(
            //         (el) => el.value == item.customer.survey.branchReview
            //       )
            //       ? branchTreatment.find(
            //           (el) => el.value == item.customer.survey.branchReview
            //         ).label
            //       : ""
            //     : "-",
            //   style: {
            //     fill: { fgColor: { rgb: "ffffff" } },
            //     font: {
            //       sz: "13",
            //       color: {
            //         rgb: "888888",
            //       },
            //     },
            //     alignment: { horizontal: "center" },
            //   },
            // },

            // {
            //   value: item.customer.survey
            //     ? guide.find(
            //         (el) => el.value == item.customer.survey.guideReview
            //       )
            //       ? guide.find(
            //           (el) => el.value == item.customer.survey.guideReview
            //         ).label
            //       : ""
            //     : "-",
            //   style: {
            //     fill: { fgColor: { rgb: "ffffff" } },
            //     font: {
            //       sz: "13",
            //       color: {
            //         rgb: "888888",
            //       },
            //     },
            //     alignment: { horizontal: "center" },
            //   },
            // },

            // {
            //   value: item.customer.survey
            //     ? goodBadResponse.find(
            //         (el) => el.value == item.customer.survey.airlineReview
            //       )
            //       ? goodBadResponse.find(
            //           (el) => el.value == item.customer.survey.airlineReview
            //         ).label
            //       : ""
            //     : "-",
            //   style: {
            //     fill: { fgColor: { rgb: "ffffff" } },
            //     font: {
            //       sz: "13",
            //       color: {
            //         rgb: "888888",
            //       },
            //     },
            //     alignment: { horizontal: "center" },
            //   },
            // },
            // {
            //   value: item.customer.survey
            //     ? goodBadResponse.find(
            //         (el) => el.value == item.customer.survey.madinahHotelReview
            //       )
            //       ? goodBadResponse.find(
            //           (el) =>
            //             el.value == item.customer.survey.madinahHotelReview
            //         ).label
            //       : ""
            //     : "-",
            //   style: {
            //     fill: { fgColor: { rgb: "ffffff" } },
            //     font: {
            //       sz: "13",
            //       color: {
            //         rgb: "888888",
            //       },
            //     },
            //     alignment: { horizontal: "center" },
            //   },
            // },
            // {
            //   value: item.customer.survey
            //     ? goodBadResponse.find(
            //         (el) =>
            //           el.value == item.customer.survey.madinahServiceReview
            //       )
            //       ? goodBadResponse.find(
            //           (el) =>
            //             el.value == item.customer.survey.madinahServiceReview
            //         ).label
            //       : ""
            //     : "-",
            //   style: {
            //     fill: { fgColor: { rgb: "ffffff" } },
            //     font: {
            //       sz: "13",
            //       color: {
            //         rgb: "888888",
            //       },
            //     },
            //     alignment: { horizontal: "center" },
            //   },
            // },
            // {
            //   value: item.customer.survey
            //     ? goodBadResponse.find(
            //         (el) => el.value == item.customer.survey.makkahHotelReview
            //       )
            //       ? goodBadResponse.find(
            //           (el) => el.value == item.customer.survey.makkahHotelReview
            //         ).label
            //       : ""
            //     : "-",
            //   style: {
            //     fill: { fgColor: { rgb: "ffffff" } },
            //     font: {
            //       sz: "13",
            //       color: {
            //         rgb: "888888",
            //       },
            //     },
            //     alignment: { horizontal: "center" },
            //   },
            // },
            // {
            //   value: item.customer.survey
            //     ? goodBadResponse.find(
            //         (el) => el.value == item.customer.survey.makkahServiceReview
            //       )
            //       ? goodBadResponse.find(
            //           (el) =>
            //             el.value == item.customer.survey.makkahServiceReview
            //         ).label
            //       : ""
            //     : "-",
            //   style: {
            //     fill: { fgColor: { rgb: "ffffff" } },
            //     font: {
            //       sz: "13",
            //       color: {
            //         rgb: "888888",
            //       },
            //     },
            //     alignment: { horizontal: "center" },
            //   },
            // },
            // {
            //   value: item.customer.survey
            //     ? BestInKounouz.find(
            //         (el) => el.value == item.customer.survey.bestOf
            //       )
            //       ? BestInKounouz.find(
            //           (el) => el.value == item.customer.survey.bestOf
            //         ).label
            //       : ""
            //     : "-",
            //   style: {
            //     fill: { fgColor: { rgb: "ffffff" } },
            //     font: {
            //       sz: "13",
            //       color: {
            //         rgb: "888888",
            //       },
            //     },
            //     alignment: { horizontal: "center" },
            //   },
            // },
            // {
            //   value: item.customer.survey && item.customer.survey.isRecommended,
            //   style: {
            //     fill: { fgColor: { rgb: "ffffff" } },
            //     font: {
            //       sz: "13",
            //       color: {
            //         rgb: "888888",
            //       },
            //     },
            //     alignment: { horizontal: "center" },
            //   },
            // },
            // {
            //   value: item.customer.survey
            //     ? item.customer.survey.note || ""
            //     : "",
            //   style: {
            //     fill: { fgColor: { rgb: "ffffff" } },
            //     font: {
            //       sz: "13",
            //       color: {
            //         rgb: "222222",
            //       },
            //     },
            //     alignment: { horizontal: "center" },
            //   },
            // },
          ];
          return item2;
        }),
      },
    ];
};
