const extrats = [
  {
    id: 0,
    packageUmrahId: 0,
    providerId: 0,
    designation: "Kaaba View",
    cost: 200,
    exchange: 1,
    currencyId: 0,
  },
  {
    id: 0,
    packageUmrahId: 0,
    providerId: 0,
    designation: "Haram View",
    cost: 180,
    exchange: 1,
    currencyId: 0,
  },
  {
    id: 0,
    packageUmrahId: 0,
    providerId: 0,
    designation: "City View",
    cost: 150,
    exchange: 1,
    currencyId: 0,
  },
  {
    id: 0,
    packageUmrahId: 0,
    providerId: 0,
    designation: "Madinah View",
    cost: 190,
    exchange: 1,
    currencyId: 0,
  },
  {
    id: 0,
    packageUmrahId: 0,
    providerId: 0,
    designation: "Equipements pour les personnes handicapées",
    cost: 150,
    exchange: 1,
    currencyId: 0,
  },
  {
    id: 0,
    packageUmrahId: 0,
    providerId: 0,
    designation: "Service d'étage",
    cost: 100,
    exchange: 1,
    currencyId: 0,
  },
  {
    id: 0,
    packageUmrahId: 0,
    providerId: 0,
    designation: "Navette aéroport",
    cost: 75,
    exchange: 1,
    currencyId: 0,
  },
  {
    id: 0,
    packageUmrahId: 0,
    providerId: 0,
    designation: "Excursion",
    cost: 200,
    exchange: 1,
    currencyId: 0,
  },
];

export default extrats;
