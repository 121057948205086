const styles = {
  mainConatiner: {
    padding: "40px 10px",
  },
  steps: {
    verticalAlign: "top",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
  },
  button: {
    textAlign: "right",
  },
  message: {
    marginTop: "20px",
  },
};

export default styles;
