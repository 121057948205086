import axios from "axios";
//export const BASE_URL = "https://financeapi.kounouz.travel/";
// export const BASE_URL = "https://beobble.a2development.org/";
// export const BASE_URL = "https://localhost:5001/";
export const BASE_URL = process.env.REACT_APP_HOST_API;
//export const BASE_URL = "http://51.83.237.117:8080/";
export const AXIOS = axios.create({ baseURL: BASE_URL + "api/" });

export const API_KEY = "AIzaSyCRI2KNgo32guiF5X08xRJtMhe_rtJFRyA";

export const ALFRISCO =
  "http://51.83.237.117:8095/share/proxy/alfresco-noauth/api/internal/shared/node/";
