import { IconButton } from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import DepartureFlightList from "./departureFlightList";
import { FlightsModel } from "../../../../Models/packageomra.model";
import { useEffect, useMemo, useState } from "react";
import styles from "./styles/departureFlightsStyles";

const DepartureFlights = ({ model, setModel, disponibilitie, index }) => {
  const [flightsDepartListState, setFlightsDepartListState] = useState([]);
  const [flightsArrivListState, setFlightsArrivListState] = useState([]);

  useMemo(() => {
    if (disponibilitie.flights) {
      const initialFlights = disponibilitie.flights;
      setFlightsDepartListState(
        initialFlights.filter((flight) => flight.type === 0)
      );
      setFlightsArrivListState(
        initialFlights.filter((flight) => flight.type === 1)
      );
    }
  }, []);

  const addNewFlight = (type) => {
    const newFlightList = new FlightsModel(type, disponibilitie.airlineId);
    if (type === 0) {
      setFlightsDepartListState((prev) => [...prev, newFlightList]);
    } else {
      setFlightsArrivListState((prev) => [...prev, newFlightList]);
    }
  };

  const deleteFlight = (type, flightIndex) => {
    if (type === 0) {
      setFlightsDepartListState((prev) =>
        prev.filter((_, index) => index !== flightIndex)
      );
    } else if (type === 1) {
      setFlightsArrivListState((prev) =>
        prev.filter((_, index) => index !== flightIndex)
      );
    }
  };

  useEffect(() => {
    const combinedFlights = [
      ...flightsDepartListState,
      ...flightsArrivListState,
    ];
    setModel((prevModel) => ({
      ...prevModel,
      disponibilities: prevModel.disponibilities.map((item, idx) =>
        idx === index ? { ...item, flights: combinedFlights } : item
      ),
    }));
  }, [flightsDepartListState, flightsArrivListState]);

  return (
    <div>
      <div style={styles.section}>
        <h6>Vols de départ</h6>
        {flightsDepartListState.length === 0 && (
          <IconButton
            onClick={() => {
              addNewFlight(0);
            }}
            color="violet"
            icon={<PlusRoundIcon />}
          />
        )}
        <div style={styles.flightContainer}>
          {flightsDepartListState.map((flight, i) => (
            <div key={i} style={styles.flightRow}>
              <div style={styles.flightContent}>
                <DepartureFlightList
                  model={model}
                  setModel={setModel}
                  disponibilitie={disponibilitie}
                  index={i}
                  flight={flight}
                  type={0}
                  setList={setFlightsDepartListState}
                />
              </div>

              <div style={styles.buttonGroup}>
                <IconButton
                  onClick={() => {
                    deleteFlight(0, i);
                  }}
                  color="violet"
                  icon={<TrashIcon />}
                  style={styles.iconButton}
                />
                {i + 1 === flightsDepartListState.length && (
                  <IconButton
                    onClick={() => {
                      addNewFlight(0);
                    }}
                    color="violet"
                    icon={<PlusRoundIcon />}
                    style={styles.iconButton}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div>
        <h6>Vols retour</h6>
        {flightsArrivListState.length === 0 && (
          <IconButton
            onClick={() => {
              addNewFlight(1);
            }}
            color="violet"
            icon={<PlusRoundIcon />}
          />
        )}
        <div style={styles.flightContainer}>
          {flightsArrivListState.map((flight, i) => (
            <div key={i} style={styles.flightRow}>
              <div style={styles.flightContent}>
                <DepartureFlightList
                  model={model}
                  setModel={setModel}
                  disponibilitie={disponibilitie}
                  index={i}
                  flight={flight}
                  type={1}
                  setList={setFlightsArrivListState}
                />
              </div>

              <div style={styles.buttonGroup}>
                <IconButton
                  onClick={() => {
                    deleteFlight(1, i);
                  }}
                  color="violet"
                  icon={<TrashIcon />}
                  style={styles.iconButton}
                />
                {i + 1 === flightsArrivListState.length && (
                  <IconButton
                    onClick={() => {
                      addNewFlight(1);
                    }}
                    color="violet"
                    icon={<PlusRoundIcon />}
                    style={styles.iconButton}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DepartureFlights;
