import { useEffect } from "react";
import PricesPackageTable from "./table.js/table";

const PricesPackage = ({ model, setModel }) => {
  const generateCombinations = (destinations) => {
    const hotelLists = destinations.map((destination) =>
      destination.hotelsVo.map((hotel) => ({
        hotelListId: hotel.hotelListId,
        name: hotel.name,
      }))
    );

    const cartesianProduct = (arrays) =>
      arrays.reduce(
        (acc, array) =>
          acc.flatMap((item) => array.map((element) => [...item, element])),
        [[]]
      );

    return cartesianProduct(hotelLists);
  };

  const createPrices = () => {
    if (!model.destinations || model.destinations.length === 0) {
      return [];
    }

    const hotelCombinations = generateCombinations(model.destinations).filter(
      (combination) => combination.length > 0
    );

    if (hotelCombinations.length === 0) {
      return [];
    }

    return hotelCombinations.map((combination) => {
      const hotelNames = combination.map((hotel) => hotel.name).join(" - ");
      const hotelIds = combination.map((hotel) => hotel.hotelListId);

      return {
        name: hotelNames,
        price: 0,
        typeTraveler: "",
        arrangementName: "",
        hotelListIds: hotelIds,
        costs: [],
      };
    });
  };

  useEffect(() => {
    if (!model.prices || model.prices.length === 0) {
      const initialPrices = createPrices();
      setModel((prev) => ({ ...prev, prices: initialPrices }));
    }
  }, [model.destinations, model.prices]);

  const handlePriceChange = (index, field, value) => {
    const updatedPrices = model.prices.map((price, i) =>
      i === index ? { ...price, [field]: value } : price
    );

    setModel((prev) => ({ ...prev, prices: updatedPrices }));
  };

  return (
    <div className="p-10">
      <PricesPackageTable
        prices={model.prices}
        onPriceChange={handlePriceChange}
      />
    </div>
  );
};

export default PricesPackage;
