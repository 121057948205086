import { Input } from "rsuite";
import Responsive from "../../../Components/Responsive";
import Extrats from "../Extrats/Extrats.component";
import { CaracteristiquePackageTranslations } from "../../../Constants/types";
import Caracteristique from "../../../Components/Caracteristique/caracteristique";

const InformationsGeneral = ({
  setModel,
  providers,
  fetchProviders,
  model,
}) => {
  const setCaracteristiques = (caracteristique) => {
    setModel((prev) => ({
      ...prev,
      caracteristique,
    }));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Responsive m={6} l={6} xl={6} className="p-10">
          <label>Nom :</label>
          <Input
            value={model.name}
            onChange={(name) =>
              setModel((prev) => ({ ...prev, name: name.toUpperCase() }))
            }
          />
        </Responsive>

        <Responsive m={6} l={6} xl={6} className="p-10">
          <label>Description :</label>
          <Input
            value={model.description}
            onChange={(description) =>
              setModel((prev) => ({ ...prev, description }))
            }
          />
        </Responsive>
      </div>

      <Caracteristique
        setCaracteristiques={setCaracteristiques}
        caracteristique={model.caracteristique || {}}
        translations={CaracteristiquePackageTranslations}
      />

      <Extrats
        providers={providers}
        fetchProviders={fetchProviders}
        model={model}
        setModel={setModel}
      />
    </div>
  );
};

export default InformationsGeneral;
