import styles from "./styles/caracteristiqueStyles";

const Caracteristique = ({
  setCaracteristiques,
  caracteristique,
  translations,
}) => {
  const handleChange = (key) => (e) => {
    const updatedCaracteristique = {
      ...caracteristique,
      [key]: e.target.checked,
    };
    setCaracteristiques(updatedCaracteristique);
  };

  return (
    <div style={styles.container}>
      {Object.keys(caracteristique)
        .filter((key) => key !== "id")
        .map((key) => (
          <label key={key} style={styles.label}>
            {translations[key] || key}
            <input
              type="checkbox"
              checked={caracteristique[key]}
              onChange={handleChange(key)}
            />
          </label>
        ))}
    </div>
  );
};

export default Caracteristique;
