import WarningRoundIcon from "@rsuite/icons/WarningRound";
import styles from "./styles/attachmentStyles";

const FilePreviewList = ({ state, handleDelete, canDelete }) => {
  return (
    <>
      <div>
        <h5>Fichiers téléchargés :</h5>
        <br />
        <div style={styles.fileContainer}>
          {state.map((file, index) => (
            <div style={styles.filePreviewContainer} key={file.fileKey}>
              <div style={styles.filePreview}>
                {file ? (
                  file.blobFile?.type.startsWith("video") ? (
                    <video
                      src={file.url || URL.createObjectURL(file.blobFile)}
                      controls
                      style={styles.file}
                    />
                  ) : (
                    <img
                      src={file.url || URL.createObjectURL(file.blobFile)}
                      alt="Preview"
                      style={styles.file}
                    />
                  )
                ) : (
                  <p>Pas d'aperçu</p>
                )}
              </div>
              {!canDelete && (
                <div
                  style={styles.deleteIcon}
                  onClick={() => handleDelete(index)}
                >
                  <WarningRoundIcon style={{ color: "red" }} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FilePreviewList;
