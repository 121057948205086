import { Input, SelectPicker } from "rsuite";
import Responsive from "../../../../../Components/Responsive";
import { TypeTransfert } from "../../../../../Constants/types";
import { useState } from "react";
import moment from "moment";
import styles from "./styles/transfertListStyles";

const TransfertForm = ({
  transfertList,
  handleChange,
  hotelData,
  airportData,
  setArrivalCity,
  setDepartureCity,
  arrivalCity,
  departureCity,
  paysData,
}) => {
  const [departureType, setDepartureType] = useState(
    transfertList.departType || 0
  );
  const [arrivalType, setArrivalType] = useState(
    transfertList.arrivalType || 0
  );

  const isSpecialType = (type) => type === 0;

  const handleDepartureTypeChange = (value) => {
    handleChange("departType", value);
    setDepartureType(value);
  };

  const handleArrivalTypeChange = (value) => {
    handleChange("arrivalType", value);
    setArrivalType(value);
  };

  return (
    <div>
      <Responsive className="p-5" m={6} l={3} xl={1.8}>
        <label>Nom: </label>
        <Input
          block
          value={transfertList.name || ""}
          onChange={(name) => handleChange("name", name)}
        />
      </Responsive>

      <Responsive className="p-5" m={6} l={2} xl={1.8}>
        <label>Date:</label>
        <Input
          type="date"
          value={moment(transfertList.date).format("YYYY-MM-DD") || ""}
          onChange={(date) => handleChange("date", date)}
        />
      </Responsive>

      <div style={styles.typeTransfert}>
        <Responsive className="p-5" m={4} l={2} xl={1.7}>
          <label>Type de départ: </label>
          <SelectPicker
            value={departureType}
            data={TypeTransfert}
            block
            noSearch
            onSelect={handleDepartureTypeChange}
          />
        </Responsive>

        {isSpecialType(departureType) ? (
          <Responsive className="p-5" m={6} l={3} xl={2}>
            <label>Nom de départ:</label>
            <Input
              block
              value={transfertList.departName || ""}
              onChange={(value) => handleChange("departName", value)}
            />
          </Responsive>
        ) : (
          <>
            <Responsive className="p-5" m={6} l={3} xl={2}>
              <label>Pays de départ:</label>
              <SelectPicker
                data={paysData?.map((c) => ({
                  label: c.nom,
                  value: c.nom,
                }))}
                block
                value={departureCity}
                onSelect={(pays) => setDepartureCity(pays)}
              />
            </Responsive>

            <Responsive className="p-5" m={6} l={3} xl={2}>
              <label>{departureType === 2 ? "hôtel" : "Aéroport"}</label>
              <SelectPicker
                value={transfertList.departName || ""}
                data={
                  departureType === 2
                    ? hotelData.departure.map((c) => ({
                        label: c.name_en,
                        value: c.name_en,
                      }))
                    : departureType === 1
                    ? airportData.departure.map((a) => ({
                        label: a.name,
                        value: a.name,
                      }))
                    : []
                }
                block
                noSearch
                onSelect={(value) => handleChange("departName", value)}
              />
            </Responsive>
            <Responsive className="p-5" m={6} l={3} xl={2}>
              <label>Nom de départ:</label>
              <Input
                block
                value={transfertList.departName || ""}
                onChange={(value) => handleChange("departName", value)}
                disabled
              />
            </Responsive>
          </>
        )}
      </div>

      <div style={styles.typeTransfert}>
        <Responsive className="p-5" m={6} l={2} xl={1.7}>
          <label>Type d'arrivée: </label>
          <SelectPicker
            value={arrivalType}
            data={TypeTransfert}
            block
            noSearch
            onSelect={handleArrivalTypeChange}
          />
        </Responsive>

        {isSpecialType(arrivalType) ? (
          <Responsive className="p-5" m={6} l={3} xl={2}>
            <label>Nom d'arrivée:</label>
            <Input
              block
              value={transfertList.arrivalName || ""}
              onChange={(value) => handleChange("arrivalName", value)}
            />
          </Responsive>
        ) : (
          <>
            <Responsive className="p-5" m={6} l={3} xl={2}>
              <label>Pays d'arrivée:</label>
              <SelectPicker
                data={paysData?.map((c) => ({ label: c.nom, value: c.nom }))}
                block
                value={arrivalCity}
                onSelect={(pays) => setArrivalCity(pays)}
              />
            </Responsive>

            <Responsive className="p-5" m={6} l={3} xl={2}>
              <label>{arrivalType === 2 ? "hôtel" : "Aéroport"}</label>
              <SelectPicker
                value={transfertList.arrivalName || ""}
                data={
                  arrivalType === 2
                    ? hotelData.arrival.map((c) => ({
                        label: c.name_en,
                        value: c.name_en,
                      }))
                    : arrivalType === 1
                    ? airportData.arrival.map((a) => ({
                        label: a.name,
                        value: a.name,
                      }))
                    : []
                }
                block
                noSearch
                onSelect={(value) => handleChange("arrivalName", value)}
              />
            </Responsive>
            <Responsive className="p-5" m={6} l={3} xl={2}>
              <label>Nom d'arrivée:</label>
              <Input
                block
                value={transfertList.arrivalName || ""}
                onChange={(value) => handleChange("arrivalName", value)}
                disabled
              />
            </Responsive>
          </>
        )}
      </div>
    </div>
  );
};

export default TransfertForm;
