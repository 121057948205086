import { Input, SelectPicker } from "rsuite";
import Responsive from "../../../../Components/Responsive";
import PackageMealTypes from "../packageMealTypes";
import { CaracteristiqueHotelTranslations } from "../../../../Constants/types";
import Caracteristique from "../../../../Components/Caracteristique/caracteristique";
import ArrangementSection from "../ArrangementSection";

const HotelForm = ({
  updateHotel,
  hotelData,
  hotel,
  index,
  fetchHotels,
  setPackageMealTypes,
  setCaracteristiques,
  providers,
  currencies,
  exchanges,
  fetchProviders,
  setArrangements,
  isUmrah,
}) => {
  const getCurrencyOptions = (providerId) => {
    if (providerId) {
      const provider = providers.find((p) => p.id === providerId);
      return provider
        ? provider.currencies.map((c) => ({
            label: c.currency.name,
            value: c.currency.id,
          }))
        : [];
    }
    return currencies.map((c) => ({ label: c.name, value: c.id }));
  };

  return (
    <div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
      <div style={{ display: "flex", gap: 8 }}>
        <Responsive className="p-5" m={6} l={3} xl={3}>
          <label>Hôtel:</label>
          <SelectPicker
            value={hotel.hotelListId}
            onSearch={fetchHotels}
            data={hotelData.map((c) => ({ label: c.name_en, value: c.id }))}
            block
            noSearch
            onSelect={(hotelListId) => {
              const selectedHotel = hotelData.find((c) => c.id === hotelListId);
              updateHotel(index, {
                hotelListId,
                name: selectedHotel?.name_en || "",
              });
            }}
          />
        </Responsive>
        <Responsive className="p-5" m={6} l={3} xl={3}>
          <label>Fournisseur:</label>
          <SelectPicker
            value={hotel.providerId}
            onSearch={fetchProviders}
            data={providers.map((c) => ({ label: c.name, value: c.id }))}
            block
            noSearch
            onSelect={(providerId) => {
              updateHotel(index, {
                providerId,
              });
            }}
          />
        </Responsive>
        <Responsive className="p-5" m={6} l={3} xl={3}>
          <label>Devise:</label>
          <SelectPicker
            data={getCurrencyOptions()}
            block
            noSearch
            value={hotel.currencyId}
            onSelect={(currencyId) => {
              const ex = exchanges.find(
                (el) => el.currencySourceId === currencyId
              );
              updateHotel(index, {
                currencyId,
              });
              updateHotel(index, {
                exchange: ex ? ex.rate : 1,
              });
            }}
          />
        </Responsive>
        <Responsive s={3} m={3} l={1.5} xl={1} className="p-10">
          <label>Cour:</label>
          <h6 className="p-5">{hotel.exchange}</h6>
        </Responsive>

        <Responsive s={6} m={6} l={1.5} xl={1} className="p-10">
          <label>Durée:</label>
          <Input
            type="number"
            step="1"
            value={hotel.duration}
            onChange={(dur) => {
              const duration = parseInt(dur, 10);
              updateHotel(index, {
                duration: isNaN(duration) ? 0 : duration,
              });
            }}
          />
        </Responsive>
      </div>

      <Caracteristique
        setCaracteristiques={(updatedCaracteristique) =>
          setCaracteristiques(index, updatedCaracteristique)
        }
        caracteristique={hotel.caracteristique || {}}
        translations={CaracteristiqueHotelTranslations}
      />

      {isUmrah && (
        <ArrangementSection
          arrangements={hotel.arrangements}
          setArrangements={(newArrangement) =>
            setArrangements(index, newArrangement)
          }
          exchange={hotel.exchange}
          duration={hotel.duration}
        />
      )}

      <PackageMealTypes
        packageMealTypes={hotel.packageMealTypes}
        setPackageMealTypes={(newMealTypes) =>
          setPackageMealTypes(index, newMealTypes)
        }
        exchange={hotel.exchange}
        duration={hotel.duration}
      />
    </div>
  );
};

export default HotelForm;
