import { useCallback, useEffect, useState } from "react";
import TransfertForm from "./transfertForm";
import TransportForm from "../modeTransports/transportForm";
import { ModeTransportMdel } from "../../../../../Models/packageomra.model";
import { APi } from "../../../../../Api";

const TransfertList = ({ setModel, disponibilitie, index, transfertList }) => {
  const [transfertListState, setTransfertListState] = useState(transfertList);
  const [airportData, setAirportData] = useState({
    departure: [],
    arrival: [],
  });
  const [city, setCity] = useState({ departure: "", arrival: "" });
  const [paysData, setPaysData] = useState([]);
  const [hotelData, setHotelData] = useState({
    departure: [],
    arrival: [],
  });

  const handleChange = (field, value) => {
    const updatedTransfertList = { ...transfertListState, [field]: value };
    setTransfertListState(updatedTransfertList);

    const updatedTransfertLists = [
      ...disponibilitie.transfert.transfertLists.slice(0, index),
      updatedTransfertList,
      ...disponibilitie.transfert.transfertLists.slice(index + 1),
    ];

    setModel((prev) => ({
      ...prev,
      disponibilities: prev.disponibilities.map((disponibility, idx) => {
        if (idx === index) {
          return {
            ...disponibility,
            transfert: {
              ...disponibility.transfert,
              transfertLists: updatedTransfertLists,
            },
          };
        }
        return disponibility;
      }),
    }));
  };

  const addNewTransport = () => {
    const newTransport = new ModeTransportMdel();
    const updatedTransfertList = {
      ...transfertListState,
      modeTransports: [...transfertListState.modeTransports, newTransport],
    };
    setTransfertListState(updatedTransfertList);
    handleChange("modeTransports", updatedTransfertList.modeTransports);
  };

  const deleteTransport = (i) => {
    const updatedModeTransports = transfertListState.modeTransports.filter(
      (_, index) => index !== i
    );
    handleChange("modeTransports", updatedModeTransports);
  };

  const handleInputChangeTransport = (i, field, value) => {
    const updatedModeTransports = [...transfertListState.modeTransports];
    updatedModeTransports[i] = {
      ...updatedModeTransports[i],
      [field]: value,
    };
    handleChange("modeTransports", updatedModeTransports);
  };

  const fetchAirports = useCallback(
    (cityKey) => {
      const cityName = city[cityKey];
      if (!cityName) return;

      APi.createAPIEndpoint(APi.ENDPOINTS.Airport, { city: cityName })
        .customGet()
        .then((res) => {
          setAirportData((prev) => ({
            ...prev,
            [cityKey]: res.data.data || [],
          }));
        });
    },
    [city]
  );

  useEffect(() => {
    fetchAirports("departure");
    fetchAirports("arrival");
  }, [fetchAirports]);

  const fetchHotels = useCallback(
    (cityKey) => {
      const cityName = city[cityKey];
      if (!cityName) return;
      APi.createAPIEndpoint(APi.ENDPOINTS.HotelList + "/HotelsByCity", {
        city: cityName,
      })
        .customGet()
        .then((res) => {
          setHotelData((prev) => ({
            ...prev,
            [cityKey]: res.data.hotels || [],
          }));
        });
    },
    [city]
  );

  useEffect(() => {
    fetchHotels("departure");
    fetchHotels("arrival");
  }, [fetchHotels]);

  const fetchPays = useCallback(() => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Pays, {})
      .customGet()
      .then((res) => setPaysData(res.data));
  }, []);

  useEffect(() => {
    fetchPays();
  }, [fetchPays]);

  return (
    <div
      style={{
        background: "rgb(70, 90, 238, 0.2)",
        padding: "10px",
      }}
      className="p-5"
    >
      <TransfertForm
        transfertList={transfertListState}
        handleChange={handleChange}
        hotelData={hotelData}
        airportData={airportData}
        setArrivalCity={(value) =>
          setCity((prev) => ({ ...prev, arrival: value }))
        }
        setDepartureCity={(value) =>
          setCity((prev) => ({ ...prev, departure: value }))
        }
        arrivalCity={city.arrival}
        departureCity={city.departure}
        paysData={paysData}
      />
      <hr style={{ margin: "10px 0" }} />
      <h6>Transport</h6>
      {transfertListState.modeTransports.map((mode, i) => (
        <TransportForm
          mode={mode}
          i={i}
          addNewTransport={addNewTransport}
          modeTransports={transfertListState.modeTransports}
          deleteTransport={deleteTransport}
          handleInputChangeTransport={handleInputChangeTransport}
        />
      ))}
    </div>
  );
};

export default TransfertList;
