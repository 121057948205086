export default class SaleVoModel {
  hotelName = "";
  arrangement = "";
  ticketNumber = "";
  totalPrice = 0;
  totalRefund = 0;
  commission = 0;
  fare = 0;
  commissionPercent = 0;
  regulated_value = 0;
  note = "";
  dateVol = null;
  date = new Date();
  clientId = 0;
  packageId = 0;
  extrats = [];
  customer = new CustomerVoModel();
}

export class CustomerVoModel {
  name = "";
  passportNumber = "";
  birthDate = new Date();
  gender = 1;
  ageType = 1;
  phoneNumber = "";
  passportExpiryDate = new Date();
  passportDeliveryDate;
  mrz = "";
  grandFatherName;
  arabicMotherName;
  cin;
  sex = 1;
  birthCountry = "216";
  civilState;
  picture;
  nationality = "216";
  passportDeliveryCity;

  passportExpiryDate;
  passportDeliveryCountry = "216";
  passportType;

  addressCountry = "216";
  tel;

  addressCity;

  title = 1;

  firstName = "";
  lastName = "";
  fatherName = "";
  motherName = "";
  arabicName = "";
  arabicLastName = "";
  birthCity;
  arabicFatherName = "";
  arabicGrandFatherName = "";
  job = "nothing";
  customerType = 1;
}
