import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Divider, Loader, Message, Steps } from "rsuite";
import { PackageAtom } from "../../Atoms/Package.atom";
import Attachments from "./Attachments/Attachments.component";
import InformationsGeneral from "./informationsGeneral/informationsGeneral.component";
import Disponibilities from "../PackageUmrah/disponibilitie/Disponibilities";
import { stepsTitles } from "../../Constants/types";
import { APi } from "../../Api";
import { CurrencyAtom } from "../../Atoms/currency.atom";
import { ExchangeAtom } from "../../Atoms/exchange.atom";
import { hotelAtoms } from "../../Atoms/hotel.atom";
import Destination from "../PackageUmrah/destination/destination";
import ProgramPackage from "../PackageUmrah/program/program";
import PricesPackage from "./Prices/pricesPackage";
import styles from "../PackageUmrah/styles/AddEditPackageUmrah.componentStyles";

const AddEditOrganizedTravel = ({
  error,
  providers,
  fetchProviders,
  isLoading,
}) => {
  const [current, setCurrent] = useState(0);
  const [model, _setmodel] = useRecoilState(PackageAtom);
  const [state, setState] = useState([]);
  const [programsData, setProgramsData] = useState([]);
  const currencies = useRecoilValue(CurrencyAtom);
  const exchanges = useRecoilValue(ExchangeAtom);
  const [paysData, setPaysData] = useState([]);
  const [city, setCity] = useState("Saudi Arabia");
  const [hotelData, setHotelData] = useRecoilState(hotelAtoms);
  const [excursionData, setExcursionData] = useState([]);

  const nbrDay = useMemo(
    () => model.destinations?.reduce((sum, dest) => sum + dest.nbrDay, 0) || 0,
    [model.destinations]
  );

  const fetchPrograms = useCallback(() => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Programs + "/getAll", {
      isUmrah: false,
      maxDay: nbrDay,
    })
      .customGet()
      .then((res) => {
        setProgramsData(res.data.programs);
      });
  }, [nbrDay]);

  useEffect(() => {
    fetchPrograms();
  }, [fetchPrograms]);

  const fetchPays = useCallback(() => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Pays, {})
      .customGet()
      .then((res) => setPaysData(res.data));
  }, []);

  useEffect(() => {
    fetchPays();
  }, [fetchPays]);

  const fetchHotels = useCallback(() => {
    APi.createAPIEndpoint(APi.ENDPOINTS.HotelList + "/HotelsByCity", {
      city,
    })
      .customGet()
      .then((res) => {
        setHotelData(res.data.hotels);
      });
  }, [city]);

  useEffect(() => {
    fetchHotels();
  }, [fetchHotels]);

  const fetchExcursion = useCallback(() => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Excursion + "/getAll", {
      find: "Vo",
    })
      .customGet()
      .then((res) => {
        setExcursionData(res.data.excursions);
      });
  }, []);

  useEffect(() => {
    fetchExcursion();
  }, [fetchExcursion]);

  const stepsComponents = {
    0: (
      <InformationsGeneral
        setModel={_setmodel}
        providers={providers}
        fetchProviders={fetchProviders}
        model={model}
      />
    ),
    1: (
      <Disponibilities
        model={model}
        setModel={_setmodel}
        fetchProviders={fetchProviders}
        providers={providers}
        isUmrah={false}
      />
    ),
    2: (
      <Destination
        title="Destination et Hôtels"
        providers={providers}
        currencies={currencies}
        exchanges={exchanges}
        fetchProviders={fetchProviders}
        setModel={_setmodel}
        model={model}
        fetchPays={fetchPays}
        fetchHotels={fetchHotels}
        hotelData={hotelData || []}
        paysData={paysData || []}
        excursionData={excursionData || []}
        isUmrah={false}
        city={city}
        setCity={setCity}
      />
    ),
    3: (
      <ProgramPackage
        setModel={_setmodel}
        programs={programsData || []}
        model={model}
        fetchPrograms={fetchPrograms}
      />
    ),
    4: <PricesPackage model={model} setModel={_setmodel} />,
    5: (
      <Attachments
        model={model}
        setModel={_setmodel}
        state={state}
        setState={setState}
      />
    ),
  };

  return (
    <>
      <Steps current={current} style={styles.steps}>
        {stepsTitles.map((title, idx) => (
          <Steps.Item key={idx} title={title} onClick={() => setCurrent(idx)} />
        ))}
      </Steps>

      {isLoading ? (
        <div style={styles.loader}>
          <Loader size="lg" />
        </div>
      ) : (
        stepsComponents[current]
      )}

      {error && (
        <Message showIcon type="error" style={styles.message}>
          {error}
        </Message>
      )}

      <Divider />
    </>
  );
};

export default AddEditOrganizedTravel;
