import "../../../style/packageUmrah/hotelMakkah/hotelMakkah.css";
import Responsive from "../../../Components/Responsive";
import { CheckPicker, Input, SelectPicker } from "rsuite";
import { destinations } from "../../../Constants/types";

const DestinationForm = ({
  destinationModel,
  setDestinationModel,
  excursionData,
  isUmrah,
  setCity,
  city,
  paysData,
}) => {
  const handleDestinationChange = (key, value) => {
    setDestinationModel((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div>
      {!isUmrah && (
        <Responsive className="p-10" m={6} l={3} xl={1.8}>
          <label>Pays:</label>
          <SelectPicker
            data={paysData?.map((c) => ({
              label: c.nom,
              value: c.nom,
            }))}
            block
            value={city}
            onSelect={(pays) => setCity(pays)}
          />
        </Responsive>
      )}

      {isUmrah ? (
        <Responsive className="p-10" m={6} l={3} xl={1.8}>
          <label>Destination:</label>
          <SelectPicker
            value={destinationModel.name}
            data={destinations}
            block
            noSearch
            onSelect={(name) => {
              handleDestinationChange("name", name);
            }}
          />
        </Responsive>
      ) : (
        <Responsive className="p-10" m={6} l={3} xl={1.8}>
          <label>Destination:</label>
          <Input
            value={destinationModel.name}
            onChange={(name) => handleDestinationChange("name", name)}
          />
        </Responsive>
      )}

      <Responsive className="p-10" m={6} l={3} xl={2}>
        <label>Excursion:</label>
        <CheckPicker
          value={destinationModel.excursionDestIds || []}
          data={excursionData.map((c) => ({ label: c.name, value: c.id }))}
          block
          noSearch
          onChange={(selectedExcursionIds) => {
            setDestinationModel((prevModel) => ({
              ...prevModel,
              excursionDestIds: selectedExcursionIds,
            }));
          }}
        />
      </Responsive>

      <Responsive s={6} m={6} l={1.4} xl={1} className="p-10">
        <label>Durée Total:</label>
        <Input type="text" value={destinationModel.nbrDay} disabled />
      </Responsive>
    </div>
  );
};

export default DestinationForm;
