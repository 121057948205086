import React, { useEffect, useState } from 'react'
import { APi } from '../../Api'
import { ENDPOINTS } from '../../Api/enpoints'
import Filter from '../../Components/Common/Filter'
import Responsive from '../../Components/Responsive'
import { SelectPicker, Tag } from 'rsuite'
import moment from 'moment'
import Grid from '../../Components/Grid'
import { ImCheckboxChecked } from 'react-icons/im'
import { ConfirmationStatus } from '../../Constants/types'
import ImageIcon from "@rsuite/icons/Image";
import { BASE_URL } from '../../Config/api.config'

export default function VolStatistics() {
    const [data, setdata] = useState({items:[]})
    const [packages, setpackages] = useState([])
    const [filterModel, setfilterModel] = useState({packageUmrahId:0})
    const fetch =()=>{
        APi.createAPIEndpoint(ENDPOINTS.Order,filterModel,"/GetUmrahsStatistics").fetchAll().then(res=>setdata(res.data))
    }
    const fetchPackages = (q) => {
        if (typeof q == "undefined" || q.length > 2) {
          APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah + "/getForPrices", { q })
            .customGet()
            .then((res) => {
              setpackages(res.data.data);
    
           
            });
        }
      };
      useEffect(()=>{
        fetchPackages()
      },[])
  return (
    <div>
<Filter search={() => fetch()}>
      
       
        <Responsive  className="p-10">
          <label>Nom Omrah: </label>
          <SelectPicker
            onSearch={fetchPackages}
            data={[{ label: "Tout", value: 0 }].concat(
              packages.map((el) => {
                return { label: el.name, value: el.id };
              })
            )}
            block
            noSearch
            value={filterModel.packageUmrahId}
            onSelect={(packageUmrahId) => {
              setfilterModel((prev) => {
                return { ...prev, packageUmrahId };
              });
            }}
          />
        </Responsive>
      
      </Filter>
      <div>
        {data.items.map(d=><Item d={d}/>)}
      </div>

    </div>
  )
}

const Item=({d})=>{
    const [show, setshow] = useState(false)
    const [showIndex, setshowIndex] = useState(-1)
return <div style={{padding:"5px", background:"#fff", borderRadius:"5px" , margin:"10px 4px", position:"relative"}}>
    <button style={{position:"absolute", right:"6px" , top:"5px"}} onClick={()=>setshow(prev=>!prev)}>détails</button>
<b
style={{
display: "inline-flex",
alignItems: "center",
padding: "3px",

borderRadius: "5px",
marginBottom:"15px"
}}
>
<img
alt=""
style={{ width: "30px", height: "30px", margin: "0 6px" }}
src={d.disponibility.airline ? d.disponibility.airline.logo : ""}
></img>
<b>
{" "}
<div style={{ color: "#232323" }}>
  {moment(d.disponibility.date).format("DD MMM YYYY")}
</div>
<b style={{ color: "#888", fontWeight: "lighter" }}>
  {" "}
  {d.disponibility.airline ? d.disponibility.airline.name : ""}
</b>
</b>
</b>
<div style={{display:"flex", alignItems:"center", }}><i>Total:</i> <b style={{fontSize:"20px" , margin:"0 4px"}}>{d.total}</b> <div style={{display:"inline-flex" ,flex:1, height:"25px" , background:"#f1f1f1" , border:"1px solid #eee", marginLeft:"5px" , borderRadius:"5px", overflow:"hidden"}}>

<div style={{display:"inline-block" , width:`${((d.visasNumber + 3)/d.total)*100}%` , background:"rgb(76,103,194)",height:"25px" , textAlign:"center", color:"#fff",}}>{d.visasNumber} visas</div>
<div style={{display:"inline-block" , width:`${((d.sendedNumber+2)/d.total)*100}%` , background:"#334455",height:"25px" , textAlign:"center", color:"#fff",}}>{d.sendedNumber} envoyés</div>
<div style={{display:"inline-block" , width:`${((d.total- d.sendedNumber-d.visasNumber)/d.total)*100}%` ,color:"rgb(76,103,194)",height:"25px" , textAlign:"center",background:"rgb(76,103,194,0.2)" }}>{d.total- d.sendedNumber-d.visasNumber} en attente</div>

</div></div>
{show && <div style={{paddingLeft:"2%"}}>
    
    {d.items.map((item,i)=>
   <> <div style={{display:"flex", alignItems:"center", }}><a href="#" onClick={()=>setshowIndex(prev=>prev==i?-1 :i)} style={{fontWeight:"bold"}}>{item.client.name}:</a> <b style={{fontSize:"20px" , margin:"0 4px"}}>{item.orders.length}</b> <div style={{display:"inline-flex" ,flex:1, height:"22px" , background:"#f1f1f1" , border:"1px solid #eee", marginLeft:"5px" , borderRadius:"5px", overflow:"hidden"}}>

<div style={{display:"inline-block" , width:`${((item.visasNumber +1)/item.orders.length)*100}%` , background:"rgb(76,103,194)",height:"22px" , textAlign:"center", color:"#fff",}}>{item.visasNumber} visas</div>
<div style={{display:"inline-block" , width:`${((item.sendedNumber+1)/item.orders.length)*100}%` , background:"#334455",height:"22px" , textAlign:"center", color:"#fff",}}>{item.sendedNumber} envoyés</div>
<div style={{display:"inline-block" , width:`${((item.orders.length- item.sendedNumber-item.visasNumber)/item.orders.length)*100}%` ,color:"rgb(76,103,194)",height:"22px" , textAlign:"center",background:"rgb(76,103,194,0.2)" }}>{item.orders.length- item.sendedNumber-item.visasNumber} en attente</div>
</div>

</div>{showIndex== i &&      <Grid
       
      
       columns={columns}
       rows={item.orders}
     />}</>)}

</div>}
</div>
}



const columns = [ {
    value: "customer",
    name: "Client B2C",
    render: (v) => (
      <a style={{ display: "flex", alignItems: "center" }}>
        {v.picture ? (
          <img
            alt=""
            src={BASE_URL + "Uploads/CustomerPictures/" + v.picture+"?"+Date.now()}
            width="43"
            height="43"
            style={{
              border: "1px solid #eee",
              borderRadius: "50%",
              boxShadow: "-11px -5px 20px -13px rgba(0,0,0,0.75)",
            }}
          />
        ) : (
          <div
            style={{
              fontSize: "15px",
              border: "1px dashed #888",
              borderRadius: "50%",
              width: "43px",
              height: "43px",
              textAlign: "center",
              lineHeight: "42px",
            }}
          >
            <ImageIcon color="#3598ff"></ImageIcon>
          </div>
        )}
        <div style={{ padding: "0 5px" }}>
          {" "}
          {v ? v.name.toUpperCase() : ""}
          <br></br> <i style={{ color: "#aaa" }}>{v ? v.passportNumber : ""}</i>
        </div>
      </a>
    ),
  },
  {
    value: "gender",
    name: "Genre",
    render: (v) => (
      <Tag
        color={
          v === 1 ? "violet"  :"red"
        }
      >
       {
          v === 1 ? "male"  :"female"
        }
      </Tag>
    ),
  },
    {
   value:"onlyTicket",value2:"withoutVisa",
      name: "Type",
      render: (onlyTicket ,withoutVisa) => (
        <b style={{ color: "#549f0a" }}>{ onlyTicket ?<Tag color="blue">Billet</Tag>: ""} { withoutVisa ?<Tag color="orange">sans visa</Tag>: ""}</b>
      ),
    },
   
    {
      value: "confirmationStatus",
      name: "Etat",
      render: (v) => (
        <Tag
          color={
            v === 2 ? "violet" : v === 3 ? "green" : v === 4 ? "red" : "yellow"
          }
        >
          {ConfirmationStatus.find((el) => v === null || el.value === v)
            ? ConfirmationStatus.find((el) => v === null || el.value === v).label
            : ConfirmationStatus.find((el) => el.value === 1).label}
        </Tag>
      ),
    },
    {
      value: "fingerprint",
      name: "Empreinte",
      render: (v) => (v ? <ImCheckboxChecked color="green" /> : <b>-</b>),
    },
    
   
  
    
   
    
  ];