import { Button, Modal } from "rsuite";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useState } from "react";

const FileUploadModal = ({
  open,
  selectedFile,
  onClose,
  onUploadInModal,
  crop,
  setCrop,
}) => {
  const [isHeightTooLarge, setIsHeightTooLarge] = useState(false);
  const [isWidthTooLarge, setIsWidthTooLarge] = useState(false);

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    setIsHeightTooLarge(crop.height > naturalHeight);
    setIsWidthTooLarge(crop.width > naturalWidth);
  };

  return (
    <Modal open={open} onClose={onClose} size="md">
      <Modal.Header>
        <Modal.Title>Télécharger le fichier</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedFile && selectedFile.blobFile instanceof Blob ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <ReactCrop
              crop={crop}
              onChange={(percentCrop) => setCrop(percentCrop)}
              keepSelection
              locked
            >
              <img
                src={URL.createObjectURL(selectedFile.blobFile)}
                alt="Selected File"
                onLoad={handleImageLoad}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </ReactCrop>
            {(isHeightTooLarge || isWidthTooLarge) && (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  background: "rgba(255,0,0,0.6)",
                  color: "white",
                  padding: "5px",
                  borderRadius: "3px",
                  fontSize: "12px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {isHeightTooLarge && (
                  <div>
                    La hauteur du recadrage dépasse la hauteur de l'image!
                  </div>
                )}
                {isWidthTooLarge && (
                  <div>
                    La largeur du recadrage dépasse la largeur de l'image!
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <p>Aucun fichier sélectionné</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: "flex",
            gap: 2,
            justifyContent: "center",
            alignContent: "center",
            paddingTop: "10px",
          }}
        >
          <Button onClick={onClose} appearance="subtle">
            Annuler
          </Button>
          <Button onClick={onUploadInModal} appearance="primary">
            Recadrer
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default FileUploadModal;
