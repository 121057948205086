import Responsive from "../../Components/Responsive";
import { Input, Loader, SelectPicker } from "rsuite";
import { CaracteristiqueTransfert, TypePackage } from "../../Constants/types";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { API_KEY } from "../../Config/api.config";
import { useRecoilState } from "recoil";
import { ExcursionAtom } from "../../Atoms/excursion.atom";
import Attachments from "../Packages/Attachments/Attachments.component";
import { useState } from "react";
import styles from "./styles/excursionStyles";
import Caracteristique from "../../Components/Caracteristique/caracteristique";

const AddExcursion = ({ isLoading }) => {
  const [model, _setmodel] = useRecoilState(ExcursionAtom);
  const [stateModel, setStateModel] = useState([]);
  const setCaracteristiques = (updatedCaracteristiques) => {
    _setmodel((prev) => ({
      ...prev,
      caracteristique: updatedCaracteristiques,
    }));
  };

  const handleMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();

    const newMarker = { lat, lng };
    _setmodel((prev) => ({
      ...prev,
      latitude: newMarker.lat,
      longitude: newMarker.lng,
    }));
  };

  const handleChange = (key, value) => {
    _setmodel((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <>
      {isLoading ? (
        <div style={styles.loader}>
          <Loader size="lg" />
        </div>
      ) : (
        <div style={styles.container}>
          <LoadScript googleMapsApiKey={API_KEY}>
            <div style={styles.wrapper}>
              <div style={styles.header}>
                <div style={styles.formSection}>
                  <div style={styles.formRow}>
                    <div style={styles.formInputWrapper}>
                      <Responsive className="p-5" m={6} l={3} xl={3}>
                        <label>Nom: </label>
                        <Input
                          block
                          value={model.name || ""}
                          onChange={(name) => handleChange("name", name)}
                        />
                      </Responsive>

                      <Responsive className="p-5" m={6} l={3} xl={3}>
                        <label>Type Excursion</label>
                        <SelectPicker
                          data={TypePackage}
                          block
                          noSearch
                          value={model.typeExcursion}
                          onChange={(typeExcursion) =>
                            handleChange("typeExcursion", typeExcursion)
                          }
                        />
                      </Responsive>

                      <Responsive className="p-5" m={6} l={3} xl={3}>
                        <label>Latitude: </label>
                        <Input block value={model.latitude || ""} disabled />
                      </Responsive>

                      <Responsive className="p-5" m={6} l={3} xl={3}>
                        <label>Longitude: </label>
                        <Input block value={model.longitude || ""} disabled />
                      </Responsive>

                      <Responsive className="p-5" m={6} l={5} xl={9}>
                        <label>Description: </label>
                        <Input
                          as="textarea"
                          rows={3}
                          block
                          value={model.description || ""}
                          onChange={(description) =>
                            handleChange("description", description)
                          }
                        />
                      </Responsive>
                    </div>

                    <div style={styles.mapWrapper}>
                      <GoogleMap
                        mapContainerStyle={styles.mapContainer}
                        center={{ lat: 24.7136, lng: 46.6753 }}
                        zoom={6}
                        onClick={(event) => handleMapClick(event)}
                      >
                        <Marker
                          position={{
                            lat: model.latitude,
                            lng: model.longitude,
                          }}
                        />
                      </GoogleMap>
                    </div>
                  </div>
                  <Caracteristique
                    setCaracteristiques={setCaracteristiques}
                    caracteristique={model.caracteristique || {}}
                    translations={CaracteristiqueTransfert}
                  />
                </div>
              </div>
              <Attachments
                model={model}
                setModel={_setmodel}
                state={stateModel}
                setState={setStateModel}
                hasVideo
              />
            </div>
          </LoadScript>
        </div>
      )}
    </>
  );
};

export default AddExcursion;
