import { Input } from "rsuite";
import Responsive from "../../../Components/Responsive";

const MainInformations = ({ model, setModel }) => {
  const handleInputChange = (key, value, parseFunction) => {
    setModel((prev) => {
      return { ...prev, [key]: parseFunction ? parseFunction(value) : value };
    });
  };

  return (
    <div>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Nom : </label>
        <Input
          value={model.name}
          onChange={(name) => handleInputChange("name", name.toUpperCase())}
        />
      </Responsive>

      <Responsive s={4} m={3} l={2} xl={2} className="p-10">
        <label>Commission : </label>
        <Input
          type="number"
          step="1"
          value={model.commission}
          onChange={(commission) =>
            handleInputChange("commission", commission, parseFloat)
          }
        />
      </Responsive>

      <Responsive s={4} m={3} l={2} xl={2} className="p-10">
        <label>Commission B2B : </label>
        <Input
          type="number"
          step="1"
          value={model.b2Bcommission}
          onChange={(b2Bcommission) =>
            handleInputChange("b2Bcommission", b2Bcommission, parseFloat)
          }
        />
      </Responsive>

      <Responsive s={4} m={3} l={2} xl={2} className="p-10">
        <label>Nbre pour une gratuité : </label>
        <Input
          type="number"
          step="1"
          value={model.nbr_for_free_place}
          onChange={(nbr) =>
            handleInputChange("nbr_for_free_place", nbr, parseInt)
          }
        />
      </Responsive>
    </div>
  );
};

export default MainInformations;
