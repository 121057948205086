import { Input, SelectPicker } from "rsuite";
import Responsive from "../../../../Components/Responsive";
import { classFlight } from "../../../../Constants/types";
import moment from "moment";
import styles from "./styles/departureFlightsStyles";
import { useEffect, useMemo, useState } from "react";
import { APi } from "../../../../Api";

const DepartureFlightForm = ({
  flight,
  handleChange,
  departureAirportData,
  arrivalAirportData,
  departureCity,
  setDepartureCity,
  arrivalCity,
  setArrivalCity,
  paysData,
  index,
}) => {
  const handleFieldChange = (field, value) => {
    handleChange(index, field, value);
  };
  const [airportDepart, setAirportDepart] = useState("");
  const [airportReturn, setAirportReturn] = useState("");

  useEffect(() => {
    if (flight.departureAirportId) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Airport)
        .fetchById(flight.departureAirportId)
        .then((res) => {
          setAirportDepart(res.data);
        });
    }
  }, [flight.departureAirportId]);

  useEffect(() => {
    if (flight.arrivalAirportId) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Airport)
        .fetchById(flight.arrivalAirportId)
        .then((res) => {
          setAirportReturn(res.data);
        });
    }
  }, [flight.arrivalAirportId]);

  const paysOptions = useMemo(
    () => paysData?.map((c) => ({ label: c.nom, value: c.nom })) || [],
    [paysData]
  );

  const departureAirportOptions = useMemo(
    () =>
      departureAirportData?.map((c) => ({
        label: c.name,
        value: c.id,
      })) || [],
    [departureAirportData]
  );

  const arrivalAirportOptions = useMemo(
    () =>
      arrivalAirportData?.map((c) => ({
        label: c.name,
        value: c.id,
      })) || [],
    [arrivalAirportData]
  );

  return (
    <div>
      <div style={styles.container}>
        <Responsive className="p-5" m={6} l={3} xl={2}>
          <label>Nom: </label>
          <Input
            block
            value={flight.name || ""}
            onChange={(name) => handleFieldChange("name", name)}
          />
        </Responsive>

        <Responsive className="p-5" m={6} l={3} xl={2}>
          <label>Numéro de vol: </label>
          <Input
            block
            value={flight.flightNumber || ""}
            onChange={(flightNumber) =>
              handleFieldChange("flightNumber", flightNumber)
            }
          />
        </Responsive>
      </div>

      <div style={styles.container}>
        <Responsive className="p-5" m={6} l={3} xl={1.6}>
          <label>Pays de départ:</label>
          <SelectPicker
            data={paysOptions}
            block
            value={departureCity}
            onSelect={(pays) => setDepartureCity(pays)}
          />
        </Responsive>

        <Responsive className="p-5" m={6} l={3} xl={2}>
          <label>Aéroport</label>
          <SelectPicker
            data={departureAirportOptions}
            block
            value={flight.departureAirportId}
            onSelect={(departureAirportId) =>
              handleFieldChange("departureAirportId", departureAirportId)
            }
          />
        </Responsive>
        <Responsive className="p-5" m={6} l={3} xl={2}>
          <label>Nom de départ:</label>
          <Input block value={airportDepart.name || ""} disabled />
        </Responsive>
      </div>

      <div style={styles.container}>
        <Responsive className="p-5" m={6} l={3} xl={1.6}>
          <label>Pays d'arrivée:</label>
          <SelectPicker
            data={paysOptions}
            block
            value={arrivalCity}
            onSelect={(pays) => setArrivalCity(pays)}
          />
        </Responsive>

        <Responsive className="p-5" m={6} l={3} xl={2}>
          <label>Aéroport</label>
          <SelectPicker
            data={arrivalAirportOptions}
            block
            value={flight.arrivalAirportId}
            onSelect={(arrivalAirportId) =>
              handleFieldChange("arrivalAirportId", arrivalAirportId)
            }
          />
        </Responsive>
        <Responsive className="p-5" m={6} l={3} xl={2}>
          <label>Nom d'arrivée:</label>
          <Input block value={airportReturn.name || ""} disabled />
        </Responsive>
      </div>

      <Responsive className="p-5" m={6} l={3} xl={1.5}>
        <label>Date de départ :</label>
        <Input
          type="datetime-local"
          value={moment(flight.departDate).format("YYYY-MM-DDTHH:mm") || ""}
          onChange={(departDate) => handleFieldChange("departDate", departDate)}
        />
      </Responsive>

      <Responsive className="p-5" m={6} l={3} xl={1.5}>
        <label>Date d'arrivée :</label>
        <Input
          min={moment(flight.departDate).format("YYYY-MM-DDTHH:mm")}
          type="datetime-local"
          value={moment(flight.arrivalDate).format("YYYY-MM-DDTHH:mm") || ""}
          onChange={(arrivalDate) =>
            handleFieldChange("arrivalDate", arrivalDate)
          }
        />
      </Responsive>

      <Responsive className="p-5" m={6} l={3} xl={1.6}>
        <label>Class:</label>
        <SelectPicker
          data={classFlight}
          block
          noSearch
          value={flight.class}
          onChange={(className) => handleFieldChange("class", className)}
        />
      </Responsive>

      <Responsive className="p-5" m={6} l={3} xl={1.3}>
        <label>Durée du vol: </label>
        <Input
          block
          value={flight.flightDuration || ""}
          onChange={(flightDuration) =>
            handleFieldChange("flightDuration", flightDuration)
          }
        />
      </Responsive>

      <Responsive className="p-5" m={6} l={3} xl={1.3}>
        <label>Bagages à main: </label>
        <Input
          block
          value={flight.handBaggages || ""}
          onChange={(handBaggages) =>
            handleFieldChange("handBaggages", handBaggages)
          }
        />
      </Responsive>

      <Responsive className="p-5" m={6} l={3} xl={1.3}>
        <label>Bagages: </label>
        <Input
          block
          value={flight.baggages || ""}
          onChange={(baggages) => handleFieldChange("baggages", baggages)}
        />
      </Responsive>

      <Responsive className="p-5" m={6} l={3} xl={3}>
        <label>Description: </label>
        <Input
          block
          value={flight.description || ""}
          onChange={(description) =>
            handleFieldChange("description", description)
          }
        />
      </Responsive>
    </div>
  );
};

export default DepartureFlightForm;
