import { CustomerVoModel } from "./SaleVoModel";

export class OrderUmrahModel {
  date;
  clientId;
  customer;
  totalPrice;
  note;
  ref;
  status;
  tag;
  extrats;
  priceId;
  packageUmrahMealTypeMakkahId;
  arrangementMadinahId;
  packageUmrahMealTypeMadinahId;
  ticketNumber;
  ticketBaseFare;
  ticketCommission;
  ticketTax;
  dateVol;
  disponibilityId;
  commission; // after regulation
  //-----------------  for custom sale only ----------------------------------
  isSpecialSale; // if someone buy only a part of the pack // the service fees will be applied
  specialCosts;
  hotelsMakkah;
  hotelsMadinah;
  customer = new CustomerVoModel();
  withoutVisa  = false;
 onlyTicket  = false;
}

export class OrderUmrahHotel {
  hotelUmrahId;
  ArrangementId;
  PackageUmrahMealTypeId;
  duration;
}
