import Responsive from "../../../../Components/Responsive";
import HotelForm from "./hotelForm";
import TrashIcon from "@rsuite/icons/Trash";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import { IconButton } from "rsuite";
import { useEffect, useMemo } from "react";
import styles from "./styles/hotelsStyles";

const Hotels = ({
  hotels,
  updateHotel,
  hotelData,
  addHotel,
  removeHotel,
  setDestinationModel,
  fetchHotels,
  providers,
  currencies,
  exchanges,
  fetchProviders,
  destinationModel,
  isUmrah,
}) => {
  const totalDuration = useMemo(() => {
    return hotels.reduce((sum, currentHotel) => {
      return sum + (currentHotel.duration || 0);
    }, 0);
  }, [hotels]);

  useEffect(() => {
    setDestinationModel((prev) => ({
      ...prev,
      nbrDay: totalDuration,
    }));
  }, [totalDuration]);

  const setPackageMealTypes = (index, newMealTypes) => {
    setDestinationModel((prev) => ({
      ...prev,
      [isUmrah ? "hotelsUmrah" : "hotelsVo"]: (isUmrah
        ? prev.hotelsUmrah
        : prev.hotelsVo
      ).map((hotel, i) =>
        i === index
          ? {
              ...hotel,
              packageMealTypes: newMealTypes,
            }
          : hotel
      ),
    }));
  };

  const setCaracteristiques = (index, updatedCaracteristique) => {
    setDestinationModel((prev) => ({
      ...prev,
      [isUmrah ? "hotelsUmrah" : "hotelsVo"]: (isUmrah
        ? prev.hotelsUmrah
        : prev.hotelsVo
      ).map((hotel, i) =>
        i === index
          ? {
              ...hotel,
              caracteristique: updatedCaracteristique,
            }
          : hotel
      ),
    }));
  };

  const setArrangements = (index, newArrangment) => {
    setDestinationModel((prev) => ({
      ...prev,
      hotelsUmrah: prev.hotelsUmrah.map((hotel, i) =>
        i === index
          ? {
              ...hotel,
              arrangements: newArrangment,
            }
          : hotel
      ),
    }));
  };

  return (
    <div style={styles.mainContainer}>
      {hotels.map((hotel, index) => (
        <div style={styles.container}>
          <div style={styles.hotel} className="p-10">
            <HotelForm
              fetchHotels={fetchHotels}
              hotel={hotel}
              updateHotel={updateHotel}
              hotelData={hotelData}
              index={index}
              setPackageMealTypes={setPackageMealTypes}
              setCaracteristiques={setCaracteristiques}
              providers={providers}
              currencies={currencies}
              exchanges={exchanges}
              fetchProviders={fetchProviders}
              setDestinationModel={setDestinationModel}
              setArrangements={setArrangements}
              isUmrah={isUmrah}
            />
          </div>
          <Responsive s={2} m={1.6} l={1} xl={1}>
            <div style={styles.iconButton}></div>
            <IconButton
              onClick={() => removeHotel(index)}
              color="violet"
              icon={<TrashIcon />}
            />
            {index + 1 === hotels.length && (
              <IconButton
                onClick={() => addHotel(destinationModel.name || "")}
                color="violet"
                icon={<PlusRoundIcon />}
              />
            )}
          </Responsive>
        </div>
      ))}
    </div>
  );
};

export default Hotels;
