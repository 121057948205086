import { DestinationVoModel } from "../../../Models/Package.model";
import "../../../style/packageUmrah/hotelMakkah/hotelMakkah.css";

const PackageVoAddButton = ({
  destinationModel,
  setModel,
  setDestinationModel,
}) => {
  const updateHotelsAndPrices = () => {
    setModel((prev) => {
      const updatedPrices = prev.prices.map((price) => {
        const hotelName = prev.destinations
          .flatMap((destination) => destination.hotelsVo || [])
          .find((hotel) =>
            destinationModel.hotelsVo?.some(
              (modelHotel) =>
                (hotel.id && hotel.id === modelHotel.id) ||
                (hotel.uuid && hotel.uuid === modelHotel.uuid)
            )
          )?.name;

        if (price.name === hotelName) {
          return {
            ...price,
            name: destinationModel.hotelsVo?.[0]?.name,
          };
        }
        return price;
      });

      const updatedDestinations = [...prev.destinations];
      const destinationIndex = updatedDestinations.findIndex(
        (destination) =>
          (destination.id && destination.id === destinationModel.id) ||
          (destination.uuid && destination.uuid === destinationModel.uuid)
      );

      if (destinationIndex !== -1) {
        updatedDestinations[destinationIndex] = destinationModel;
      } else {
        updatedDestinations.push({
          ...destinationModel,
          uuid: Date.now(),
        });
      }

      return {
        ...prev,
        destinations: updatedDestinations,
        prices: updatedPrices,
      };
    });

    setDestinationModel(new DestinationVoModel());
  };

  const buttonText =
    destinationModel.id || destinationModel.uuid ? "modifier" : "ajouter";

  return (
    <div className="button-container">
      <button className="button-primary" onClick={updateHotelsAndPrices}>
        {buttonText}
      </button>
    </div>
  );
};

export default PackageVoAddButton;
