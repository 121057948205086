const styles = {
  modalContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "15px 0",
    zIndex: 1,
    maxWidth: "100vw",
  },
  modal: {
    maxHeight: "calc(100vh - 50px)",
    overflow: "auto",
    maxWidth: "100vw",
  },
  modalBody: {
    maxHeight: "calc(100vh - 240px)",
    overflow: "auto",
    maxWidth: "100vw",
  },
};

export default styles;
